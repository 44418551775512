export default [{
  id: 0,
  title: 'Declaro tener vigente el certificado para trabajo seguro en alturas, la afiliación a seguridad social y cumplir con los requisitos de aptitud relativos a la labor.'
}, {
  id: 1,
  title: 'Declaro contar con las medidas de prevención para el desarrollo de la labor correspondientes a la demarcación y señalización, el procedimiento escrito, y la compañía de un ayudante de seguridad.'
}, {
  id: 2,
  title: 'Declaro estar en uso de los elementos de protección personal (EPP) necesarios para el desarrollo de la labor, como lo son monja, casco, barbuquejo, gafas, uniforme, guantes y calzado apropiados.'
}, {
  id: 3,
  title: 'Declaro estar en uso de los elementos de protección  contra caídas necesarios para el desarrollo de la labor, como lo son eslinga de seguridad con absorbente de caídas, línea de vida y  freno de seguridad, conectores o mosquetones adecuados.'
}, {
  id: 4,
  title: 'Declaro contar con matricula CONTE, conocer los riesgos asociados a la actividad eléctrica y estas capacitado para afrontarlos, así como estar libre del uso de accesorios metálicos como joyas, manillas, relojes u otros.'
}, {
  id: 5,
  title: 'Declaro que conozco y se aplicar la distancia de seguridad, que las herramienta y equipos a utilizar son aislados, están diseñados para la labor a realizar y apropiados al nivel de tensión.'
}];
