<div class="flex-row padding-top-8 padding-bottom-8">
  <mat-checkbox
    [disabled]="state!=='NEW'"
    *ngIf="selector"
    class="margin-8"
    matRipple
    role="button"
    [checked]="selected"
    (change)="onChange($event)">
  </mat-checkbox>
  <div class="space-width actionable {{selector && state !=='NEW' ? 'disabled': ''}}" (click)="linkClicked()">
    <div class="header padding-bottom-8">
      <div class="column-full-width luminary-number" >
        <mat-icon *ngIf="computePendingSync" class="no-sync">cloud_off</mat-icon>
        <span class="name" *ngIf="luminaryNumber">{{luminaryNumber}}</span>
        <i class="name error" *ngIf="!luminaryNumber">Luminaria sin identificar</i>
      </div>
      <div>
        <span class="project">{{project}}</span>
      </div>
    </div>
    <div class="container">
      <div class="data">
        <div class="margin-right-16 basic-data">
          <div>
            <humanize-state [state]="state"></humanize-state>
          </div>
          <div class="icon-text" *ngIf="type">
            <mat-icon>build</mat-icon> <span> {{type.description}} </span>
          </div>
          <div class="icon-text" *ngIf="painType">
            <mat-icon>healing</mat-icon> <span> {{painType.description}} </span>
          </div>
        </div>
      </div>
      <div class="container-right">
        <div class="icon-text" *ngIf="technician">
          <mat-icon>account_circle</mat-icon> <span class="capitalize"> {{technician.name}} {{technician.surname}} </span>
        </div>
        <div class="icon-text" *ngFor="let participant of participants">
          <mat-icon>account_circle</mat-icon> <span class="capitalize"> {{participant.name}} {{participant.surname}} </span>
        </div>
        <div class="icon-text error" *ngIf="!technician">
          <i>Sin asignar</i>
        </div>
        <div class="icon-text" *ngIf="parent">
          <mat-icon>schedule</mat-icon> <span class="capitalize"> Reabierto </span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="data">
        <div class="margin-right-16 basic-data">
          <div class="subtitle-description" *ngIf="painDescription">
            {{painDescription}}
          </div>
          <div class="icon-text" *ngIf="estimatedResolutionDate">
            <mat-icon>calendar_today</mat-icon> <span> Fecha estimada: {{estimatedResolutionDate | formatDate}} </span>
          </div>
          <div class="icon-text" *ngIf="repairmentDate">
            <mat-icon>calendar_today</mat-icon> <span> Fecha de cierre: {{repairmentDate | formatDate}} </span>
          </div>
          <div class="subtitle ubication icon-text">
            <mat-icon>room</mat-icon> <span> {{address}} </span>
          </div>
          <div class="subtitle-description">
            <span>{{neighborhood}} </span><span *ngIf="referencePoint">|  {{referencePoint}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions {{selector ? 'invisible': ''}}" *ngIf="actions">
    <contextual-action [actions]="actions" [collapseAll]="true" (actionEvent)="onClickActionEvent($event)"></contextual-action>
  </div>
</div>
<div>
  <div class="tagged-data">
    <tag-automatic-color icon="memory" [text]="technology" matRipple *ngIf="technology"></tag-automatic-color>
    <tag-automatic-color icon="offline_bolt" [text]="power" matRipple *ngIf="power"></tag-automatic-color>
  </div>
  <div class="tagged-data" *ngIf="materials">
    <tag-automatic-color [keyColor]="technology+'_'+power" *ngFor="let material of materials" [text]="material | humanizeDescriptionMaterial"></tag-automatic-color>
  </div>
</div>
