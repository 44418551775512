import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subject, from } from 'rxjs';
import { catchError, tap, map, debounceTime, shareReplay, timeout, concatMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastService } from './toast.service';
import { SessionStore } from '../stores/session.store'
import { environment } from '../../environments/environment';
import { ImageCacheService } from './image-cache.service';
import { NativeLayerService } from './native-layer.service';

const PROTOCOL = environment.protocol;
const HOST = environment.host;
const PORT = environment.port;

function endpoint(service) {
  return `${PROTOCOL}://${HOST}:${PORT}${service}`;
}

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {

  cache: any = {};

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private cacheService: ImageCacheService,
    private native: NativeLayerService
  ) { }

  signin(userData, options = { error: true }): Observable<any> {
    return this.http.post(endpoint('/session'), userData, { observe: 'response' })
      .pipe(
        map(data => ({
          user: data.body,
          token: data.headers.get('jwt'),
          tokenNative: data.headers.get('jwt-native')
        })),
        catchError((error) => options.error && this.handleError(error))
      )
  }

  updateUser(id, data, options = { error: true }) {
    return this.http.patch(endpoint(`/users/${id}`), data)
      .pipe(
        tap(() => this.toast.show('Usuario modificado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  changePhoto(id, data, options = { error: true }) {
    return this.http.patch(endpoint(`/users/photo/${id}`), data)
      .pipe(
        tap(() => this.toast.show('Foto modificada correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  createUser(data, options = { error: true }) {
    return this.http.post(endpoint('/users'), data)
      .pipe(
        tap(() => this.toast.show('Usuario creado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  listUsers(params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/users'), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getUserDetail(id, options = { error: true }) {
    return this.http.get(endpoint(`/users/${id}`))
      .pipe(
        timeout(5000),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  createMaterial(data, options = { error: true }) {
    return this.http.post(endpoint('/materials'), data)
      .pipe(
        tap(() => this.toast.show('Material creado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  listMaterials(params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/materials'), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );

    // const normalizedEndpoint = endpoint('/materials');
    // const opts = { params: new HttpParams({ fromString: this.getQPFromJson(params) }) };
    //
    // // if (options.cache && this.cache[normalizedEndpoint]) {
    // //   return this.cache[normalizedEndpoint];
    // // }
    //
    // const observable = this.http.get(normalizedEndpoint, opts)
    //   .pipe(
    //     // shareReplay(1),
    //     catchError((error) => {
    //       // delete this.cache[normalizedEndpoint];
    //       return options.error && this.handleError(error)
    //     })
    //   );
    // // if (options.cache ) {
    // //   this.cache[normalizedEndpoint] = observable;
    // // }
    //
    // return observable

  }

  createProject(data, options = { error: true }) {
    return this.http.post(endpoint('/projects'), data)
      .pipe(
        tap(() => this.toast.show('Proyecto creado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  listProjects(params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/projects'), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  listStores(params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/stores'), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  changeParticipants(id, data, options = { error: true }) {
    return this.http.patch(endpoint(`/projects/${id}/participants`), data)
      .pipe(
        tap(() => this.toast.show('Paricipantes modificados correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getFromCatalog(type, params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint(`/catalogs/${type}`), opts)
      .pipe(
        map((data: any) => ({ data: data.data.map(( name ) => ({ name }) ) })),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  createLuminary(data, options = { error: true }) {
    let performedPhotos = of(null);

    if (data.photos) {
      performedPhotos = this.preparePhotos(data.photos)
    }

    return performedPhotos
      .pipe(
        concatMap((photos) =>
          this.http.post(endpoint('/luminaries'), {...data, photos})
            .pipe(
              tap(() => this.toast.show('Luminaria creada correctamente', 'ok')),
              catchError((error) => options.error && this.handleError(error))
            )
        )
      );
  }

  listLuminaries(params = {}, options = { error: true, timeout: false }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/luminaries'), opts)
      .pipe(
        options.timeout ? timeout(5000): tap(() => {}),
        catchError((error) => options.error && this.handleError(error))
      );
  }


  bulkLuminaries(project, fileToUpload: File, options = { error: true }) {
    const formData: FormData = new FormData();

    formData.append('bulkFile', fileToUpload, fileToUpload.name);
    return this.http.post(endpoint(`/luminaries/bulk/${project}`), formData)
      .pipe(
        tap(() => this.toast.show('Luminarias volcadas correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
    );
  }

  createAsocMaterial(data, options = { error: true }) {
    return this.http.post(endpoint('/asociate-materials'), data)
      .pipe(
        tap(() => this.toast.show('Material asociado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  listAsocMaterial(params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/asociate-materials'), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getAsocMaterial(params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/asociate-materials/materials'), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  changeAsocMaterial(id, params = {}, options = { error: true }) {
    return this.http.patch(endpoint(`/asociate-materials/${id}`), params)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  removeAsocMaterials(id, options = { error: true }) {
    return this.http.delete(endpoint(`/asociate-materials/${id}`))
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  deleteMaterial(id, options = { error: true }) {
    return this.http.delete(endpoint(`/materials/${id}`))
      .pipe(
        tap(() => this.toast.show('Material eliminado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getLuminaryDetail(id, params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint(`/luminaries/${id}`), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  createMaintenance(data, options = { error: true }) {
    return this.http.post(endpoint('/maintenances'), data)
      .pipe(
        tap(() => this.toast.show('Mantenimiento creado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  listMaintenances(params = { }, options = { error: true, cache: false }) {
    const opts = {
      params: new HttpParams({ fromString: this.getQPFromJson(params) }),
      headers: new HttpHeaders({ 'cache': `${options.cache}` })
    };

    return this.http.get(endpoint('/maintenances'), opts)
      .pipe(
        timeout(2000),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getProjectParticipants(role, params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint(`/projects/participants/${role}/`), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  assignMaintenancesToTechnical(maintenances, options = { error: true }) {
    return this.http.patch(endpoint('/maintenances/assign-to-technician/'), maintenances)
      .pipe(
        tap(() => this.toast.show('Mantenimiento asignado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  assignMaterials(idUser, data, options = { error: true }) {
    return this.http.post(endpoint(`/users/${idUser}/assignMaterials`), data)
      .pipe(
        tap(() => this.toast.show('Material asignado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  savePreassignedMaterials(idUser, data, options = { error: true }) {
    return this.http.patch(endpoint(`/users/${idUser}/preassignedMaterials`), data)
      .pipe(
        tap(() => this.toast.show('Material preasignado actualizado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  returnToStore(idUser, data, options = { error: true }) {
    return this.http.patch(endpoint(`/users/${idUser}/returnMaterialsToStore`), data)
      .pipe(
        tap(() => this.toast.show('Material devuelto correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  returnToInventory(idUser, data, options = { error: true }) {
    return this.http.patch(endpoint(`/users/${idUser}/returnMaterialsToInventory`), data)
      .pipe(
        tap(() => this.toast.show('Material devuelto a inventario correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  confirmMaterial(idUser, data = {}, options = { error: true }) {
    return this.http.patch(endpoint(`/users/${idUser}/confirmMaterial`), data)
      .pipe(
        tap(() => this.toast.show('Material asignado confirmado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  removePreassignedMaterials(idUser, options = { error: true }) {
    return this.http.delete(endpoint(`/users/${idUser}/preassignedMaterials`))
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  changeMaintenanceState(idMaintenance, { state, answers, questions }, options = { error: true }) {
    return this.http.patch(endpoint(`/maintenances/${idMaintenance}/changeState`), { state, answers, questions })
      .pipe(
        timeout(5000),
        tap(() => this.toast.show('Mantenimiento cambiado de estado', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getDetailMaintenance(idMaintenance, options = { error: true }) {
    return this.http.get(endpoint(`/maintenances/detail/${idMaintenance}`))
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  postponeMaintenance(idMaintenance, { repairmentDate }, options = { error: true }) {
    return this.http.post(endpoint(`/maintenances/${idMaintenance}/postpone`), { repairmentDate })
      .pipe(
        timeout(5000),
        tap(() => this.toast.show('Mantenimiento postpueto correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  closeMaintenance(idMaintenance, params, options = { error: true }) {
    return this.http.patch(endpoint(`/maintenances/${idMaintenance}/close`), params)
      .pipe(
        timeout(5000),
        tap(() => this.toast.show('Mantenimiento cerrado', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  undoMaterials(idMaintenance, options = { error: true }) {
    return this.http.patch(endpoint(`/maintenances/${idMaintenance}/undoMaterials`), {})
      .pipe(
        timeout(5000),
        tap(() => this.toast.show('Materiales eliminados correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  fetchStats(params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint('/maintenances/stats'), opts)
      .pipe(
        timeout(5000),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getFile(fileName, params = {}, options = { error: true, contentType: undefined }) {
    const opts = {
      params: new HttpParams({fromString: this.getQPFromJson(params)}),
      headers: new HttpHeaders({
        'Content-Type': options.contentType
      })
    };

    return this.http.get(endpoint(`/${fileName}`), {responseType: 'blob', ...opts})
      .pipe(
        timeout(7000),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  enrollmentFingerprint(publicKey, options = { error: true }): Observable<any> {
    return this.http.post(endpoint('/fingerprint'), { publicKey }, { observe: 'response' })
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      )
  }

  signFile(file, id, digest, options = { error: true }) {
    return this.http.post(endpoint('/documents/sign'), { fileName: file, taskId: id, digest })
      .pipe(
        timeout(5000),
        tap(() => this.toast.show('Documentación firmada correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  generateDocument(data, options = { error: true }): Observable<any> {
    return this.http.post(endpoint('/documents'), data)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      )
  }

  recoverAccess(data, options = { error: true }): Observable<any> {
    return this.http.post(endpoint('/recover-access'), data)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      )
  }

  removeMaintenance(id, options = { error: true }) {
    return this.http.delete(endpoint(`/maintenances/${id}`))
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }


  updateMaintenance(idMaintenance, {
    questions = null,
    answers = null,
    height = null,
    ascendMethod = null,
    luminary = null,
    photos = null,
    state = null,
    usedMaterials = null,
    extractedMaterials = null,
    description = null,
    repairmentDate = null,
    openDate = null,
  }, options = { error: true, alerts: true }) {
    return this.http.patch(endpoint(`/maintenances/${idMaintenance}`), { questions, answers, openDate, ascendMethod, height, luminary, photos, state, usedMaterials, extractedMaterials, description, repairmentDate })
      .pipe(
        timeout(7000),
        tap(() => options.alerts && this.toast.show('Mantenimiento actualizado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  preparePhotos(photos) {
    const performedPhotos = from(Promise.all(photos.map((photo) => {
      return new Promise((resolve) => {
        if (!photo.includes('/base64/')) {
          resolve(photo);

          return;
        }
        this.cacheService.getBase64(photo, !this.native.isNative).then((photo64) => {
          resolve(photo64);
        });
      });
    })));

    return performedPhotos;
  }
  updateLuminary(idLuminary, data, options = { error: true }) {
    let performedPhotos = of(null);

    if (data.photos) {
      performedPhotos = this.preparePhotos(data.photos)
    }

    return performedPhotos
      .pipe(
        concatMap((photos) =>
          this.http.patch(endpoint(`/luminaries/${idLuminary}`), {...data, ...photos && { photos }})
            .pipe(
              timeout(5000),
              tap(() => this.toast.show('Luminaria actualizada correctamente', 'ok')),
              catchError((error) => options.error && this.handleError(error))
            )
        )
      );
  }

  getProjectDetail(id, params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint(`/projects/${id}`), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  updateProject(id, data, options = { error: true }) {
    return this.http.patch(endpoint(`/projects/${id}`), data)
      .pipe(
        tap(() => this.toast.show('Proyecto guardado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getDailyQuestionary(id, params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint(`/projects/${id}/questionaries`), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getTaskDetail(id, options = { error: true }) {
    return this.http.get(endpoint(`/tasks/${id}`))
      .pipe(
        timeout(5000),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getTasks(params, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint(`/tasks/list`), opts)
      .pipe(
        timeout(5000),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  getStoreDetail(id, params = {}, options = { error: true }) {
    const opts = { params: new HttpParams({fromString: this.getQPFromJson(params)}) };

    return this.http.get(endpoint(`/stores/${id}`), opts)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      );
  }

  setCoworker(idUser, params, options = { error: true }) {
    return this.http.patch(endpoint(`/users/${idUser}/coworkers`), params)
      .pipe(
        tap(() => this.toast.show('Grupo guardado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }


  updateDailyQuestionary(id, params, options = { error: true }) {
    return this.http.patch(endpoint(`/projects/${id}/questionaries`), params)
      .pipe(
        timeout(4000),
        tap(() => this.toast.show('Cuestionario añadido correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  performDocumentation(params, options = { error: true }): Observable<any> {
    return this.http.post(endpoint('/documents'), params)
      .pipe(
        catchError((error) => options.error && this.handleError(error))
      )
  }

  createStore(data, options = { error: true }) {
    return this.http.post(endpoint('/stores'), data)
      .pipe(
        tap(() => this.toast.show('Almacén creado correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  addToStore(storeId, items, options = { error: true }) {
    return this.http.patch(endpoint(`/stores/${storeId}/items`), items)
      .pipe(
        tap(() => this.toast.show('Items añadidos al almacén', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  signinWithRecoverToken(data, options = { error: true }) {
    return this.http.post(endpoint('/session/recover-access'), data, { observe: 'response' })
    .pipe(
      map(data => ({
        user: data.body,
        token: data.headers.get('jwt'),
        tokenNative: data.headers.get('jwt-native')
      })),
      catchError((error) => options.error && this.handleError(error))
    )
  }

  createExpansion(data, options = { error: true }) {
    return this.http.post(endpoint('/expansions'), data)
      .pipe(
        timeout(5000),
        tap(() => this.toast.show('Expansion creada correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  createModernization(data, options = { error: true }) {
    return this.http.post(endpoint('/modernizations'), data)
      .pipe(
        timeout(15000),
        tap(() => this.toast.show('Modernización creada correctamente', 'ok')),
        catchError((error) => options.error && this.handleError(error))
      );
  }

  private handleError(error: any) {
    const data = {
      status: error.status,
      error: error.error
    };
    if (error.name === 'TimeoutError') {
      data.status = 0;
      data.error = {
        code: -2,
        message: 'Conexión limitada'
      }
    }
    if (error.status === 0) {
      data.status = 0;
      data.error = {
        code: -1,
        message: 'No dispones de conexión'
      }
    }
    switch(data.status) {
      case 0:
        this.toast.show(data.error.message);
        break;
      // Perder sesión
      case 402:
        if (data.error?.code === 666) {
          SessionStore.invalidate();
          this.toast.show(data.error.message);
          return;
        }
        this.toast.show(data.error.message);
        break;
      case 422:
        this.toast.show(data.error.message);
      break;
      default:
        this.toast.show(data.error.message || 'Servicio temporalmente no disponible');
    }
    return throwError(data.error);
  };

  private getQPFromJson(param) {
    return Object.keys(param).map((key) => {
      if (key && Array.isArray(param[key])) {
        return param[key].reduce((accumulator, name) => accumulator.append(key, name), new HttpParams());
      }
      return key && `${key}=${param[key]}`;
    }).filter(key=>key).join('&');
  }

}
