import { Component } from '@angular/core';

@Component({
    selector: 'app-bottom-navigation',
    template: `
        <div class="button" mat-ripple [routerLink]="['/app/dashboard/maintenances']" routerLinkActive="active-link">
            <mat-icon>build</mat-icon>
            <div class="text">Mantenimientos</div>
        </div>
        <div class="button" mat-ripple [routerLink]="['/app/dashboard/materials']" routerLinkActive="active-link">
            <mat-icon>extension</mat-icon>
            <div class="text">Materiales</div>
        </div>
        <div class="button" mat-ripple [routerLink]="['/app/dashboard/expansions']" routerLinkActive="active-link">
            <mat-icon>wb_incandescent</mat-icon>
            <div class="text">Expansiones</div>
        </div>
        <div class="button" mat-ripple [routerLink]="['/app/dashboard/modernizations']" routerLinkActive="active-link">
            <mat-icon>refresh</mat-icon>
            <div class="text">Modernizaciones</div>
        </div>
    `,
    styleUrls: ['./bottom-navigation.component.css'],
})
export class BottomNavigationComponent { }
