<mat-form-field appearance="outline" class="month-day margin-right-8">
  <mat-label>Día</mat-label>
  <mat-select (selectionChange)="change($event, 'day')" [(value)]="day">
    <mat-option *ngFor="let it of [].constructor(days); let day = index" [value]="day+1">
      {{day+1}}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="outline" class="month-day margin-right-8">
  <mat-label>Mes</mat-label>
  <mat-select (selectionChange)="change($event, 'month')" [(value)]="month">
    <mat-option *ngFor="let it of [].constructor(months); let month = index" [value]="month+1">
      {{month+1}}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="outline" class="year">
  <mat-label>Año</mat-label>
  <mat-select (selectionChange)="change($event, 'year')" [(value)]="year">
    <mat-option *ngFor="let year of years" [value]="year">
      {{year}}
    </mat-option>
  </mat-select>
</mat-form-field>
