<div  [ngClass]="theme">
    <div class="wrapper">
        <div>
            <mat-icon class="icon">{{icon}}</mat-icon>
        </div>
        <div class="text">
            <span>{{text}}</span>
        </div>
    </div>
    <div class="link" *ngIf="linkText">
        {{linkText}}
    </div>
</div>

  