import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-users-dialog',
  templateUrl: './select-users-dialog.component.html',
  styleUrls: ['./select-users-dialog.component.css']
})
export class SelectUsersDialogComponent implements OnInit {
  public static UNIQUE_SELECTION = 'unique';
  selectedUsers;
  disabledUsers;
  project;
  roleId;
  belongsToMyProjects;
  multipleSelection;
  constructor(
    public dialogRef: MatDialogRef<SelectUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
     this.selectedUsers = data.selected || [];
     this.disabledUsers = data.disabled || [];
     this.project = data.project;
     this.roleId = data.roleId;
     this.belongsToMyProjects = data.belongsToMyProjects;
     this.multipleSelection = data.selection !== SelectUsersDialogComponent.UNIQUE_SELECTION;
  }

  ngOnInit(): void {
  }


  onDoAction({ event, checked, user}) {
    if (checked) {
      this.selectedUsers = [...this.selectedUsers, ...[user]];
    } else {
      this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
    }
  }

  close() {
    this.dialogRef.close({ close: true });
  }

  accept() {
    this.dialogRef.close({ participants: this.selectedUsers });
  }

  onSelectUser(event) {
    if (this.multipleSelection) {
      return;
    }

    this.dialogRef.close({ selectedUser: event.user });
  }

}
