<div class="container">
    <div class="scrollable-content">
      <simple-header title="Asociación de material" [back]="true" icon="close"></simple-header>
      <div class="inline-flex margin-bottom-8">
        <tag-automatic-color icon="memory" [text]="technology" class="actionable" matRipple *ngIf="technology"></tag-automatic-color>
        <tag-automatic-color icon="offline_bolt" [text]="power" class="actionable" matRipple *ngIf="power"></tag-automatic-color>
      </div>
      <div cdkDropListGroup class="group">
        <div class="material-container">
          <h3>Listado de material</h3>
          <div
            cdkDropList
            [cdkDropListData]="possibleMaterial"
            class="list"
            (cdkDropListDropped)="drop($event)">
            <div class="box" *ngFor="let item of possibleMaterial" cdkDrag>{{item.name}}</div>
          </div>
        </div>
        <div class="material-container">
          <h3>Material necesario</h3>
          <div
            cdkDropList
            [cdkDropListData]="selectedMaterial"
            class="list"
            (cdkDropListDropped)="drop($event)">
            <div class="box" *ngFor="let item of selectedMaterial" cdkDrag>
              <div>
                {{item.name}}
              </div>
              <number-increment [(value)]="item.amount" [unit]="item.unit"></number-increment>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="align-center padding-top-16">
      <button [disabled]="loading" mat-raised-button color="primary" class="button-size-200" (click)="save()">Guardar</button>
      <div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
  