<simple-header title="Búsqueda avanzada" (action)="close()" icon="close"></simple-header>
<div class="content-scroll">

  <form [formGroup]="searchFormGroup">
    <predictive-searcher
      formControlName="selectedProjectForm"
      label="Proyecto"
      [service]="searchProjectAdapter"
      class=""></predictive-searcher>
    <div class="margin-bottom-16">
      <mat-checkbox [(ngModel)]="withoutNumber" [ngModelOptions]="{standalone: true}">Sin enumerar</mat-checkbox>
    </div>
    <mat-form-field *ngIf="!withoutNumber" appearance="outline" class="full-width">
      <mat-label>Número</mat-label>
      <input [readonly]="withoutNumber" matInput maxlength="20" formControlName="number">
    </mat-form-field>
    <predictive-searcher
      formControlName="neighborhoodForm"
      label="Barrio"
      [service]="searchNeighborhoodAdapter"
      class=""></predictive-searcher>
    <input-place
      label="Ubicación"
      placeholder="CLL 33 45"
      dark
      formControlName="location"
      appearance="fill"></input-place>
  </form>

  <div class="padding-bottom-8 button">
    <button (click)="filter()" mat-raised-button color="primary" class="button-size-200">Buscar</button>
  </div>

</div>
