import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiManagerService } from '@ildes/services/api-manager.service';

@Component({
    selector: 'app-add-store-items',
    template: `
    <div class="container">
       <div class="scrollable-content">
           <simple-header title="Añadir items" [back]="true" icon="close"></simple-header>
           <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
           <form [formGroup]="storeForm" class="padding-top-8">
                <div class="inline-flex">
                    <predictive-searcher
                    formControlName="materialName"
                    label="Añadir material"
                    [service]="searchMaterialAdapter"
                    (selectedValue)="onSelectedValue($event)"
                    class="column margin-right-8"
                    ></predictive-searcher>
                </div>
            </form>
            <div *ngFor="let material of selectedMaterial">
                <div class="padding-top-8 padding-bottom-8">
                    <div>
                        {{material.name}}
                    </div>
                    <div class="row padding-top-8">
                        <mat-form-field appearance="outline" class="column margin-right-8">
                            <mat-label>Cantidad</mat-label>
                            <input type="number" (change)="setAmount($event, material)" matInput required maxlength="20">
                            <mat-error>
                                Campo obligatorio
                            </mat-error>
                        </mat-form-field>  
                        <mat-form-field appearance="outline" class="column margin-right-8">
                            <mat-label>Coste (COL)</mat-label>
                            <input type="number" (change)="setCost($event, material)" matInput required maxlength="20">
                            <mat-error>
                                El coste es obligatorio
                            </mat-error>
                        </mat-form-field>    
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <button [disabled]="loading || isInvalidInput" mat-button (click)="save()" color="primary">Guardar</button>
        </div>
     </div>
    `,
    styleUrls: ['./add-store-items.component.css'],
})
export class AddStoreItemsComponent implements OnInit{

    storeId;
    loading;
    searchMaterialAdapter;
    selectedMaterial = [];
    storeForm = new UntypedFormGroup({
        materialName: new UntypedFormControl(),
        amount: new UntypedFormControl(),
        cost: new UntypedFormControl(),
    });

    constructor(
        private apiManager: ApiManagerService,
        private route: ActivatedRoute,
        private location: Location
    ) {

    }

    get storeName() {
        return this.storeForm.get('name')?.value
    }

    get ubication() {
        return this.storeForm.get('location')?.value
    }

    get address() {
        return this.ubication?.typed;
      }

    get latitude() {
        return parseFloat(this.ubication?.lat);
    }

    get longitude() {
        return parseFloat(this.ubication?.lng);
    }

    ngOnInit(): void {
        this.searchMaterialAdapter = () => {
            return this.apiManager.listMaterials();
        };

        this.route.queryParams.subscribe(params => {
            this.storeId = params.storeId;
        });
    }

    get selectedProject() {
        return this.storeForm.get('selectedProjectForm')?.value?.selected;
    }

    setCost($event, material) {
        material.cost = parseFloat($event.target.value);
        console.log($event.target.value, material);
    }

    setAmount($event, material) {
        material.amount = parseFloat($event.target.value);
    }

    save() {
        const materials = this.selectedMaterial.filter((material) => material.amount * material.cost !== 0 );

        this.loading = true;
        this.apiManager.addToStore(this.storeId, { items: materials }).subscribe(() => {
            this.loading = false;
            this.location.back();
        }, () => {
            this.loading = false;
        })
    }

    onSelectedValue($event) {
        this.selectedMaterial = [...this.selectedMaterial, {...$event.option.value, amount: 0}];

        this.storeForm.reset();
    }

    get isInvalidInput() {
        return !this.selectedMaterial?.length || this.selectedMaterial.some(({amount, cost}) => {
            return amount === undefined || cost === undefined
        })
    }
}
