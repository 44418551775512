import { inject } from '@angular/core';
import { ImageCacheService } from '@ildes/services/image-cache.service';
export class PhotosUtils {
    private cacheService:any = inject(ImageCacheService);

    blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
    }
    async toBase64(photos) {
        const result = await Promise.all(photos.map(async(photo) => {
            if (!photo.includes('/base64/')) {
              return photo;
            }
            const buffer = await this.cacheService.get(photo).then((response)=>response.blob());
            const base64 = await this.blobToBase64(buffer);
    
            return base64;
        }));

        return result;
    }

  }