import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../../services/api-manager.service';
import { SessionStore } from '../../../stores/session.store';

function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  password;
  loading;

  constructor(
    private apiManager: ApiManagerService
  ) { }

  ngOnInit(): void {
    this.password = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, this.passwordsShouldMatch);
  }

  save() {
    const sessionStore = SessionStore.getInstance();
    const data = {
      password: this.password.controls.password.value
    };
    const id = sessionStore.get().user.id;
    this.loading = true;
    this.apiManager.updateUser(id, data).subscribe(() => {
      this.loading = false;
    }, (data) => {
      this.loading = false;
    })
  }

  private passwordsShouldMatch(fGroup: UntypedFormGroup) {
    return fGroup.get('password').value === fGroup.get('confirmPassword').value ? null : { 'mismatch': true };
  }

}
