import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
const MIN_YEAR = 1935;
@Component({
  selector: 'date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css']
})
export class DateSelectorComponent implements OnInit {

  @Input() date;
  @Input() year;
  @Input() month;
  @Input() day;
  @Input() minYear = MIN_YEAR;
  @Input() maxYear;
  @Output() onChangeDate =  new EventEmitter();

  months = 12;

  constructor() { }

  ngOnInit(): void {
    const today = new Date();

    this.maxYear = this.maxYear || today.getFullYear();
    if (this.date) {
      const formatDate = new Date(this.date);

      this.day = formatDate.getDate();
      this.month = formatDate.getMonth() + 1;
      this.year = formatDate.getFullYear();

      return;
    }
    this.year = this.year || this.minYear;
    this.day = this.day || 1;
    this.month = this.month || 1;
  }

  get years() {
    const years = [];

    for (let i = this.minYear; i <= this.maxYear; i++) {
      years.push(i);
    }

    return years;
  }

  get days() {
    return new Date(this.year, this.month + 1, 0).getDate();
  }

  change(event, change) {
    if (this.day === this.days) {
      this.day--;
    }
    this.onChangeDate.emit({
      selectedDate: new Date(this.year, this.month - 1, this.day),
      change
    });
  }

}
