import { Injectable } from '@angular/core';
import { StorageService } from '@ildes/services/storage-service.service';

@Injectable({
  providedIn: 'root'
})
export class UnsyncDataService {
  public storageName;
  constructor(
    public storageService: StorageService,
    storageName,
  ) {
    this.storageName = storageName;
  }

  getItems(raw = false) {
    const items = this.storageService.getItem(this.storageName);

    if (raw) {
      return items;
    }
    return items?.map(this.normalize);
  }

  normalize(item) {
    return item;
  }

  addItem(item) {
    let list = this.storageService.getItem(this.storageName) || [];

    if (item.id) {
      this.update(item);

      return item.id;
    }
    const id = parseInt(''+Math.random()*100000);
    list = [...list, {...item, id}];
    this.storageService.setItem(this.storageName, list);

    return id;
  }

  removeItem(item) {
    let list = this.storageService.getItem(this.storageName) || [];

    list = list?.filter( (itemList) => {
      return item.id !== itemList.id;
    });
    this.storageService.setItem(this.storageName, list);
    return list;
  }

  update(item) {
    let list = this.storageService.getItem(this.storageName) || [];

    list = list?.map( (itemList) => {
      if (item.id === itemList.id) {
        return item;
      }
      return itemList;
    })
    this.storageService.setItem(this.storageName, list);
  }

  findItem(id) {
    let list = this.storageService.getItem(this.storageName) || [];

    return list?.find( (item) => `${item.id}` === `${id}`);
  }

}
