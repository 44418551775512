import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../services/api-manager.service';
import * as moment from 'moment';
import { SessionStore } from '../../stores/session.store';
const PARTS = [
  {
    id:'NATURAL',
    description:'Natural'
  },
  {
    id:'JURIDIC',
    description:'Jurídico'
  }
];

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {

  projectForm;
  loading;
  subscriptionDate;
  minutesDate;
  contractParts = PARTS;
  dinamicParts = [
    {
      name: '',
      type: '',
      percent: 0, 
    }
  ];

  public searchDepartmentAdapter;
  public searchCityAdapter;
  private currentDepartment;

  get days() {
    return this.endDate.diff(this.minutesDate || this.subscriptionDate, 'days');
  }

  get endDate() {
    const currentDate = moment(this.minutesDate || this.subscriptionDate);
    const years = this.projectForm.controls.years.value;
    const months = this.projectForm.controls.months.value;

    if(!currentDate.isValid() || (years === 0 && months === 0)) {
      return null;
    }
    return currentDate
      .add(years, 'year')
      .add(months, 'month')
      .subtract(1, 'day');
  }

  get formattedEndDate() {
    return this.endDate?.format('DD/MM/YYYY');
  }

  constructor(
    private apiManager: ApiManagerService
  ) { }

  ngOnInit(): void {
    this.searchDepartmentAdapter = (data) => {
      return this.apiManager.getFromCatalog('department', data);
    };
    this.searchCityAdapter = (data) => {
      const classification = this.projectForm.controls.department.value.selected.name;

      return this.apiManager.getFromCatalog('city', { text: data.text, classification});
    };
    this.resetForm();
  }

  private resetForm() {
    this.subscriptionDate = new Date();
    this.minutesDate = false;
    this.projectForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      object: new UntypedFormControl('', [Validators.required]),
      contractType: new UntypedFormControl('', [Validators.required]),
      contractNumber: new UntypedFormControl('', [Validators.required]),
      value: new UntypedFormControl('', [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))]),
      months: new UntypedFormControl(0, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      years: new UntypedFormControl(0, [Validators.pattern(new RegExp('^[0-9]+$'))]),
      address: new UntypedFormControl('', [Validators.required]),
      department: new UntypedFormControl('', [Validators.required]),
      city: new UntypedFormControl('', [Validators.required]),
    }, this.durationShouldBeGreaterThanOne);
  }
  setSubscriptionDate(event) {
    this.subscriptionDate = event.selectedDate;
  }

  setMinutesDate(event) {
    this.minutesDate = event.selectedDate;
  }

  changeDepartment($event) {
    if ( this.currentDepartment && this.currentDepartment !== $event.option.value?.name) {
      this.currentDepartment = $event?.name;
      console.log('reset')
      this.projectForm.controls.city.reset();
    } 
  }

  get addressData() {
    return {
      address: this.projectForm.controls.address.value,
      department: this.projectForm.controls.department.value.selected.name,
      city: this.projectForm.controls.city.value.selected.name
    }
  }

  create() {
    const {
      name,
      object,
      contractType,
      contractNumber,
      value
    } = this.projectForm.controls;
    const length = this.dinamicParts.length;
    const contractParts = this.dinamicParts.slice(0, length - 1);
    const data = {
      parts: contractParts,
      name: name.value,
      object: object.value,
      contractType: contractType.value,
      contractNumber: contractNumber.value,
      value: value.value,
      minutesDate: this.minutesDate || this.subscriptionDate,
      subscriptionDate: this.subscriptionDate,
      duration: this.days,
      address: this.addressData
    };
    this.loading = true;
    this.apiManager.createProject(data).subscribe(() => {
      this.loading = false;
      this.resetForm();
    }, (data) => {
      this.loading = false;
    });
  }

  public toggleMinutesDate() {
    if (this.minutesDate) {
      this.minutesDate = false;

      return;
    }
    this.minutesDate = this.subscriptionDate;
  }

  private durationShouldBeGreaterThanOne(fGroup: UntypedFormGroup) {
    if (!fGroup.get('months').touched && !fGroup.get('years').touched) {
      return null;
    }
    return fGroup.get('months').value > 0 || fGroup.get('years').value > 0 ? null : { 'validDuration': true };
  }

  addPart() {
    this.dinamicParts.push({
      name: '',
      percent: 0,
      type: '',
    })
  }


  get disabledAddPart() {
    const last = this.dinamicParts[this.dinamicParts.length -1];

    return !last.name || !last.percent || !last.type;
  }

  removePart(index) {
    this.dinamicParts = this.dinamicParts.filter((_, cIndex) => index!==cIndex);
  }

  get erorInParts () {
    const length = this.dinamicParts.length;

    return  length > 1 && this.dinamicParts.slice(0, length - 1).reduce((sum, part) => part.percent + sum, 0) !== 100;
  }
}
