<div class="container padding-8" *ngIf="selectedUser">
  <simple-header title="Perfil" [back]="true" icon="close"></simple-header>
  <form [formGroup]="profile" class="padding-top-8 padding-bottom-8">
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" maxlength="20">
        <mat-error>
            El nombre es obligatorio
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Apellidos</mat-label>
        <input matInput formControlName="surname" maxlength="40">
        <mat-error>
            Los apellidos son obligatorios
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width" [allowAction]="['users.updateEmail']">
        <mat-label>Correo electrónico</mat-label>
        <input matInput placeholder="Ex. pat@example.com" formControlName="email">
        <mat-error>
            El email es obligatorio y debe ser un email válido
        </mat-error>
      </mat-form-field>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Género</mat-label>
          <mat-select [(value)]="gender">
            <mat-option *ngFor="let currentGender of genders" [value]="currentGender.id">
              {{currentGender.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div [allowAction]="['users.updateRole']">
        <mat-form-field appearance="outline">
          <mat-label>Rol</mat-label>
          <mat-select [(value)]="role">
            <mat-option *ngFor="let currentRole of roles" [value]="currentRole.id">
              {{currentRole.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="align-center">
      <div class="padding-bottom-8">
        <button (click)="save()" [disabled]="profile.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
      </div>
      <div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </form>
</div>
