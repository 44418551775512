import { Injectable, Inject } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ConnectionStateService } from '@ildes/services/connection-state.service';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const WHITE_LIST = ['/jsonplaceholder'];

import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class ConnectionStateInterceptor implements HttpInterceptor {

  private updateConnectionSubject;
  constructor(
    private connectionState: ConnectionStateService
    ) {
    this.updateConnectionSubject = new Observable(subscriber => {
      this.connectionState.update();
    }).pipe(debounceTime(10000));
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<any> {

    if (!WHITE_LIST.filter((signature) => req.url.indexOf(signature) >= 0 ).length) {
      return next.handle(req).pipe(
        tap(() => {
          this.connectionState.update();
        }),
        catchError((error) => {
          if (error.name === 'HttpErrorResponse' && error.status === 0) {
            this.connectionState.update(true);
          }
          return throwError(error);
        })
      )
    }

    return next.handle(req);
  }
}
