import { Component, OnInit, NgZone } from '@angular/core';
import  { ConnectionStateService, CONNECTED, NO_CONNECTION, LIMITED_CONNECTION, RECONNECTING, Status } from '@ildes/services/connection-state.service';

@Component({
  selector: 'connection-alert',
  templateUrl: './connection-alert.component.html',
  styleUrls: ['./connection-alert.component.css']
})
export class ConnectionAlertComponent implements OnInit {

  private status: Number = null;
  public navigatorStatus: Status;
  public log: String[] = [];

  public get statusText(): string {
    switch (this.status) {
      case CONNECTED:
          return 'conectado';
      case NO_CONNECTION:
        return 'desconectado';
      case LIMITED_CONNECTION:
        return 'conexión limitada o nula';
      case RECONNECTING:
        return 'comprobando conexión';
      default:
        return '';
    }
  }

  public get theme(): string {
    switch (this.status) {
      case CONNECTED:
          return 'ok';
      case NO_CONNECTION:
        return 'error';
      case LIMITED_CONNECTION:
        return 'warning';
      case RECONNECTING:
        return 'advise';
      default:
        return '';
    }
  }

  public get icon(): string {
    switch (this.status) {
      case CONNECTED:
          return 'cloud_done';
      case NO_CONNECTION:
        return 'cloud_off';
      case LIMITED_CONNECTION:
        return 'signal_wifi_bad';
      case RECONNECTING:
        return 'loop';
      default:
        return '';
    }
  }

  constructor(public connectionStatus: ConnectionStateService, private zone: NgZone ) {
    this.connectionStatus.statusObserver.subscribe((data: { state: Status }) => {
      if (this.status !== CONNECTED && data.state.state === CONNECTED && this.status !== null) {
        setTimeout(() => {
          this.status = null;
        }, 2000);

        this.status = data.state.state;
      } else if(data.state.state !== CONNECTED) {
        this.status = data.state.state;
      }
    });
  }

  ngOnInit(): void {
    
  }

}
