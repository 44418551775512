import { Inject, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const CLOSE = { id:'close', icon:'close' };

@Component({
  selector: 'app-maintenance-questionary-dialog',
  templateUrl: './maintenance-questionary-dialog.component.html',
  styleUrls: ['./maintenance-questionary-dialog.component.css']
})
export class MaintenanceQuestionaryDialogComponent implements OnInit {

  questionForm;
  headerActions = [CLOSE];
  questions = [];
  answers = [];
  readOnly = false;
  title = 'Cuestionario de seguridad';
  buttonText = 'Aceptar';

  constructor(
    public dialogRef: MatDialogRef<MaintenanceQuestionaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.questions = data.questions;
    this.answers = !data.answers?.length ? this.questions.map((question: any) => ({
      id: question.id,
      value: true
    })) : data.answers;
    this.readOnly = data.readOnly
    this.title = data.title || this.title;
    this.buttonText = data.buttonText || this.buttonText;

    if (!data.buttonText && this.readOnly) {
      this.buttonText = 'Cerrar';
    }
  }

  ngOnInit(): void {
    const formControlsQuestions = this.questions.reduce(
      (res, question: any) => {
        const foundedAnswer = this.answers.find((answer) => question.id === answer.id);

        return {
          ...res,
          ...{
            [question.id]: new UntypedFormControl(foundedAnswer?.value)},
          ...question.observations && {[`observation_${question.id}`]: new UntypedFormControl(foundedAnswer?.observations || '')}
        }
      }, {});

    this.questionForm = new UntypedFormGroup(formControlsQuestions);
  }

  headerAction(action) {
    if (action.id === CLOSE.id) {
      this.close();
    }
  }

  close(commit = false) {
    this.dialogRef.close({ commit, answers: this.getFlattenedAnswers() });
  }

  private getFlattenedAnswers() {
    const controls = this.questionForm.controls;

    return this.questions.map((question) => ({
      id: question.id,
      value: controls[question.id].value,
      ...!controls[question.id].value && { observations: controls[`observation_${question.id}`]?.value }
    }));
  }

  get acceptedAllQuestionary() {
    const controls = this.questionForm.controls;

    return Object.keys(controls).reduce((res, questionKey) =>
      controls[questionKey].value && res
    , true);
  }

}
