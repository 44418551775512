<div class="icon-state" [ngClass]="check ?'check': 'uncheck'">
  <mat-icon *ngIf="check && !loading">check_circle</mat-icon>
  <mat-icon *ngIf="!check && state==='PENDING' && !loading">pending</mat-icon>
  <mat-icon *ngIf="!check && state==='IN_PROGRESS' && !loading">check_circle_outline</mat-icon>
  <div *ngIf="loading" style="width: 30px;height:30px;">
    <app-loading diameter="30"></app-loading>
  </div>
</div>
<div class="step-content">
  <h3 class="title" [ngClass]="{'disabled': !check && disabled}">
    {{title}}
  </h3>
  <div class="description" [innerHTML]="description"></div>
</div>
<div *ngIf="!disableAction && !disabled" class="icon-goto">
  <mat-icon>arrow_forward_ios</mat-icon>
</div>
