import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-material',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {


  constructor(
    private location: Location
  ) { }

  back() {
    this.location.back();
  }
}
