<div class="container">
  <list-users-composition
    class="full-height"
    (onClickItem)="onDoAction($event)"
    [belongsToMyProjects]="true"
    [actionEvents]="actions"
    (doAction)="onDoAction($event)"
    roleId="DRIVER"
    paddingBottom="true">
    <simple-header title="Operaciones" [back]="true" icon="close"></simple-header>
    <h3> Selecciona un técnico </h3>
  </list-users-composition>
</div>