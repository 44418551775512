<div class="container-photo">
  <profile-avatar size="small" [name]="name" [surname]="surname" [image]="photo | imagePath"></profile-avatar>
</div>
<div class="data margin-left-16 actionable" matRipple role="button" (click)="linkClicked()">
  <div>
    <span class="name">{{name}} {{surname}}</span>
  </div>
  <div class="subtitle">
    <span class="email">{{email}}</span>
    <span class="phone" *ngIf="phone"> | {{phone}}</span>
  </div>
  <div class="tag actionable" matRipple role="button" *ngIf="role">
    {{role}}
  </div>
</div>
<div class="actions">
  <ng-container [ngTemplateOutlet]="actionsTemplateRef">
  </ng-container>
</div>
