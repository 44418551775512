import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'composition-basic-profile',
  templateUrl: './composition-basic-profile.component.html',
  styleUrls: ['./composition-basic-profile.component.css']
})
export class CompositionBasicProfileComponent implements OnInit {

  @Input() image;
  @Input() name;
  @Input() surname;
  @Input() description;
  @Output() onLinkClick: EventEmitter<any> = new EventEmitter();

  linkClick() {
    this.onLinkClick.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
