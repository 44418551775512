import { Component, OnInit, Input } from '@angular/core';

const COLORS = [
  'light-blue',
  'cyan',
  'green',
  'pink',
  'orange',
];

@Component({
  selector: 'profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.css']
})
export class ProfileAvatarComponent implements OnInit {

  @Input() image;
  @Input() name;
  @Input() surname;
  @Input() bordered;

  constructor() { }

  ngOnInit(): void {
  }

  get firstLetter() {
    return this.name?.[0] || 'A';
  }

  get secondLetter() {
    return this.surname?.[0] || 'N';
  }

  get calculatedBackground() {
    const index = parseInt(`${this.firstLetter.charCodeAt()}${this.secondLetter.charCodeAt()}`);

    return COLORS[index%COLORS.length];
  }

}
