import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../../services/api-manager.service';
import { SessionStore } from '../../../stores/session.store';
import { ActivatedRoute } from '@angular/router';

const DOCUMENTS_TYPES = [
  {
    id: 'CC',
    description: 'Cédula de ciudadanía',
    maxLength: 8
  }, {
    id: 'CE',
    description: 'Cédula de extranjería',
    maxLength: 6
  }
];
const BLOOD_TYPES = [
  {
    id: '0+',
    description: '0+'
  }, {
    id: '0-',
    description: '0-'
  }, {
    id: 'A+',
    description: 'A-'
  }, {
    id: 'B-',
    description: 'B-'
  }, {
    id: 'AB+',
    description: 'AB+'
  }, {
    id: 'AB-',
    description: 'AB-'
  }
];
const CONTRACT_TYPES = [
  {
    id: 'OBRA',
    description: 'Obra y servicio'
  }, {
    id: 'INDEFINIDO',
    description: 'Indefinido'
  }
];

@Component({
  selector: 'app-edit-documentation-and-hhrr-information',
  templateUrl: './edit-documentation-and-hhrr-information.component.html',
  styleUrls: ['./edit-documentation-and-hhrr-information.component.css']
})
export class EditDocumentationAndHhrrInformationComponent implements OnInit {

  profile;
  loading;
  selectedUser;
  documentTypes = DOCUMENTS_TYPES;
  documentType;
  bloodTypes = BLOOD_TYPES;
  bloodType;
  contractTypes = CONTRACT_TYPES;
  contractType;
  expeditionDate;
  bornDate;
  id;
  loadingData;
  user;

  get documentNumberMaxLength() {
    return this.documentType?.maxLength;
  }
  constructor(
    private apiManager: ApiManagerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const sessionStore = SessionStore.getInstance();

    this.user = sessionStore.get().user;

    this.route.queryParams.subscribe(params => {
      if (!params?.id) {
        this.selectedUser = sessionStore.get().user;

        this.setFormData();
      } else {
        this.id = params.id;
        this.loadingData = true;

        this.apiManager.getUserDetail(this.id).subscribe((user) => {
          this.selectedUser = user;
          this.setFormData();
          this.loadingData = false;
        });
      }
    });
  }

  private setFormData() {
    this.documentType = this.documentTypes.find(type => type.id === this.selectedUser.document?.type?.id);
    this.bloodType = this.bloodTypes.find(type => type.id === this.selectedUser.bloodType?.id);
    this.contractType = this.contractTypes.find(type => type.id === this.selectedUser.contractType?.id);
    this.expeditionDate = this.selectedUser.document?.expeditionDate;
    this.bornDate = this.selectedUser.bornDate;
    this.profile = new UntypedFormGroup({
      documentNumber: new UntypedFormControl(this.selectedUser.document?.number || '', [Validators.required]),
      expeditionPlace: new UntypedFormControl(this.selectedUser.document?.expeditionPlace || '', [Validators.required])
    });
  }

  save(){
    const {
      documentNumber,
      expeditionPlace
    } = this.profile.controls;
    const data = {
      document: {
        type: this.documentType,
        number: documentNumber.value,
        expeditionPlace: expeditionPlace.value,
        expeditionDate: this.expeditionDate
      },
      contractType: this.contractType,
      bloodType: this.bloodType,
      bornDate: this.bornDate
    };
    this.loading = true;
    this.apiManager.updateUser(this.selectedUser.id, data).subscribe(() => {
      this.loading = false;

      if (this.selectedUser.id === this.user.id) {
        SessionStore.updateUserInfo(data);
      }

    }, (data) => {
      this.loading = false;
    })
  }

  setExpeditionDate(event) {
    this.expeditionDate = event.selectedDate;
  }

  setBornDate(event) {
    this.bornDate = event.selectedDate;
  }

}
