import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

const HOST = environment.host;
const PORT = environment.port;
const PROTOCOL = environment.protocol;

@Pipe({
  name: 'imagePath'
})
export class ImagePathPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value && (value.search('assets') === 0 || value.search('storage') === 0)) {
      const uri = `/${value}`.replaceAll('//', '/');
      return value ? `${PROTOCOL}://${HOST}:${PORT}${uri}`: null;
    } else {
      return value;
    }
  }

}
