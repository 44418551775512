<mat-toolbar>
  <button (click)="snav.toggle()" mat-icon-button aria-label="abrir menú">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="column-full-space">
    <img src="./assets/images/logo.png" class="logo">
  </div>
  <!-- <button (click)="openSettings()" mat-icon-button aria-label="ajustes de sesión">
    <mat-icon>settings</mat-icon>
  </button> -->
</mat-toolbar>
<mat-drawer-container class="container" >
  <mat-drawer #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!this.mobileQuery.matches" class="drawer-menu" color="primary">
    <composition-basic-profile (onLinkClick)="gotoProfile()" class="padding-8" [name]="user?.name" [surname]="user?.surname" [description]="user?.role?.description" [image]="user?.photo | imagePath"></composition-basic-profile>
    <mat-divider></mat-divider>
    <div class="padding-top-8 paddin-bottom-8" *ngIf="user?.role?.id === 'DRIVER'">
      <button (click)="configureGroup()" mat-button class="btn-menu" *ngIf="user?.role?.id === 'DRIVER' && !user?.coworkers?.length">
        <mat-icon>group_add</mat-icon>Configurar grupo de trabajo
      </button>
      <list-user-item
        (click)="configureGroup()"
        *ngIf="user.coworkers?.length"
        [name]="user?.coworkers[0].name"
        [surname]="user?.coworkers[0].surname"
        class="padding-left-8 center-vertically"
        >
      </list-user-item>
    </div>
    <mat-divider *ngIf="user?.role?.id === 'DRIVER'"></mat-divider>
    <mat-action-list>
      <button [routerLink]="['/app/dashboard/maintenances']" mat-button class="btn-menu" *ngIf="user?.role?.id === 'TECHNICIAN' || user?.role?.id === 'DRIVER'">
        <mat-icon>dashboard</mat-icon>Dashboard
      </button>
      <button mat-button class="btn-menu" [routerLink]="['/app/list-luminaries']">
        <mat-icon>batch_prediction</mat-icon>Luminarias
      </button>
      <button mat-button class="btn-menu" [allowAction]="['maintenances.listAssignedToMe']" [routerLink]="['/app/list-maintenances']">
        <mat-icon>build</mat-icon>Mis Mantenimientos
      </button>
      <button mat-button class="btn-menu" [allowAction]="['maintenances.list']" [routerLink]="['/app/list-maintenances']">
        <mat-icon>build</mat-icon>Mantenimientos
      </button>
      <button mat-button class="btn-menu" [allowAction]="['users.listMyBelongsToMyProjects', 'users.detail']" [routerLink]="['/app/technicians']">
        <mat-icon>engineering</mat-icon>Técnicos
      </button>
      <button mat-button class="btn-menu" [allowAction]="['materials.create']" (click)="gotoControlPanel()">
        <mat-icon>settings</mat-icon>Panel de control
      </button>
      <button mat-button class="btn-menu" (click)="gotoMailbox()">
        <mat-icon>mail</mat-icon>Buzón
      </button>
      <mat-divider></mat-divider>
      <button mat-button class="btn-menu" (click)="exit()">
        <mat-icon>exit_to_app</mat-icon>Salir
      </button>
    </mat-action-list>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
