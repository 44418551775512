import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../services/api-manager.service';

@Component({
  selector: 'app-user-settings-dialog',
  templateUrl: './user-settings-dialog.component.html',
  styleUrls: ['./user-settings-dialog.component.css']
})
export class UserSettingsDialogComponent implements OnInit {

  selectedProject = null;

  searchFormGroup = new UntypedFormGroup({
    selectedProjectForm: new UntypedFormControl()
  });

  showFormChangeProject = false;

  searchProjectAdapter;

  constructor(
    private apiManager: ApiManagerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    this.searchProjectAdapter = (data) => {
      return this.apiManager.listProjects(data);
    };
  }

  onSelectedValue(value) {
    this.selectedProject = this.searchFormGroup.controls.selectedProjectForm?.value?.selected
    this.showFormChangeProject = false;
  }

}
