<div class="inline-flex wrap adjust-width">
  <div class="column adjust-width">
    <h3 *ngIf="number">{{projectName}} | {{number}}</h3>
    <h3 *ngIf="!number"><i>{{projectName}} | Sin número</i></h3>
    <div class="data">
      <key-value-item key="Dirección" [value]="address" *ngIf="address"></key-value-item>
      <key-value-item key="Latitud" [value]="latitude" *ngIf="latitude"></key-value-item>
      <key-value-item key="Longitud" [value]="longitude" *ngIf="longitude"></key-value-item>
      <key-value-item key="Barrio" [value]="neighborhood" *ngIf="neighborhood"></key-value-item>
      <key-value-item key="Barrio" [value]="neighborhood" *ngIf="neighborhood"></key-value-item>
      <key-value-item key="Tipo de vía" [value]="retilap" *ngIf="retilap"></key-value-item>
      <key-value-item key="Tecnología" [value]="technology" *ngIf="technology"></key-value-item>
      <key-value-item key="Potencia" [value]="power" *ngIf="power"></key-value-item>
      <key-value-item key="Fabricante" [value]="maker" *ngIf="maker"></key-value-item>
      <key-value-item key="Modelo" [value]="model" *ngIf="model"></key-value-item>
      <key-value-item key="Tipo" [value]="type" *ngIf="type"></key-value-item>
      <key-value-item key="Especial" [value]="special" *ngIf="special"></key-value-item>
      <key-value-item key="Anomalía" [value]="anomaly" *ngIf="anomaly"></key-value-item>
      <key-value-item key="Encendido" [value]="switchedon" *ngIf="switchedon"></key-value-item>
      <key-value-item key="Red eléctrica" [value]="electricNetwork" *ngIf="electricNetwork"></key-value-item>
      <key-value-item key="Tipo de poste" [value]="postType" *ngIf="postType"></key-value-item>
      <key-value-item key="Clase de poste" [value]="postClass" *ngIf="postClass"></key-value-item>
      <key-value-item key="Uso particular" [value]="particularUse ? 'Sí' : 'No'" *ngIf="particularUse"></key-value-item>
    </div>
  </div>

</div>
