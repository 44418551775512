import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStore } from '../../stores/session.store';
import { ChangePhotoDialogComponent } from '../../basic/change-photo-dialog/change-photo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiManagerService } from '../../services/api-manager.service';
import { FingerprintService } from '../../services/fingerprint.service';
import { NativeLayerService } from '@ildes/services/native-layer.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  loading;
  user;
  id;
  selectedUser;
  fingerprintEnabled;
  availableBiometry = false;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private apiManager: ApiManagerService,
    private fingerprint: FingerprintService,
    public nativeLayer: NativeLayerService,
  ) {
    
  }

  ngOnInit(): void {
    const sessionStore = SessionStore.getInstance();

    this.user = sessionStore.get().user;
    this.route.queryParams.subscribe(params => {
      this.id = '';
      if (!params?.id) {
        this.selectedUser = sessionStore.get().user;
      } else {
        this.id = params.id;
        this.loading = true;

        this.apiManager.getUserDetail(this.id).subscribe((user) => {
          this.selectedUser = user;
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      }
    });
    if (!this.nativeLayer.isNative) {
      return;
    }
    this.fingerprint.isCompatible().then((result) => {
      this.availableBiometry = result;

      this.fingerprint.isEnrolled().then((result) => {
        this.fingerprintEnabled = result;
      });
    });
  }

  gotoEditProfile() {
    this.router.navigate(['app/user/edit-profile'], { queryParams: { id: this.selectedUser.id } });
  }

  gotoEditContactInformation() {
    this.router.navigate(['app/user/edit-contact-info'], { queryParams: { id: this.selectedUser.id } });
  }

  gotoEditDocumentation() {
    this.router.navigate(['app/user/edit-documentation'], { queryParams: { id: this.selectedUser.id } });
  }

  changePhoto() {
    const dialog = this.dialog.open(ChangePhotoDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { id: this.selectedUser.id}
    });

    dialog.afterClosed().subscribe((data = { closed: false }) => {
      if (data.closed) {
        return;
      }
      window.location.reload();
    })
  }

  async enrollFingerprint() {
    await this.fingerprint.enroll();

    this.fingerprintEnabled = true;
    
  }

  async disableFingerprint() {
    await this.fingerprint.disable();

    this.fingerprintEnabled = false;
  }
}
