import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { SessionStore } from '../../stores/session.store';

const DELETE_ACTION = {
  id: 'delete',
  icon: 'cancel',
}
@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.css']
})
export class MaterialComponent implements OnInit {

  loading;
  materials;
  pagination;
  user;
  actions = [DELETE_ACTION]

  constructor(
    private apiManager: ApiManagerService
  ) { }

  ngOnInit(): void {
    const sessionStore = SessionStore.getInstance();

    this.loading = true;
    this.user = sessionStore.get().user;
    this.apiManager.listMaterials().subscribe((materialData: any) => {
      this.materials = materialData.data;
      this.loading = false;
      this.pagination = materialData.pagination;
    });
  }

  paginate(event) {
    this.loading = true;
    this.apiManager.listMaterials({ page: event.pageIndex + 1 }).subscribe((materialData: any) => {
      this.loading = false;
      this.materials = materialData.data;
      this.pagination = materialData.pagination;
    }, (data) => {
      this.loading = false;
    });
  }

  onClickAction(action, material) {
    switch (action.id) {
      case DELETE_ACTION.id:
        this.loading = true;
        this.apiManager.deleteMaterial(material.id).subscribe(() => {
          this.materials = this.materials.filter(({ id }) => id !== material.id);
          this.loading = false;
        }, () => {
          this.loading = false;
        })
        break;
    
      default:
        break;
    }

  }
}
