import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterActionsMaintenance'
})
export class FilterActionsMaintenancePipe implements PipeTransform {

  private pendingSync(pendingSync) {
    return Object.keys(pendingSync || { }).find((key) => {
      return pendingSync[key];
    })
  }

  transform(actions: any[], maintenance: any): unknown {
    // maintenance.state !== 'CLOSED' ? actions : actionsClosed;

    return actions.filter((action) => {
      if (!action.conditions?.length) {
        return true;
      }
      return action.conditions.filter(({ key, value, equal }) => {
        if (equal && key === 'pendingSync' && value) {
          return this.pendingSync(maintenance.pendingSync);
        }
        return equal ? maintenance[key] === value: maintenance[key] !== value
      })?.length === action.conditions.length;
    });
  }

}
