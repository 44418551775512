<simple-header title="Error al cargar las luminarias" (action)="close()" icon="close"></simple-header>
<div class="align-center padding-bottom-16">
  <mat-icon class="icon">
    {{data.errors?.length > 0 ? 'error_outline' : 'check_circle_outline'}}
  </mat-icon>
  <div *ngIf="data.errors?.length > 0">
    No se pudo iniciar el proceso de volcado por errores en la validación de los datos.
  </div>
  <div *ngIf="data.errors?.length <= 0">
    Se han insertado {{error.totalDocsInserted}} de {{error.totalDocsInserted + error.errors?.length}}
  </div>
</div>
<div *ngIf="data.errors?.length > 0">
  <h3>Existen errores en los datos de las siguientes filas del documento</h3>
  <ul>
    <li *ngFor="let currentError of error.errors">
      Fila {{currentError.index}}: {{currentError.causeError}}
    </li>
  </ul>
</div>
<h3 *ngIf="data.errors?.length > 0">
  Corrige el documento y vuelve a intentarlo
</h3>
<div class="align-center">
  <button (click)="close()" color="primary" mat-raised-button>
    Aceptar
  </button>
</div>
