<connection-alert></connection-alert>
<div class="container">
  <div class="scrollable-content">
    <simple-header title="Devolver materiales" (click)="closeDialog()" icon="close"></simple-header>
    <mat-vertical-stepper [linear]="true" #stepper>
        <mat-step [completed]="selectedStore" >
            <ng-template matStepLabel>Seleccione el almacén</ng-template>
            <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loadingStores"></mat-progress-bar>
            <mat-form-field class="fill" appearance="outline">
                <mat-label>Almacén</mat-label>
                <mat-select (selectionChange)="onStoreChange($event)" [(value)]="selectedStore">
                    <mat-option *ngFor="let store of stores" [value]="store">
                    {{store.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="align-center padding-top-8">
                <button [disabled]="!selectedStore" mat-raised-button color="primary" (click)="stepper.next()" class="button-size-200">Continuar</button>
            </div>
        </mat-step>
        <mat-step [completed]="selectedMaterials.length>0" >
            <ng-template matStepLabel>Seleccione el material que desea devolver</ng-template>
            <div *ngFor="let item of availableMaterials">
                <div class="material">
                <div class="fill">
                    {{item.reference.name}}
                </div>
                <input-material-value
                    [min]="0"
                    [max]="item.max"
                    class="vertical-center"
                    [material]="item"
                    [(value)]="item.amount"></input-material-value>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="align-center padding-top-8">
                <button [disabled]="computedSelectedMaterials === 0" mat-raised-button color="primary" (click)="continue()" class="button-size-200">Continuar</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Resumen del material a devolver</ng-template>
            <div class="padding-bottom-8 padding-top-8">
                Almacén: <i>{{selectedStore?.name}}</i>
            </div>
            <mat-divider></mat-divider>
            <div class="padding-bottom-8 padding-top-8">
                Dirección: <i>{{selectedStore?.address}}</i>
            </div>
            <mat-divider></mat-divider>
            <div *ngFor="let material of selectedMaterials" class="item">
                <list-material-item
                  [name]="material.reference.name"
                  [unit]="material.reference.unit"
                  [amount]="material.amount || material.count"
                  class="padding-top-8">
                </list-material-item>
                <mat-divider></mat-divider>
            </div>
            <div class="align-center padding-top-8">
                <button mat-raised-button color="primary" (click)="submit()" class="button-size-200">Aceptar</button>
            </div>
        </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
