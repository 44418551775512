import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiManagerService } from '@ildes/services/api-manager.service';

@Component({
    selector: 'app-store-items',
    template: `
    <div class="container">
        <div class="scrollable-content">
        <simple-header title="Estado de almacén" [back]="true" icon="close"></simple-header>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <app-image-panel *ngIf="!loading && !items?.length" emoji="true" text="No hay nada en el almacén"></app-image-panel>
        
        <div *ngFor="let item of items" class="item" role="list">
            <div role="listitem" class="item">
                <div class="flex-center">
                    <div matRipple role="button"  class="actionable fill-width">
                        {{item.item}}
                    </div>
                    <div>
                        {{item.amount}}
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
        <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/store-items/add-items']" [queryParams]="{storeId:this.storeId}">+</button>
    </div>`,
    styleUrls: ['./store-items.component.css'],
})
export class StoreItemsComponent implements OnInit {
    loading = false;
    items = [];
    storeId = '65635cbac8da695f485e7bac' // TODO: get from query params
    constructor(
        private apiManager: ApiManagerService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.route.queryParams.subscribe(params => {
            this.storeId = params.id;
            this.apiManager.getStoreDetail(this.storeId).subscribe(({ data }: any) => {
                this.loading = false;

                this.items = data;
            }, () => {
                this.loading = false;
            })
        });
    }
}
