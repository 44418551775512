import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const CLOSE = { id:'close', icon:'close' };

@Component({
  selector: 'app-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.css']
})
export class GalleryDialogComponent implements OnInit {

  headerActions = [CLOSE];
  photos = [];
  readOnly = false;

  constructor(
    public dialogRef: MatDialogRef<GalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.photos = data?.photos || [];
    this.readOnly = data?.readOnly;
  }

  ngOnInit(): void {
  }

  close(commit = false) {
    this.dialogRef.close({
      photos: this.photos,
      commit: commit && !this.readOnly
    });
  }

  confirm() {
    this.close(true);
  }

  headerAction() {
    this.dialogRef.close();
  }


}
