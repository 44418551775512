<app-loading *ngIf="pendingRequest"></app-loading>
<div class="container" *ngIf="!pendingRequest">
  <div class="scrollable-content">
    <simple-header title="Buzón" [back]="true" icon="close"></simple-header>
    <app-alert-dialog
      (click)="enrollFingerprint()"
      *ngIf="!loading && (!availableBiometry || !fingerprintEnabled)"
      [text]="fingerprintAlertText"
      [linkText]="availableBiometry && !fingerprintEnabled ? 'Activar huella': undefined"
      status="advise"></app-alert-dialog>
    <div class="item" *ngIf="tasks?.length">
      <div *ngFor="let task of tasks">
        <app-list-step-state-item
          [check]="task.state === 'COMPLETED'"
          [disabled]="task.state === 'COMPLETED'"
          (click)="openTask(task)"
          [title]="task.title"
          [description]="task.description">
        </app-list-step-state-item>
        <mat-divider></mat-divider>
      </div>
    </div>
    <app-image-panel *ngIf="!tasks?.length && !pendingRequest"></app-image-panel>
  </div>
</div>
  