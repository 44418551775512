import { Store } from './store';
import { Session } from './models/session';

export class SessionStore extends Store<Session> {

    private static instance: SessionStore;
    private token;
    private static methodStorage: any;

    private constructor(){
        super();

        this.token = sessionStorage.getItem('token');
        let user = sessionStorage.getItem('user');

        if (user) {
            this.store(new Session(JSON.parse(user)));
            SessionStore.methodStorage = sessionStorage;

            return;
        }
        user = localStorage.getItem('user');
        this.token = localStorage.getItem('token');

        if (user) {
            this.store(new Session(JSON.parse(user)));
            SessionStore.methodStorage = localStorage;
        }
    }

    public static getInstance(): SessionStore {
        if (!SessionStore.instance) {
          SessionStore.instance = new SessionStore();
        }

        return SessionStore.instance;
    }

    public static create(user, token, remember) {
      SessionStore.methodStorage = sessionStorage;

      if (remember) {
        SessionStore.methodStorage = localStorage;
      }

      SessionStore.methodStorage.setItem('token', token);
      SessionStore.methodStorage.setItem('user', JSON.stringify({...user, lastUpdate: new Date().getTime()}));

      const instance = SessionStore.getInstance();

      instance.token = token;
      instance.store(new Session(user));
    }

    public static invalidate() {
      SessionStore.methodStorage.removeItem('token');
      SessionStore.methodStorage.removeItem('user');
      SessionStore.getInstance().store(null);
    }

    public static updateUserInfo(userData, timestamp = false) {
      const instance = SessionStore.getInstance();
      const currentUser = instance.get().user;
      const newUser = {
        ...currentUser,
        ...userData,
        ...timestamp && { lastUpdate: new Date().getTime() }
      };

      SessionStore.methodStorage.setItem('user', JSON.stringify(newUser));
      instance.store(new Session(newUser));
    }
}
