<mat-card class="banner">
  <div class="padding-left-16 padding-right-16">
    <img src="./assets/images/logo.png" class="logo">
    <div *ngIf="!user">
      <p> Accede a la plataforma de gestión del alumbrado</p>
      <mat-divider></mat-divider>
      <form [formGroup]="credentials" class="padding-top-8 padding-bottom-8">
        <div>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Ex. pat@example.com" formControlName="email">
            <mat-error>
                El email es obligatorio y debe ser un email válido
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline"  class="full-width">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-error>
                La contraseña es obligatoria
            </mat-error>
          </mat-form-field>
        </div>
        <div class="align-center">
          <div class="padding-bottom-8">
            <button (click)="onSubmitCredentials()" [disabled]="credentials.invalid || loading" mat-raised-button color="primary" class="button-size-200">Entrar</button>
          </div>
          <div>
            <!-- <button mat-button>He olvidado la contraseña</button> -->
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </form>
      <div class="buttons">
        <button [routerLink]="['/remember-password']" mat-button>¿Has olvidado tu contraseña?</button>
        <button [routerLink]="['/privacy-policy']" mat-button>Política de privacidad</button>
      </div>      
    </div>
    <div *ngIf="user">
      <mat-divider></mat-divider>
      <div class="padding-bottom-32 padding-top-32">
        <profile-avatar size="big" [image]="user.photo | imagePath" [name]="user.name" [surname]="user.surname"></profile-avatar>
      </div>
      <p>
        Bienvenido, {{user.name}}
      </p>
      <div class="margin-top-32 margin-bottom-8">
        <button (click)="go()" mat-raised-button color="primary" class="button-size-200">Entrar</button>
      </div>
    </div>
  </div>
</mat-card>
<div style="position:relative; top: 12%; text-align: center; color: green" *ngIf="!this.native.isNative">
  <div>
    <mat-icon class="icon-button">android</mat-icon>
  </div>
  <a mat-button href="/assets/app-release.apk" download>
    Descarga la app en tu dispositivo Android
  </a>
</div>
<div style="
    position: absolute;
    color: #71747a57;
    top: calc(100% - 30px);
    z-index: 100;
    left: 12px;
    "
>{{releaseDate}}</div>