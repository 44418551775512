export class Session {
  token;
  user;

  constructor(user, token = null) {
    this.user = user;
    if (!token) {
      return;
    }
    this.token = token;
  }

  public hasPermission(action){
    const { family, actionName } = action;

    return this.user.permissions[family] && this.user.permissions[family][actionName];
  }
}
