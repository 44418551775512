import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';

@Component({
    selector: 'app-create-store',
    template: `
         <div class="container">
            <div class="scrollable-content">
                <simple-header title="Crear almacén" [back]="true" icon="close"></simple-header>
                <form [formGroup]="storeForm" class="padding-top-8 padding-bottom-8">
                    <div class="content horizintal-padding-8">
                        <mat-form-field appearance="outline" class="adjust-width">
                            <mat-label>Nombre</mat-label>
                            <input required matInput formControlName="name" maxlength="50">
                            <mat-error>
                                El nombre es obligatorio
                            </mat-error>
                        </mat-form-field>
                        <predictive-searcher
                            formControlName="selectedProjectForm"
                            label="Proyecto"
                            [service]="searchProjectAdapter"
                            class="column margin-right-column-8"></predictive-searcher>
                    </div>
                    <div class="horizintal-padding-8">
                        <input-place
                            label="Ubicación"
                            placeholder="CLL 33 45"
                            dark
                            [requiredGeoposition]="true"
                            formControlName="location"
                            appearance="fill"></input-place>
                    </div>
                    <div class="align-center">
                        <div class="padding-bottom-8">
                            <button (click)="save()" [disabled]="storeForm.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
                        </div>
                        <div>
                            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>
                </form>
            </div>
        </div>
  `,
    styleUrls: ['./create-store.component.css'],
})
export class CreateStoreComponent implements OnInit {

    loading;
    searchProjectAdapter;

    storeForm = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        selectedProjectForm: new UntypedFormControl(),
        location: new UntypedFormControl(''),
    });

    constructor(
        private apiManager: ApiManagerService,
        private location: Location
    ) {

    }

    get storeName() {
        return this.storeForm.get('name')?.value
    }

    get ubication() {
        return this.storeForm.get('location')?.value
    }

    get address() {
        return this.ubication?.typed;
      }

    get latitude() {
        return parseFloat(this.ubication?.lat);
    }

    get longitude() {
        return parseFloat(this.ubication?.lng);
    }

    ngOnInit(): void {
        this.searchProjectAdapter = (data) => {
            return this.apiManager.listProjects(data);
        };
    }

    get selectedProject() {
        return this.storeForm.get('selectedProjectForm')?.value?.selected;
    }

    save() {
        const store = {
            name: this.storeName,
            project: this.selectedProject,
            address: this.address,
            geolocation: {
                type: 'Point',
                coordinates: [this.longitude, this.latitude]
            },
        }
        this.loading = true;
        this.apiManager.createStore(store).subscribe(() => {
            this.loading = false;
            this.location.back();
        }, () => {
            this.loading = false;
        })
    }
}
