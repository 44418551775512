<app-loading *ngIf="loadingData"></app-loading>
<div *ngIf="!loadingData">
  <simple-header title="Editar proyecto" class="background padding-8" [back]="true" icon="close"></simple-header>
  <form [formGroup]="projectForm">
    <div class="section">
      <h3>Nombre</h3>
      <mat-form-field appearance="outline" class="full-width background">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" maxlength="100">
        <mat-error>
            El nombre es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="section margin-top-8">
      <h3>Datos de dirección</h3>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Calle, Carrera...</mat-label>
        <input matInput formControlName="address">
      </mat-form-field>
      <div class="inline-flex">
        <predictive-searcher
          class="column margin-right-8"
          formControlName="department"
          label="Departamento"
          mandatorySelection="true"
          (selectedValue)="changeDepartment($event)"
          [service]="searchDepartmentAdapter"></predictive-searcher>
        <predictive-searcher
          class="column"
          mandatorySelection="true"
          [disabled]="!this.projectForm?.controls?.department?.value.selected?.name"
          formControlName="city"
          label="Municipio"
          [service]="searchCityAdapter"></predictive-searcher>
      </div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Objetivo/Alcance</mat-label>
        <textarea matInput formControlName="object" maxlength="500"></textarea>
        <mat-error>
            El objetivo es obligatorio
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Tipo de contrato</mat-label>
        <input matInput formControlName="contractType" maxlength="50">
        <mat-error>
            El tipo de contrato es obligatorio
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Número de contrato</mat-label>
        <input matInput formControlName="contractNumber" maxlength="50">
        <mat-error>
            El número de contrato es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="section margin-top-8">
      <h3>Partes del contrato*</h3>
      <div  *ngFor="let currentPart of dinamicParts; index as i">
        <mat-form-field class="margin-right-8" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input [disabled]="dinamicParts.length > 1 && i !== dinamicParts.length - 1" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="currentPart.name"  maxlength="200">
          <mat-error>
              Las partes del contrato son obligatorias
          </mat-error>
        </mat-form-field>
        <mat-form-field class="margin-right-8" appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select [disabled]="dinamicParts.length > 1 && i !== dinamicParts.length - 1" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentPart.type">
            <mat-option *ngFor="let option of contractParts" [value]="option.id">
              {{option.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="percent-input">
          <mat-label>Porcentaje</mat-label>
          <input type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentPart.percent" matInput>
        </mat-form-field>
        <button (click)="removePart(i)" [disabled]="dinamicParts?.length === 1" mat-icon-button aria-label="eliminar">
          <mat-icon>highlight_off</mat-icon>
        </button>
      </div>
      <div class="flex-row">
        <div class="right">
          <button [disabled]="disabledAddPart" (click)="addPart()" mat-button color="primary" class="margin-top-16">Añadir</button>
        </div>
      </div>
      <mat-error *ngIf="erorInParts">
        Debe añadir al menos una parte del contrato y sumar 100%
      </mat-error>
    </div>   
    <div class="section margin-top-8">
      <h3>Fecha de suscripción</h3>
      <div>
        <date-selector [date]="subscriptionDate" minYear=2000 maxYear=2080 (onChangeDate)="setSubscriptionDate($event)" ></date-selector>
      </div>
      <div class="margin-bottom-8">
        <mat-slide-toggle [checked]="minutesDate" (change)="toggleMinutesDate()">Introducir fecha de acta</mat-slide-toggle>
      </div>
    </div>
    <div class="section margin-top-8">
      <h3 *ngIf="minutesDate">Fecha de acta</h3>
      <div *ngIf="minutesDate">
        <date-selector [date]="minutesDate" minYear=2000 maxYear=2080 (onChangeDate)="setMinutesDate($event)"></date-selector>
      </div>
    </div>
    <div class="section margin-top-8">
      <h3>Plazo</h3>
      <mat-form-field appearance="outline" class="margin-right-8">
        <mat-label>Años</mat-label>
        <input matInput formControlName="years" maxlength="2">
        <mat-error>
          Debe ser un número válido
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Meses</mat-label>
        <input matInput formControlName="months" maxlength="2">
        <mat-error>
          Debe ser un número válido
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="projectForm.hasError('validDuration')">
          la duración debe ser mayor que 0
      </mat-error>
    </div>
    <div class="section margin-top-8 padding-bottom-8">
      <h3>Fecha estimada de finalización</h3>
      <p *ngIf="!formattedEndDate"><i>Se necesita informar la duración</i></p>
      <p *ngIf="formattedEndDate"><i>{{formattedEndDate}} *</i></p>
      <p *ngIf="formattedEndDate && minutesDate"><i>* Calculada sobre la fecha de acta</i></p>
      <p *ngIf="formattedEndDate && !minutesDate"><i>* Calculada sobre la fecha de subscripción</i></p>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Valor del contrato</mat-label>
        <input matInput formControlName="value" maxlength="20">
        <mat-icon matSuffix>attach_money</mat-icon>
        <mat-error>
            El valor del contrato es obligatorio y debe ser numérico
        </mat-error>
      </mat-form-field>
      <div class="align-center">
        <div class="padding-bottom-8">
          <button (click)="save()" [disabled]="projectForm.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
        </div>
        <div>
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
  </form>
</div>
  