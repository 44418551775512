import { Action, createReducer, State, on  } from '@ngrx/store';
import * as actions from './assigned-maintenances.actions';
import * as actionsDailyQuestionaries from './daily-questionaries.actions';
import { ActionReducer } from '@ngrx/store';

const initState = (state, action) => {
  return action.maintenances;
}

const saveQuestionary = (state, action) => {
  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId){
      const pendingSync = {
        ...maintenance.pendingSync,
        questionary: action.pendingSync
      };

      return {
        ...maintenance,
        ...{
          state: 'IN_PROGRESS',
          questionary: action.answers,
          openDate: action.openDate,
          updatedAt: new Date().toISOString(),
          pendingSync
        }
      }
    } else {
      return maintenance;
    }
  });
}

const updateTask = (state, action) => {
  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId){
      return {
        ...maintenance,
        ...{ task: action.task },
      }
    } else {
      return maintenance;
    }
  });
}

const identifyLuminary = (state, action) => {
  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId){
      const pendingSync = {
        ...maintenance.pendingSync,
        luminary: action.pendingSync
      };
      return {
        ...maintenance,
        ...{
          luminary: { number: action.luminaryNumber },
          height: action.height,
          ascendMethod: { ...action.ascendMethod },
          updatedAt: new Date().toISOString(),
          pendingSync
        }
      };
    } else {
      return maintenance;
    }
  });
}

const setPhotos = (state, action, stateMaintenance) => {
  const photos = {
    [stateMaintenance]: action.photos
  }
  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId) {
      const pendingSync = {
        ...maintenance.pendingSync,
        [`photos${stateMaintenance}`]: action.pendingSync
      };

      return {
        ...maintenance,
        ...{
          updatedAt: new Date().toISOString(),
          photos: {
            ...maintenance.photos, ...photos
          },
          pendingSync
        }
      };
    } else {
      return maintenance;
    }
  });
}

const setPhotosInitialState = (state, action) => {
  return setPhotos(state, action, 'initial');
};

const setPhotosFinalState = (state, action) => {
  return setPhotos(state, action, 'final');
};

const setRepairmentData = (state, action) => {
  const usedMaterials = action.usedMaterials ? [...action.usedMaterials]: undefined;
  const extractedMaterials = action.extractedMaterials ? [...action.extractedMaterials]: undefined;
  const description = action.description;

  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId) {
      const pendingSync = {
        ...maintenance.pendingSync,
        repairmentData: action.pendingSync
      };

      return {
        ...maintenance,
        ...{
          updatedAt: new Date().toISOString(),
          extractedMaterials,
          usedMaterials,
          description,
          localUsedMaterials: action.localUsedMaterials,
          pendingSync
        }
      };
    } else {
      return maintenance;
    }
  });
}

const changeState = (state, action) => {
  const maintenanceState = action.state;
  const repairmentDate = action.repairmentDate;

  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId) {
      const pendingSync = {
        ...maintenance.pendingSync,
        state: action.pendingSync
      };

      return {
        ...maintenance,
        ...repairmentDate && { repairmentDate: new Date().toISOString() },
        ...{
          updatedAt: new Date().toISOString(),
          state: maintenanceState,
          pendingSync
        }
      };
    } else {
      return maintenance;
    }
  });
}

const sync = (state, action) => {
  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId) {
      const newMaintenance = { ...maintenance };

      delete newMaintenance.localUsedMaterials;
      delete newMaintenance.pendingSync;
      return newMaintenance;
    } else {
      return maintenance;
    }
  });
}

const loadedMaintenances = (state, action) => {
  return action.maintenances;
}

const normalizePhotos = (state, action) => {
  return state.map((maintenance) => {
    if (maintenance.id === action.maintenance.id) {
      return { ...maintenance, ...{ pendingNormalising : false } };
    } else {
      return maintenance;
    }
  });
}
const addParticipants = (state, action) => {
  return state.map((maintenance) => {
    if (maintenance.id === action.maintenanceId) {
      return { ...maintenance, ...{ participants : action.participants } };
    } else {
      return maintenance;
    }
  });
}
const initialState = [];
const assignedMaintenancesReducer = createReducer(
  initialState,
  on(actions.initState, initState),
  on(actions.saveQuestionary, saveQuestionary),
  on(actions.identifyLuminary, identifyLuminary),
  on(actions.setPhotosInitialState, setPhotosInitialState),
  on(actions.setPhotosFinalState, setPhotosFinalState),
  on(actions.setRepairmentData, setRepairmentData),
  on(actions.changeState, changeState),
  on(actions.loadedMaintenances, loadedMaintenances),
  on(actions.sync, sync),
  on(actions.addParticipants, addParticipants),
  on(actions.normalizePhotos, normalizePhotos),
  on(actions.updateTask, updateTask)
);

export function reducer(state: any[] | undefined, action: Action) {
  return assignedMaintenancesReducer(state, action);
}
