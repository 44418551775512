import { Component, OnInit, Inject } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-advanced-search-luminary-dialog',
  templateUrl: './advanced-search-luminary-dialog.component.html',
  styleUrls: ['./advanced-search-luminary-dialog.component.css']
})
export class AdvancedSearchLuminaryDialogComponent implements OnInit {

  withoutNumber;
  searchNeighborhoodAdapter;
  searchProjectAdapter;
  searchFormGroup = new UntypedFormGroup({
    selectedProjectForm: new UntypedFormControl(),
    location: new UntypedFormControl(),
    number: new UntypedFormControl(),
    neighborhoodForm: new UntypedFormControl(),
  });
  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchLuminaryDialogComponent>,
    private apiManager: ApiManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.searchNeighborhoodAdapter = (neighborhood) => {
      return this.apiManager.getFromCatalog('neighborhood', neighborhood)
    };
    this.searchProjectAdapter = (projects) => {
      return this.apiManager.listProjects(projects);
    };

    if (!data.filter) {
      return;
    }

    this.setForm(data);
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close({ closed: true });
  }

  private setForm(data) {
    const { filter } = data;

    if (filter.project) {
      this.searchFormGroup.get('selectedProjectForm').setValue(filter.project);
    }
    if (filter.neighborhood) {
      this.searchFormGroup.get('neighborhoodForm').setValue(filter.neighborhood);
    }
    if (filter.number) {
      this.searchFormGroup.get('number').setValue(filter.number);
    }
    if (filter.location) {
      this.searchFormGroup.get('location').setValue(filter.location);
    }
    this.withoutNumber = filter.withoutNumber;
  }

  filter() {
    const filter = {
      project: this.selectedProject?.selected && this.selectedProject,
      neighborhood: this.neighborhood?.selected && this.neighborhood,
      number: this.withoutNumber ? undefined: this.number,
      withoutNumber: this.withoutNumber,
      location: this.location,
    };

    Object.keys(filter).forEach(key => filter[key] === undefined && delete filter[key]);

    this.dialogRef.close(filter);
  }

  get selectedProject() {
    return this.searchFormGroup.get('selectedProjectForm')?.value;
  }

  get technology() {
    return this.searchFormGroup.get('technologyForm')?.value;
  }

  get neighborhood() {
    return this.searchFormGroup.get('neighborhoodForm')?.value;
  }

  get number() {
    return this.searchFormGroup.get('number')?.value;
  }

  get location() {
    return this.searchFormGroup.get('location')?.value;
  }

}
