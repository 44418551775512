<div class="padding-8 container">
  <simple-header title="Cambiar contraseña" [back]="true" icon="close"></simple-header>
  <form [formGroup]="password" class="padding-top-8 padding-bottom-8">
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nueva contraseña</mat-label>
        <input matInput formControlName="password" minlength="6" type="password">
        <mat-error *ngIf="password.get('password').hasError('mismatch')">
            Las contraseñas no coinciden
        </mat-error>
        <mat-error *ngIf="password.get('password').hasError('required')">
            La contraseña es obligatoria
        </mat-error>
        <mat-error *ngIf="password.get('password').hasError('minlength')">
            La contraseña tiene que tener mínimo 6 caracteres
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Confirmar contraseña</mat-label>
        <input matInput formControlName="confirmPassword" type="password">
      </mat-form-field>
      <mat-error *ngIf="password.hasError('mismatch')">
          Las contraseñas no coinciden
      </mat-error>
    </div>
    <div class="align-center">
      <div class="padding-bottom-8">
        <button (click)="save()" [disabled]="password.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
      </div>
      <div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </form>
</div>
