<number-increment
  *ngIf="unit?.id === 'UNITS' && !forceText"
  (remove)="doRemove()"
  [min]="min"
  [max]="max"
  [showRemoveOption]="showRemoveOption"
  [(value)]="material.amount"
  [unit]="material.unit"
></number-increment>
<div *ngIf="unit?.id !== 'UNITS' || forceText">
  <div
    class="form-wrapper">
    <form [formGroup]="valueFormUnit" class="margin-top-16">
      <div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Cantidad</mat-label>
          <input type="number" matInput formControlName="value" [min]="min" [max]="max">
          <mat-error>
              Campo obligatorio
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <button (click)="doRemove()" mat-icon-button aria-label="eliminar" *ngIf="showRemoveOption">
      <mat-icon>highlight_off</mat-icon>
    </button>
  </div>
</div>
