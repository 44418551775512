import { Component, OnInit, Inject, ViewChild, ElementRef  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SessionStore } from '@ildes/stores/session.store';
import { Store } from '@ngrx/store';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
const CLOSE = { id:'close', icon:'close' };

@Component({
  selector: 'app-use-material-dialog',
  templateUrl: './use-material-dialog.component.html',
  styleUrls: ['./use-material-dialog.component.css']
})
export class UseMaterialDialogComponent implements OnInit {

  maintenance;
  headerActions = [CLOSE];
  availableMaterials = [];
  usedMaterials = [];
  validSteps = [];
  extractedMaterials = [];
  description = '';
  presetMaterials = false;
  loading = false;
  pendingSyncUsedMaterials = false;
  materialSeacherForm = new UntypedFormGroup({
    materialName: new UntypedFormControl(),
  });
  searchMaterialAdapter;
  @ViewChild('stepper') stepper: any;
  formDescription = new UntypedFormGroup({
    description: new UntypedFormControl('', [Validators.required])
  });
  constructor(
    private store: Store,
    private apiManager: ApiManagerService,
    public dialogRef: MatDialogRef<UseMaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.maintenance = data.maintenance;
    this.availableMaterials = (data.availableMaterials || []).map((material) => (
      {...material}
    ));
    const currentUsedMaterials = data.usedMaterials;
    this.description = data.description;
    this.extractedMaterials = data.extractedMaterials || [];
    this.presetMaterials = !!currentUsedMaterials;
    this.usedMaterials = this.availableMaterials.map((availableMaterial) => {
      const currentUsedMaterial = (currentUsedMaterials || [])
        .find((usedMaterial) => usedMaterial.reference?.id === availableMaterial.reference.id);

      return {
        amount: currentUsedMaterial?.amount || 0,
        reference: availableMaterial.reference,
        max: availableMaterial.amount + (currentUsedMaterial?.amount || 0)
      };
    });

    (currentUsedMaterials || []).forEach((usedMaterial) => {
      const usedInAvailableMaterials = this.usedMaterials
        .find((currentUsedMaterial) => currentUsedMaterial.reference?.id === usedMaterial.reference.id);

      if (!usedInAvailableMaterials) {
        this.usedMaterials.push({
          amount: usedMaterial.amount,
          reference: usedMaterial.reference,
          max: usedMaterial.amount
        });
      }
    });
  }

  ngOnInit(): void {
    this.searchMaterialAdapter = () => {
      return this.apiManager.listMaterials();
    };
  }

  close(commit = false) {
    this.dialogRef.close({
      usedMaterials: this.cleanListUsedMaterial,
      extractedMaterials: this.cleanListRetiredMaterial,
      description: this.formDescription.controls?.description?.value,
      commit
    });
  }

  private performRetiredMaterial() {
    return this.cleanListUsedMaterial.filter((material) =>
      material.amount > 0
    ).map((material) => {
      return {
        amount: material.amount,
        reference: material.reference,
        max: material.amount
      };
    });
  }

  get causeOfNotextractedMaterials() {
    if (!this.extractedMaterials?.length) {
      return true;
    } else {
      return this.extractedMaterials.find((retiredMaterial) => retiredMaterial.amount === 0);
    }
  }

  headerAction() {
    this.close(false);
  }

  setUsedMaterials() {
    //this.extractedMaterials = this.performRetiredMaterial();
    this.stepper.selected.completed = true;
    this.stepper.next()
  }

  continue() {
     this.stepper.selected.completed = true;
     this.stepper.next();
  }

  confirm() {
    this.close(true);
  }

  resetMaterial() {
    // if (this.maintenance.localUsedMaterials) { 
    //   this.updateMaintenance();
    //   this.presetMaterials = false;
    //   return;
    // }
    this.loading = true;
    this.apiManager.undoMaterials(this.maintenance.id)
      .subscribe(() => {
        this.presetMaterials = false;
        this.loading = false;
        this.updateUser();
        this.updateMaintenance();
      }, () => {
        this.loading = false;
      })
  }

  updateMaintenance() {
    const payload = {
      maintenanceId: this.maintenance.id,
      usedMaterials: undefined,
      extractedMaterials: undefined,
      description: undefined,
      localUsedMaterials: undefined
    };

    this.store.dispatch(
      actions.setRepairmentData(payload)
    );
  }

  updateUser() {
    this.loading = true;
    const callUserDetail = this.apiManager.getUserDetail(SessionStore.getInstance().get().user.id);

    callUserDetail.subscribe((data: any) => {
      SessionStore.updateUserInfo({
        confirmedMaterials: data.confirmedMaterials,
        assignedMaterials: data.assignedMaterials
      });
      this.loading = false;
    });

    return callUserDetail;
  }

  get cleanListRetiredMaterial() {
    return this.extractedMaterials.filter(({ amount }) => amount > 0);
  }

  get cleanListUsedMaterial() {
    return this.usedMaterials.filter(({ amount }) => amount > 0);
  }

  onSelectedValue({option}) {
    if (this.extractedMaterials.find(({ reference }) => reference.name === option.value.name)) {
      return;
    }
    const material = {
      amount: 1,
      reference: option.value
    }
    
    this.extractedMaterials = [...this.extractedMaterials, material];
    this.materialSeacherForm.reset();
  }

  addUnknownMaterial() {
    const name = this.materialSeacherForm.controls?.materialName?.value?.typed;

    if (this.extractedMaterials.find(({ reference }) => reference.name === name)) {
      return;
    }
    const material = {
      amount: 1,
      reference: {
        name
      }
    };
    this.extractedMaterials = [...this.extractedMaterials, material];
    this.materialSeacherForm.reset();
  }

  get typedRetiredMaterial() {
    return this.materialSeacherForm.controls?.materialName?.value?.typed
  }
}
