import { Injectable } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';

const { CustomNativePlugin } = Plugins;

export const ANDROID = 'android';
export const HTML = 'web';

@Injectable({
  providedIn: 'root'
})
export class NativeLayerService {

  private deviceInfo: DeviceInfo;
  private deviceId: DeviceId;

  constructor() {
    Device.getInfo().then((deviceInfo) => {
      this.deviceInfo = deviceInfo;
    });
    Device.getId().then((deviceId) => {
      this.deviceId = deviceId;
    });
  }

  get uuid() {
    return this.deviceId.identifier ;
  }

  get platform() {
    return Capacitor.getPlatform();
  }

  get isNative() {
    return this.platform !== HTML;
  }

  public setTokenNative(token) {
    return CustomNativePlugin.setString({
      keyName: 'token',
      value: token,
    });
  }
}
