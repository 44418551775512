import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'list-material-item',
  templateUrl: './list-material-item.component.html',
  styleUrls: ['./list-material-item.component.css']
})
export class ListMaterialItemComponent implements OnInit {

  @Input() name;
  @Input() unitDescription;
  @Input() amount;
  @Input() actions;
  @Input() unit;
  @Output() actionEvent =  new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(action) {
    this.actionEvent.emit(action);
  }

}
