import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {

  users;
  loading;
  pagination;

  actions = [];

  constructor(
    private apiManager: ApiManagerService,
    private router: Router,
  ) { }

  onDoAction(event) {
    console.log(event.action, event.user);
  }

  onClickUser(event) {
    
  }

  ngOnInit(): void {
    // this.loading = true;
    // this.apiManager.listUsers().subscribe((users: any) => {
    //   this.loading = false;
    //   this.users = users.data;
    //   this.pagination = users.pagination;
    // }, (data) => {
    //   this.loading = false;
    // })
  }
  //
  // gotoUser(user) {
  //   this.router.navigate(['/app/user'], { queryParams: { id: user.id } });
  // }
  //
  // paginate(event) {
  //   this.apiManager.listUsers({ page: event.pageIndex + 1 }).subscribe((users: any) => {
  //     this.loading = false;
  //     this.users = users.data;
  //     this.pagination = users.pagination;
  //   }, (data) => {
  //     this.loading = false;
  //   });
  // }
}
