import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
@Component({
  selector: 'filtered-list-maintenances',
  templateUrl: './filtered-list-maintenances.component.html',
  styleUrls: ['./filtered-list-maintenances.component.css']
})
export class FilteredListMaintenancesComponent implements OnInit {

  currentPage = 0;
  loading = false;
  maintenances;
  pagination;
  isAssigned;
  @Input() assigned;
  @Input() showAssigmentFilter;
  @Input() selector;
  @Input() actions;
  @Input() filter: {};
  @Input() state;
  @Input() omitDefaultEventBehaviour;
  @Output() doAction =  new EventEmitter();
  @Output() changeSelected =  new EventEmitter();

  constructor(
    private apiManager: ApiManagerService,
  ) { }

  ngOnInit(): void {
    this.list();
  }

  getFilter() {
    const filter: any = {};

    if (this.isAssigned !== undefined) {
        filter.isAssigned = this.isAssigned;
    }
    if (this.assigned !== undefined) {
        filter.assigned = this.assigned;
    }
    if (this.state !== undefined) {
      filter.state = this.state;
    }
    return filter;
  }

  list(pageIndex = 0) {
    this.currentPage = pageIndex;
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    const filter: any = this.getFilter();

    this.loading = true;
    this.apiManager.listMaintenances({...page, ...filter}).subscribe((maintenances: any) => {
      this.loading = false;
      this.maintenances = maintenances.data;
      this.pagination = maintenances.pagination;
    }, (data) => {
      this.loading = false;
    });
  }

  doIt(maintenance, action) {
    this.doAction.emit({ maintenance, action });
  }

  changeSelection( maintenance, { checked } ) {
    this.changeSelected.emit({ maintenance, checked });
  }

  onChangeAssigned(event) {
    this.isAssigned = event.value;

    this.list();
  }

  paginate(event) {
    this.list(event.pageIndex);
  }

}
