<div *ngIf="!emoji" class="box-center">
    <mat-icon class="resized">{{icon}}</mat-icon>
</div>
<div *ngIf="emoji" class="emoji">
    ಠ_ಠ
</div>
<!-- <div class="box-center"><mat-icon class="resized">self_improvement</mat-icon></div>
<div class="box-center"><mat-icon class="resized">surfing</mat-icon></div> -->

<div class="text">
    {{text}}
</div>


