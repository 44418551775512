<div class="container">
  <div class="scrollable-content">
    <simple-header title="Luminarias" [back]="true" icon="close"></simple-header>
    <form [formGroup]="searchFormGroup" (keydown.enter)="$event.preventDefault()">
        <div class="inline-flex">
          <div class="column inline-flex" *ngIf="!humanizeFilter?.length">
            <div class="column" *ngIf="!humanizeFilter?.length">
              <predictive-searcher
                formControlName="selectedProjectForm"
                label="Buscar por proyecto"
                [service]="searchProjectAdapter"
                [readonly]="loading"
                (selectedValue)="onSelectedValue($event)"
                class="margin-right-8"
                ></predictive-searcher>
            </div>
            <div class="column" *ngIf="!humanizeFilter?.length">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Buscar por número</mat-label>
                <input [readonly]="loading ? 'true' : null" formControlName="number" matInput (keyup.enter)="findByNumber($event)">
              </mat-form-field>
            </div>
          </div>
          <div class="fit-horizontal-content">
            <button mat-icon-button aria-label="búsqueda avanzada" (click)="advancedSearch()">
              <mat-icon>filter_alt_outline</mat-icon>
            </button>
          </div>

          <filter-list *ngIf="humanizeFilter?.length" [criterias]="humanizeFilter"></filter-list>
          <button *ngIf="humanizeFilter?.length" mat-button (click)="removeFilters()">Eliminar filtros</button>
        </div>
    </form>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div *ngFor="let luminary of luminaries" class="item">
      <list-luminary-item
        (onLinkClick)="gotoLuminaryDetail(luminary.id)"
        [number]="luminary.number"
        [project]="luminary.project?.name || 'Sin proyecto'"
        [address]="luminary.address"
        [ubication]="luminary.geolocation"
        [neighborhood]="luminary.neighborhood"
        [technology]="luminary.technology"
        [power]="luminary.power"
      ></list-luminary-item>
      <mat-divider></mat-divider>
    </div>
    <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/list-luminaries/create-luminary-distributive']">+</button>
  </div>
  <mat-paginator
    [pageIndex]="currentPage"
    [length]="pagination?.totalDocs"
    pageSize="20"
    (page)="paginate($event)">
  </mat-paginator>
</div>
