

import * as actions from '../views/technician-dashboard-maintenances/assigned-maintenances.actions';
import * as actionsDailyQuestionaries from '../views/technician-dashboard-maintenances/daily-questionaries.actions';
import * as actionsLuminaries from '../views/list-luminaries/list-luminaries.actions';
import { ActionReducer } from '@ngrx/store';

const ASSIGNED_MAINTENANCES_REDUCERS_ACTIONS = [
    actions.saveQuestionary.type,
    actions.identifyLuminary.type,
    actions.setPhotosInitialState.type,
    actions.setPhotosFinalState.type,
    actions.setRepairmentData.type,
    actions.changeState.type,
    actions.sync.type,
    actions.normalizePhotos.type
  ];
  
const DAILY_QUESTIONARIES_REDUCERS_ACTIONS = [
  actionsDailyQuestionaries.newQuestionary.type,
  actionsDailyQuestionaries.update.type,
];

const LUMINARY_REDUCERS_ACTIONS = [
  actionsLuminaries.create.type,
  actionsLuminaries.update.type,
  actionsLuminaries.remove.type
]

// console.log all actions
export function storeData(creducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action: any) {
    let storeName = '';

    if (ASSIGNED_MAINTENANCES_REDUCERS_ACTIONS.indexOf(action.type) >= 0 ) {
      storeName = 'assignedMaintenances';
    } else if (DAILY_QUESTIONARIES_REDUCERS_ACTIONS.indexOf(action.type) >= 0 ) {
      storeName = 'dailyQuestionaries';
    } else if (LUMINARY_REDUCERS_ACTIONS.indexOf(action.type) >= 0 ) {
      storeName = 'luminaries'; 
    } else {
      return creducer(state, action);
    }

    const newState = creducer(state, action);
    const data:string | null = localStorage.getItem(storeName);
    let jsonData: any;
    if (data) {
      jsonData = JSON.parse(data);
    } else {
      jsonData = {};
    }

    jsonData.metadata = {...jsonData.metadata, ...{ timestamp: new Date().getTime(), manipulated: true } };
    jsonData.value = newState[storeName];
    try {
      localStorage.setItem(storeName, JSON.stringify(jsonData));
    } catch(e) {
      console.error(e);
    }

    return newState;
  };
}
  