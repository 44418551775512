import { ChangeDetectorRef, Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { SessionStore } from '../../stores/session.store';
import { SessionService } from '../../services/session.service';
import { Router, NavigationStart } from '@angular/router';
import { UserSettingsDialogComponent } from '../../basic/user-settings-dialog/user-settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { Plugins } from '@capacitor/core';
import { SelectUsersDialogComponent } from '@ildes/basic/select-users-dialog/select-users-dialog.component';
import { ApiManagerService } from '@ildes/services/api-manager.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {

  user;
  mobileQuery: MediaQueryList;
  _mobileQueryListener;
  testPlugin;
  @ViewChild("snav") snav: any;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private dialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private apiManager: ApiManagerService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart && this.mobileQuery.matches) {
        this.snav.close();
      }
    });
  }

  ngOnInit(): void {
    const sessionStore = SessionStore.getInstance();

    SessionStore.getInstance().get$().subscribe((data) => {
      if (!data?.user) {
        this.router.navigate(['signin']);

        return;
      }
      this.user = data.user;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  gotoProfile() {
    this.router.navigate(['/app/user']);
  }

  gotoControlPanel() {
    this.router.navigate(['/app/control-panel']);
  }
  gotoMailbox() {
    this.router.navigate(['/app/mailbox']);
  }

  exit() {
    this.sessionService.invalidate();
    this.router.navigate(['signin']);
  }


  configureGroup() {

    const dialog = this.dialog.open(SelectUsersDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { selected: [{...this.user}], disabled: [{...this.user}], roleId: 'TECHNICIAN', belongsToMyProjects: true}
    });

    dialog.afterClosed().subscribe( (data) => {
      if (data.close) {
        return;
      }
      const selectedParticipants =  data.participants.filter(({ id }) => id !== this.user.id );

      this.apiManager.setCoworker(this.user.id, {
        coworkers: selectedParticipants.map(({id}) => id),
      }).subscribe((data: any) => {
       SessionStore.updateUserInfo({
          coworkers: selectedParticipants
        });
      });
    });
    
  }

  
  openSettings() {
    const dialog = this.dialog.open(UserSettingsDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog'
    });
  }
}
