import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SessionService } from '@ildes/services/session.service';
import { SessionStore } from '@ildes/stores/session.store';
import { ToastService } from '@ildes/services/toast.service';
import { Router } from '@angular/router';
import { NativeLayerService } from '@ildes/services/native-layer.service'
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
import { environment } from 'src/environments/environment';
import { MAIN_ROUTES } from 'src/config';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  credentials;
  loading;
  user;
  remember;
  releaseDate = environment.releaseDate;

  constructor(
    public native: NativeLayerService,
    private apiManager: ApiManagerService,
    private sessionService: SessionService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const sessionStore = SessionStore.getInstance().get();

    SessionStore.getInstance().get$().subscribe((data) => {
      if (data?.user) {
        this.user = data.user;

        return;
      }
    });

    this.credentials = new UntypedFormGroup({
      'email': new UntypedFormControl('', [ Validators.required, Validators.email ]),
      'password': new UntypedFormControl('', [ Validators.required ])
    });
  }

  onSubmitCredentials() {
    const { email, password } = this.credentials.controls;

    this.loading = true;
    this.sessionService.create(email.value.toLowerCase(), password.value, this.remember || this.native.isNative ).subscribe((data) => {
      this.router.navigate([MAIN_ROUTES[data.user.role.id] || MAIN_ROUTES.NOTHING_ELSE]);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  setRememberFlag(remember) {
    this.remember = remember;
  }

  go() {
    this.apiManager.getUserDetail(this.user.id).subscribe((data: any) => {
      SessionStore.updateUserInfo(data, true);
      this.router.navigate([MAIN_ROUTES[data.role.id] || MAIN_ROUTES.NOTHING_ELSE]);
    }, () => {
      this.toastService.show(`Datos actualizados por última vez ${new Date(this.user.lastUpdate).toLocaleString()}`, 'warning');
      this.router.navigate([MAIN_ROUTES[this.user.role.id] || MAIN_ROUTES.NOTHING_ELSE]);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      SplashScreen?.hide();
    }, 1000);
  }
}
