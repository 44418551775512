import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { SessionStore } from '../../../stores/session.store';
import { ApiManagerService } from '../../../services/api-manager.service';
import { ActivatedRoute } from '@angular/router';

const GENDERS = [
  {
    id: 'MALE',
    description: 'Hombre'
  },
  {
    id: 'FEMALE',
    description: 'Mujer'
  },
];

const ROLES = [
  {
    id: 'PURCHASES',
    description: 'Ventas'
  },
  {
    id: 'ADMINISTRATIVE_ASSISTANT',
    description: 'Asistente administrativo'
  },
  {
    id: 'JURIDIC',
    description: 'Jurídico'
  },
  {
    id: 'LEADER',
    description: 'Líder'
  },
  {
    id: 'QUALITY',
    description: 'Calidad'
  },
  {
    id: 'TECHNICIAN',
    description: 'Técnico electricista'
  },
  {
    id: 'ADMIN',
    description: 'Administrador'
  },
  {
    id: 'BUYANDLOGISTIC',
    description: 'Compras y logística'
  },
  {
    id: 'DRIVER',
    description: 'Conductor'
  }
];

@Component({
  selector: 'edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  profile;
  user;
  selectedUser;
  genders = GENDERS;
  role;
  roles = ROLES;
  gender;
  loading;
  loadingData
  id;

  constructor(
    private apiManager: ApiManagerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loadingData = true;
    const sessionStore = SessionStore.getInstance();

    this.user = sessionStore.get().user;

    this.route.queryParams.subscribe(params => {
      if (!params?.id) {

        this.selectedUser = sessionStore.get().user;

        this.setFormData();
      } else {
        this.id = params.id;
        this.loadingData = true;

        this.apiManager.getUserDetail(this.id).subscribe((user) => {
          this.selectedUser = user;
          this.setFormData();
          this.loadingData = false;
        });
      }
    });
  }

  setFormData() {
    this.gender = this.selectedUser.gender ? this.selectedUser.gender.id : GENDERS[0].id;
    this.role = this.selectedUser.role ? this.selectedUser.role.id : ROLES[0].id;

    this.profile = new UntypedFormGroup({
      name: new UntypedFormControl(this.selectedUser.name || '', [Validators.required]),
      surname: new UntypedFormControl(this.selectedUser.surname || '', [Validators.required]),
      email: new UntypedFormControl(this.selectedUser.email || '', [Validators.required, Validators.email]),
    });
  }

  public save() {
    const { email, name, surname } = this.profile.controls;
    const role = ROLES.find((cRole) => cRole.id === this.role);
    const gender = GENDERS.find((cGender) => cGender.id === this.gender);

    this.loading = true;
    this.apiManager.updateUser(this.selectedUser.id, {
      email: email.value, name: name.value, surname: surname.value, role, gender
    }).subscribe(() => {
      this.loading = false;

      if (this.selectedUser.id === this.user.id) {
        SessionStore.updateUserInfo({
          email: email.value,
          name: name.value,
          surname: surname.value,
          role,
          gender
        });
      }
    }, (data) => {
      this.loading = false;
    })
  }

}
