import { Component, ViewChild } from '@angular/core';

@Component({
    selector: 'app-technician-dashboard',
    template:`
    <connection-alert #alert></connection-alert>
    <simple-header class="padding-8" title="Dashboard" [back]="true" icon="close"></simple-header>
    <div class="container" #container>
        <router-outlet></router-outlet>
    </div>
    <app-bottom-navigation></app-bottom-navigation>`,
    styleUrls: ['./technician-dashboard.component.css']
})
export class TechnicianDashboardComponent {
}
