<div class="container">
    <simple-header title="Mantenimientos" [back]="true" icon="close" [actions]="groupActions" (action)="onClickHeaderAction($event)"></simple-header>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a mat-tab-link [active]="tabActive==='ALL'" (click)="tabActive='ALL'"> Todos </a>
      <a mat-tab-link [active]="tabActive==='NEW'" (click)="tabActive='NEW'"> Pendientes </a>
      <a mat-tab-link [active]="tabActive==='IN_PROGRESS'" (click)="tabActive='IN_PROGRESS'"> En curso </a>
      <a mat-tab-link [active]="tabActive==='CLOSED'" (click)="tabActive='CLOSED'"> Finalizados </a>
    </nav>
    <mat-tab-nav-panel #tabPanel class="content-tab-panel">
      <div style="height: 30px;margin: auto;text-align: center;" *ngIf="loading">
        <app-loading diameter="30"></app-loading>
        actualizando mantenimientos...
      </div>
      <filtered-list-maintenances
      *ngIf="tabActive==='ALL' && !loading"
      [assigned]="assigned"
      [showAssigmentFilter]="showAssigmentFilter"
      [selector]="selector"
      [actions]="actions"
      (doAction)="onClickAction($event)"
      (changeSelected)="changeSelection($event)">
    </filtered-list-maintenances>
    <filtered-list-maintenances
      *ngIf="tabActive==='NEW' && !loading"
      [state]="['NEW']"
      [assigned]="assigned"
      [selector]="selector"
      [actions]="actions"
      (doAction)="onClickAction($event)"
      [showAssigmentFilter]="false">
    </filtered-list-maintenances>
    <filtered-list-maintenances
      *ngIf="tabActive==='IN_PROGRESS' && !loading"
      [state]="['IN_PROGRESS']"
      [assigned]="assigned"
      [actions]="actions"
      (doAction)="onClickAction($event)"
      [showAssigmentFilter]="false">
    </filtered-list-maintenances>
    <filtered-list-maintenances
      *ngIf="tabActive==='CLOSED' && !loading"
      [state]="['CLOSED']"
      [assigned]="assigned"
      [actions]="actions"
      (doAction)="onClickAction($event)"
      [showAssigmentFilter]="false">
    </filtered-list-maintenances>
    </mat-tab-nav-panel>
    <button mat-fab color="primary" class="fab-button" [allowAction]="['maintenances.create']" [routerLink]="['/app/list-maintenances/create']">+</button>
</div>
