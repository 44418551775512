import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-bulk-luminaries-dialog',
  templateUrl: './error-bulk-luminaries-dialog.component.html',
  styleUrls: ['./error-bulk-luminaries-dialog.component.css']
})
export class ErrorBulkLuminariesDialogComponent implements OnInit {

  error;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<ErrorBulkLuminariesDialogComponent>,
  ) {
    this.error = data || {};
  }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close({ close: true });
  }
}
