import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  constructor() {
    //this.status = 'warning'
  }

  @Input() status: string;
  @Input() text: string;
  @Input() linkText: string;

  ngOnInit(): void {
    
  }

  public get theme(): string {
    return this.status;
  }

  public get icon(): string {
    switch (this.status) {
      case 'ok':
          return 'done';
      case 'error':
        return 'cancel';
      case 'warning':
        return 'warning';
      case 'advise':
        return 'notification_important';
      default:
        return '';
    }
  }

}
