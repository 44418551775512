import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import {
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  FormBuilder,
  ControlValueAccessor,
  Validators,
  NG_VALIDATORS,
  UntypedFormControl
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'predictive-searcher',
  templateUrl: './predictive-searcher.component.html',
  styleUrls: ['./predictive-searcher.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PredictiveSearcherComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PredictiveSearcherComponent),
      multi: true
    }
  ]
})
export class PredictiveSearcherComponent implements ControlValueAccessor, OnInit, OnDestroy {

  options = [];
  filteredOptions;
  loading;
  subscriptions: Subscription[] = [];
  _value;

  unknown = false;
  hide = true;
  ignoreSearch;

  formGroup;
  @Input() service;
  @Input() label;
  @Input() model;
  @Input() required;
  @Input() mandatorySelection;
  @Input() readonly;
  @Input() disabled = false;

  @Output() modelChange = new EventEmitter<number>();
  @Output() checkValue = new EventEmitter<number>();
  @Output() selectedValue = new EventEmitter<number>();

  typed;
  selected;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private apiManager: ApiManagerService
  ) { }

  ngOnInit(): void {
    const validators: any = [
      (formControl) => {
        if (!this.mandatorySelection || formControl.value?.name) {
          return null;
        }

        return this.isUnknownValue(formControl.value) ? { invalid: true }: null;
      }
    ];
    if (this.required) {
      validators.push(Validators.required);
    }

    this.typed = new UntypedFormControl('', validators);
    this.selected = new UntypedFormControl('');
    this.formGroup = new UntypedFormGroup({
      typed: this.typed,
      selected: this.selected,
    });
    this.typed.valueChanges.subscribe(this.onValueChanges.bind(this));
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  search(value) {
    return this.options.filter(({ name }) => (
      name.toLowerCase().search(value.toLowerCase()) > -1
    ));
  }

  private onValueChanges(typed = '') {
    const value:any = typed || '';

    if (value.name) {
      this.formGroup.controls.typed.setValue(value.name);
      this.formGroup.controls.selected.setValue(value)

      this.ignoreSearch = true;
      return;
    }

    if (this.ignoreSearch) {
      this.ignoreSearch = false;

      return;
    }
    const prevModel = this.model?.name;

    this.model = this.searchValue(value);
    if (prevModel !== this.model?.name) {
      this.modelChange.emit(this.model || null);
    }

    if (value.length < 3) {
      this.filteredOptions = [];

      return;
    }
    if (this._value && value.toLowerCase().indexOf((this._value || '').toLowerCase()) > -1) {
      if ( this.options ) {
        this.filteredOptions = this.search(value);
      }

      return;
    }

    this._value = value;
    this.loading = true;
    this.service({ text: value }).subscribe((data) => {
      this.loading = false;
      this.options = data.data;
      this.filteredOptions = this.search(value);
      this.formGroup.controls.typed.updateValueAndValidity()
    }, () => {
      this.loading = false;
    });
  }

  focusOut() {
    this.model = this.searchValue(this.typed.value);
    this.selected.setValue(this.model || null);
    this.modelChange.emit(this.model);
  }

  private searchValue(value) {
    const filterValue = (value || '').toLowerCase();

    return this.options.find(({ name }) => name.toLowerCase() === filterValue);
  }

  isUnknownValue(value) {
    return !this.searchValue(value);
  }

  inputAction() {
    this.checkValue.emit(this.value);
  }

  get value(): any {
    return this.model;
  }

  set value(value: any) {
    this.formGroup.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.formGroup.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.formGroup.valid ? null : { valid: false } ;
  }

  optionSelected(option) {
    this.selectedValue.emit(option);
  }
}
