<div class="scrollable-content">
  <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-form-field appearance="fill" class="margin-top-16" *ngIf="showAssigmentFilter">
    <mat-label>Filtrar por asignación</mat-label>
    <mat-select (selectionChange)="onChangeAssigned($event)">
      <mat-option>
        Todos
      </mat-option>
      <mat-option value="false">
        No asignado
      </mat-option>
      <mat-option value="true">
        Asignado
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-image-panel class="margin-top-16" *ngIf="!maintenances?.length && !loading"></app-image-panel>
  <div *ngFor="let maintenance of maintenances" class="item">
    <list-maintenance-item
      [parent]="maintenance.parent"
      (onChangeSelected)="changeSelection(maintenance, $event)"
      (onClickAction)="doIt(maintenance, $event)"
      (onLinkClick)="doIt(maintenance, $event)"
      [selector]="selector"
      [state]="maintenance.state"
      [selected]="maintenance.selected"
      [actions]="actions | filterActionsMaintenance:maintenance"
      [type]="maintenance.type"
      [luminaryNumber]="maintenance.luminary?.number"
      [project]="maintenance.project?.name || 'Sin proyecto'"
      [address]="maintenance.address"
      [neighborhood]="maintenance.neighborhood"
      [referencePoint]="maintenance.locationReferenceDescription"
      [painType]="maintenance.painType"
      [painDescription]="maintenance.painDescription"
      [technician]="maintenance.technician"
      [power]="maintenance.luminary?.power"
      [technology]="maintenance.luminary?.technology"
      [estimatedResolutionDate]="maintenance.estimatedResolutionDate"
      [repairmentDate]="maintenance.repairmentDate"
      [materials]="maintenance.materials"
      [pendingSync]="maintenance.pendingSync"
      [omitDefaultEventBehaviour]="omitDefaultEventBehaviour"
    ></list-maintenance-item>
    <mat-divider></mat-divider>
  </div>
</div>
<mat-paginator
  *ngIf="!loading"
  [pageIndex]="currentPage"
  [length]="pagination?.totalDocs"
  pageSize="20"
  (page)="paginate($event)">
</mat-paginator>
