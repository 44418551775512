import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { Router } from '@angular/router';
import { ToastService } from '@ildes/services/toast.service';

const EDIT = {
  icon: 'edit',
  id: 'edit',
  description: 'Modificar',
  disabled: false
};

const REMOVE = {
  icon: 'delete',
  id: 'remove',
  description: 'Eliminar',
  disabled: false,
};

const ACTIONS = [ EDIT, REMOVE ];


@Component({
  selector: 'app-list-asoc-material',
  templateUrl: './list-asoc-material.component.html',
  styleUrls: ['./list-asoc-material.component.css']
})
export class ListAsocMaterialComponent implements OnInit {

  loading;
  pagination;
  asocMaterials;
  currentPage = 0;
  actions = ACTIONS;

  constructor(
    public apiManager: ApiManagerService,
    private router: Router,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.list();
  }


  list(pageIndex = 0) {
    const page = pageIndex ? { page: pageIndex + 1 } : {};

    this.loading = true;
    this.apiManager.listAsocMaterial(page).subscribe((data:any) => {
      this.loading = false;
      this.asocMaterials = data.data;
      this.pagination = data.pagination;
    }, () => { this.loading = false; });
  }

  onClickActionEvent(currentAsoc, event) {
    switch(event.id) {
      case EDIT.id:
        this.router.navigate(['/app/list-asoc-material/edit'], { queryParams: { id: currentAsoc.id } });
        break;
      case REMOVE.id:
        this.loading = true;
        this.apiManager.removeAsocMaterials(currentAsoc.id).subscribe(() => {
          this.toast.show('Asociación eliminada correctamente', 'ok');
          this.loading = false;
          this.asocMaterials = this.asocMaterials.filter((asocMaterial) => asocMaterial.id !== currentAsoc.id);
        }, () => {this.loading = false;})
        break;
      default:
        break;
    }
  }

  paginate(event) {
    this.list(event.pageIndex);
  }
}
