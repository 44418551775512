import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './views/main/main.component';
import { SigninComponent } from './views/signin/signin.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { TechnicianDashboardMaintenancesComponent } from './views/technician-dashboard-maintenances/technician-dashboard-maintenances.component';
import { ProfileComponent } from './views/profile/profile.component';
import { EditProfileComponent } from './views/profile/edit-profile/edit-profile.component';
import { EditContactInfoComponent } from './views/profile/edit-contact-info/edit-contact-info.component';
import { EditDocumentationAndHhrrInformationComponent } from './views/profile/edit-documentation-and-hhrr-information/edit-documentation-and-hhrr-information.component';
import { ControlPanelComponent } from './views/control-panel/control-panel.component';
import { ListUsersComponent } from './views/list-users/list-users.component';
import { CreateUserComponent } from './views/create-user/create-user.component';
import { ChangePasswordComponent } from './views/profile/change-password/change-password.component';
import { MaterialComponent } from './views/material/material.component';
import { CreateMaterialComponent } from './views/create-material/create-material.component';
import { CreateProjectComponent } from './views/create-project/create-project.component';
import { ListProjectsComponent } from './views/list-projects/list-projects.component';
import { CreateLuminairesComponent } from './views/create-luminaires/create-luminaires.component';
import { ListLuminariesComponent } from './views/list-luminaries/list-luminaries.component';
import { CreateAsocMaterialComponent } from './views/create-asoc-material/create-asoc-material.component';
import { ListAsocMaterialComponent } from './views/list-asoc-material/list-asoc-material.component';
import { LuminaryComponent } from './views/luminary/luminary.component';
import { ListMaintenancesComponent } from './views/list-maintenances/list-maintenances.component';
import { CreateMaintenanceComponent } from './views/create-maintenance/create-maintenance.component';
import { AssignToTechnicalComponent } from './views/list-maintenances/assign-to-technical/assign-to-technical.component';
import { ListMaterialsAssignedComponent } from './views/list-materials-assigned/list-materials-assigned.component';
import { TechniciansComponent } from './views/technicians/technicians.component';
import { TechniciansDetailComponent } from './views/technicians/detail/detail.component';
import { RepairMaintenanceComponent } from './views/repair-maintenance/repair-maintenance.component';
import { EditLuminaryIdentificationDataComponent } from './views/luminary/edit-luminary-identification-data/edit-luminary-identification-data.component';
import { EditPostAndLuminaryDataComponent } from './views/luminary/edit-post-and-luminary-data/edit-post-and-luminary-data.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { EditProjectComponent } from './views/edit-project/edit-project.component';

import { TestComponent } from './views/test/test.component';
import { EditAsocMaterialComponent } from './views/edit-asoc-material/edit-asoc-material.component';
import { DetailMaintenanceComponent } from './views/detail-maintenance/detail-maintenance.component';
import { MailboxComponent } from './views/mailbox/mailbox.component';
import { TaskDetailComponent } from './views/task-detail/task-detail.component';
import { ListStoresComponent } from './views/list-stores/list-stores.component';
import { CreateStoreComponent } from './views/create-store/create-store.component';
import { StoreItemsComponent } from './views/store-items/store-items.component';
import { AddStoreItemsComponent } from './views/add-store-items/add-store-items.component';
import { RememberPasswordComponent } from './views/remember-password/remember-password.component';
import { CreateLuminaryDistributiveComponent } from './views/create-luminary-distributive/create-luminary-distributive.component';
import { CreateExpansionComponent } from './views/create-expansion/create-expansion.component';
import { CreateModernizationComponent } from './views/create-modernization/create-modernization.component';
import { TechnicianDashboardMaterialsComponent } from './views/technician-dashboard-materials/technician-dashboard-materials.component';
import { TechnicianDashboardExpansionsComponent } from './views/technician-dashboard-expansions/technician-dashboard-expansions.component';
import { TechnicianDashboardModernizationsComponent } from './views/technician-dashboard-modernizations/technician-dashboard-modernizations.component';

const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyComponent },
  {
    path: 'app', component: MainComponent, children:
      [
        {
          path: 'dashboard', component: DashboardComponent, children: [
            { path: 'maintenances', component: TechnicianDashboardMaintenancesComponent },
            { path: 'materials', component: TechnicianDashboardMaterialsComponent },
            { path: 'modernizations', component: TechnicianDashboardModernizationsComponent },
            { path: 'expansions', component: TechnicianDashboardExpansionsComponent },
          ]
        },
        { path: 'repair-maintenance', component: RepairMaintenanceComponent },
        { path: 'user', component: ProfileComponent },
        { path: 'user/edit-profile', component: EditProfileComponent },
        { path: 'user/edit-contact-info', component: EditContactInfoComponent },
        { path: 'user/edit-documentation', component: EditDocumentationAndHhrrInformationComponent },
        { path: 'user/change-password', component: ChangePasswordComponent },
        { path: 'control-panel', component: ControlPanelComponent },
        { path: 'list-users', component: ListUsersComponent },
        { path: 'list-users/create', component: CreateUserComponent },
        { path: 'material', component: MaterialComponent },
        { path: 'material/create', component: CreateMaterialComponent },
        { path: 'list-projects', component: ListProjectsComponent },
        { path: 'list-projects/create', component: CreateProjectComponent },
        { path: 'list-projects/edit', component: EditProjectComponent },
        { path: 'list-luminaries', component: ListLuminariesComponent },
        { path: 'list-luminaries/create', component: CreateLuminairesComponent },
        { path: 'list-luminaries/create-luminary-distributive', component: CreateLuminaryDistributiveComponent },
        { path: 'create-expansion', component: CreateExpansionComponent },
        { path: 'create-modernization', component: CreateModernizationComponent },
        { path: 'list-asoc-material', component: ListAsocMaterialComponent },
        { path: 'list-asoc-material/create', component: CreateAsocMaterialComponent },
        { path: 'list-asoc-material/edit', component: EditAsocMaterialComponent },
        { path: 'list-maintenances', component: ListMaintenancesComponent },
        { path: 'list-maintenances/create', component: CreateMaintenanceComponent },
        { path: 'list-maintenances/assign-to-technical', component: AssignToTechnicalComponent },
        { path: 'luminary', component: LuminaryComponent },
        { path: 'luminary/edit-identification-data', component: EditLuminaryIdentificationDataComponent },
        { path: 'luminary/edit-post-data', component: EditPostAndLuminaryDataComponent },
        { path: 'technicians', component: TechniciansComponent },
        { path: 'technicians/detail', component: TechniciansDetailComponent },
        { path: 'detail-maintenance', component: DetailMaintenanceComponent },
        { path: 'list-stores', component: ListStoresComponent },
        { path: 'list-stores/create', component: CreateStoreComponent },
        { path: 'store-items', component: StoreItemsComponent },
        { path: 'store-items/add-items', component: AddStoreItemsComponent },
        { path: 'mailbox', component: MailboxComponent },
        { path: 'task', component: TaskDetailComponent },
        { path: 'test', component: TestComponent }
      ]
  },
  { path: 'signin', component: SigninComponent },
  { path: 'remember-password', component: RememberPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
