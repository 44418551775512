<div class="container">
  <div class="scrollable-content">
    <simple-header title="Panel de control" [back]="true" icon="close"></simple-header>
    <mat-action-list>
      <button mat-list-item (click)="gotoListUsers()" [allowAction]="['users.list']">
        <mat-icon>account_circle</mat-icon> <span class="padding-left-16">Usuarios</span>
      </button>
      <button mat-list-item [routerLink]="['/app/list-asoc-material']" [allowAction]="['materials.associate']">
        <mat-icon>wb_incandescent</mat-icon> <span class="padding-left-16">Material por luminaria</span>
      </button>
      <button mat-list-item [routerLink]="['/app/material']" [allowAction]="['materials.list']">
        <mat-icon>extension</mat-icon> <span class="padding-left-16">Material</span>
      </button>
      <button mat-list-item [routerLink]="['/app/list-projects']" [allowAction]="['projects.list']">
        <mat-icon>folder</mat-icon> <span class="padding-left-16">Proyectos</span>
      </button>
      <button mat-list-item [routerLink]="['/app/list-stores']" [allowAction]="['store.list']">
        <mat-icon>list</mat-icon> <span class="padding-left-16">Almacen</span>
      </button>
    </mat-action-list>
  </div>
</div>
