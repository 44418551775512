import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'list-asoc-material-item',
  templateUrl: './list-asoc-material-item.component.html',
  styleUrls: ['./list-asoc-material-item.component.css']
})
export class ListAsocMaterialItemComponent implements OnInit {

  @Input() technology;
  @Input() materials;
  @Input() power;
  @Input() actions;
  @Output() onClickAction =  new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickActionEvent(event) {
    this.onClickAction.emit(event);
  }

}
