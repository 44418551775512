<mat-toolbar>
  <div class="column-full-space">
    <img src="./assets/images/logo.png" class="logo">
  </div>
  <button (click)="back()" mat-icon-button aria-label="volver">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="container">
  <div class="scrollable-content">
    <h1>Política de privacidad</h1>
    <p>La presente Política de Privacidad, establecida a día 3 de Junio de 2022, define los términos en que GONFOR usa y protege la información que es
      proporcionada por sus usuarios al momento de utilizar su sitio web y aplicación móvil. Esta compañía está
      comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información
      personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los
      términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada
      por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con
      dichos cambios.</p>
    <h2>Información que es recogida</h2>
    <p>
      Nuestro sitio web almacenará sus datos personales, tales como:
    </p>
    <ul>
      <li>
        nombre
      </li>
      <li>
        apellidos
      </li>
      <li>
        fecha de nacimiento
      </li>
      <li>
        correo electrónico
    
      </li>
      <li>
        datos de su cédula
      </li>
      <li>
        tipo de contrato dentro de la ogranización
      </li>
      <li>
        rol dentro de la organización
      </li>
    </ul>
    <h2>Uso de la información recogida</h2>
    <p>
      La información almacenada se usará con el objetivo de identificarle dentro de la empresa, así como en la misma
      plataforma.
    </p>
    <h2>Uso del dispositivo</h2>
    <p>
      La aplicación necesitará acceder a su cámara para poder hacer fotos del estado de las luminarias y del resultado
      final del mantenimiento. Además, podrá realizar una foto para cambiar su foto de perfil.
      También será necesario el acceso al GPS para identificar la posición de una luminaria y poder ubicarla para su
      posterior localización.
    </p>

    <h2>Cookies</h2>
    <p>
      El sistema no usa cookies, salvo las que se necesitan para el uso de la librería de google maps.
    </p>
    <h2>Control de su información personal</h2>
    <p>
      Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
    </p>


  </div>
</div>