<div class="space-width">
  <div class="inline-flex">
    <tag-automatic-color icon="memory" [text]="technology" class="actionable" matRipple *ngIf="technology"></tag-automatic-color>
    <tag-automatic-color icon="offline_bolt" [text]="power" class="actionable" matRipple *ngIf="power"></tag-automatic-color>
  </div>
  <div class="inline-flex">
    <tag-automatic-color [keyColor]="technology+'_'+power" *ngFor="let material of materials" [text]="material | humanizeDescriptionMaterial"></tag-automatic-color>
  </div>
</div>
<div class="actions" *ngIf="actions">
  <contextual-action [actions]="actions" [collapseAll]="true" (actionEvent)="onClickActionEvent($event)"></contextual-action>
</div>