import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiManagerService } from '@ildes/services/api-manager.service';

@Component({
  selector: 'app-change-material-list-dialog',
  templateUrl: './change-material-list-dialog.component.html',
  styleUrls: ['./change-material-list-dialog.component.css']
})
export class ChangeMaterialListDialogComponent implements OnInit {
  loadingStores;
  stores = [];
  selectedStore;
  availableMaterials;
  selectedMaterials = [];
  @ViewChild('stepper') stepper: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private apiManager: ApiManagerService,
    public dialogRef: MatDialogRef<ChangeMaterialListDialogComponent>,
  ) {
    
    this.availableMaterials = data.availableMaterials.map((item) => ({
      amount: 0,
      max: item.amount,
      reference: { ...item.reference }
    }));
  }

  ngOnInit(): void {
    this.loadingStores = true;
    this.apiManager.listStores().subscribe((data: any) => {
        this.stores = data.data;
        this.loadingStores = false;
    }, () => {
        this.loadingStores = false;
    })
  }

  continue() {
    this.selectedMaterials = this.availableMaterials.filter((item) => item.amount > 0).map((item) =>({ ...item }));
    setTimeout(() => this.stepper.next());
  }

  submit() {
    this.dialogRef.close({ selectedMaterials: this.selectedMaterials, selectedStore: this.selectedStore });
  }

  closeDialog() {
    this.dialogRef.close({ close: true });
  }

  get computedSelectedMaterials() {
    return this.availableMaterials.reduce((acum, current) => current.amount + acum, 0)
  }

  onStoreChange($event) {
    this.selectedStore = $event.value;
  }

}
