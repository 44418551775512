import { Injectable } from '@angular/core';
import { on } from '@ngrx/store';
import * as actions from './assigned-maintenances.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, throwError } from 'rxjs';
import { map, mergeMap, catchError, scan } from 'rxjs/operators';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { StorageService } from '@ildes/services/storage-service.service';

@Injectable()
export class MaintenancesEffects {

  loadMaintenances$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadMaintenances),
      mergeMap((action) => this.fetchData(action).pipe(
        map((data: any) =>
          actions.loadedMaintenances({
            maintenances: data.data
          })
        ),
        catchError(error => {
          if (!error.code || error.code > 0) {
            return of(actions.loadedMaintenances({
              maintenances: null
            }))
          }
          const maintenances = this.storageService.getItem('assignedMaintenances');

          return of(actions.loadedMaintenances({
            maintenances
          }))
        })
      ))
    )
  });

  fetchData(action) {
    const params = {
      assigned: action.assigned,
      ...action.state && { state: [action.state] }
    };
    let cachedData = this.storageService.getItem('assignedMaintenances');

    if (!cachedData || !cachedData.length) {
        return this.apiManager.listMaintenances(params, { error: false, cache: action.options.cache });
    }
    
    return this.apiManager.listMaintenances(params, { error: true, cache: action.options.cache })
     .pipe(
      map((data: any) => {
        const updatedLocalData = data.data.map( (maintenance) => cachedData.find(({ id, updatedAt, pendingSync, pendingNormalising }) => {
          return id === maintenance.id && (updatedAt > maintenance.updatedAt || pendingSync || pendingNormalising);
        })  || maintenance).map((maintenance) => ({...maintenance, ...{ questionary: maintenance.questionary?.length ? maintenance.questionary : maintenance.answers}}));

        return { data: updatedLocalData};
      }),
      catchError((e) => {
        if (e.code === -1) {
          return of({ data: cachedData });
        } 
        return throwError(e);
      })
      );
  }
  constructor(
    private actions$: Actions,
    private apiManager: ApiManagerService,
    private storageService: StorageService,
  ) {}
}
