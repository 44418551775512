import { Injectable } from '@angular/core';
import { ApiManagerService } from './api-manager.service';
import { SessionStore } from '../stores/session.store'
import { NativeLayerService, ANDROID } from '@ildes/services/native-layer.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private apiManager: ApiManagerService,
    private nativeLayer: NativeLayerService
  ) { }

  create(email, password, remember) {
    return this.apiManager.signin({ email, password }).pipe(
      map((data) => {
        SessionStore.create(data.user, data.token, remember);

        if (this.nativeLayer.platform === ANDROID && data.tokenNative) {
          this.nativeLayer.setTokenNative(data.tokenNative);
        }

        return data;
      })
    );
  }

  recover(email, recoverToken) {
    return this.apiManager.signinWithRecoverToken({ email, recoverToken }).pipe(
      map((data) => {
        SessionStore.create(data.user, data.token, false);

        if (this.nativeLayer.platform === ANDROID && data.tokenNative) {
          this.nativeLayer.setTokenNative(data.tokenNative);
        }

        return data;
      })
    );
  }

  invalidate() {
    SessionStore.invalidate();
  }
}
