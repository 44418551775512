import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStore } from '../../stores/session.store';
import { ApiManagerService } from '../../services/api-manager.service';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit {

  user;

  constructor(
    private router: Router,
    private apiManager: ApiManagerService,
  ) { }

  ngOnInit(): void {
    const sessionStore = SessionStore.getInstance();

    this.user = sessionStore.get().user;
  }

  gotoListUsers() {
    this.router.navigate(['/app/list-users']);
  }

}
