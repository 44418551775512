import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-maintenance-list-dialog',
    template: `
    <simple-header title="Selecciona un mantenimiento" (action)="close()" icon="close"></simple-header>
    <filtered-list-maintenances
        [selector]="false"
        [state]="['NEW']"
        [actions]="[]"
        (doAction)="onClickAction($event)"
    >
    </filtered-list-maintenances>
    `,
    styleUrls: ['./maintenance-list-dialog.component.css'],
})
export class MaintenanceListDialogComponent {
    technician;
    constructor(
        public dialogRef: MatDialogRef<MaintenanceListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.technician = data.technician;
    }

    onClickAction(event) {
        this.dialogRef.close({
            selectedMaintenance: event.maintenance
        })
    }
    close() {
        this.dialogRef.close({
            close: true
        })
    }
}
