import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SessionStore } from '@ildes/stores/session.store';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
import * as actionsLuminmaries from '../list-luminaries/list-luminaries.actions';
import getAvailableUsedMaterials from '@ildes/utils/calculate-available-materials';
import { SyncDataService } from '@ildes/services/sync-data.service';
import { ImageCacheService } from '@ildes/services/image-cache.service';
import { SelectUsersDialogComponent } from '../../basic/select-users-dialog/select-users-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangeMaterialListDialogComponent } from '@ildes/views/change-material-list-dialog/change-material-list-dialog.component';
import { ListMaintenancesPendingSyncDialogComponent } from '../list-maintenances-pending-sync-dialog/list-maintenances-pending-sync-dialog.component';
import { FingerprintService } from '../../services/fingerprint.service';
import { NativeLayerService } from '@ildes/services/native-layer.service';
import { UnsyncExpansionsService } from '@ildes/services/unsync-expansions.service';
import { UnsyncModernizationsService } from '@ildes/services/unsync-modernizations.service';

@Component({
    selector: 'app-technician-dashboard-materials',
    template: `
    <pull-to-refresh [scrollableContent]="scrolledContent" [target]="container" (refresh)="refresh()" ></pull-to-refresh>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="container" #container>
      <div class="scrollable-content" #scrolledContent>  
        <mat-accordion displayMode="flat" [multi]="true">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Material en posesión</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-image-panel class="margin-top-16" text="No tienes dispones de material" *ngIf="!availableMaterials?.length"></app-image-panel>
            <div *ngFor="let material of availableMaterials" class="item">
              <list-material-item
                [name]="material.reference.name"
                [unit]="material.reference.unit"
                [amount]="material.amount || material.count"
                class="padding-top-8">
              </list-material-item>
              <mat-divider></mat-divider>
            </div>
            <div class="flex-row margin-top-16">
              <button class="right" (click)="openReturnToStoreDialog()" [disabled]="availableMaterials?.length === 0" mat-button>Devolver a bodega</button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Material en bodega</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-image-panel class="margin-top-16" text="No tienes material pendiente de recoger" *ngIf="!user?.assignedMaterials?.length"></app-image-panel>
            <div *ngFor="let material of user?.assignedMaterials" class="item">
              <list-material-item
                [unitDescription]="material.store"
                [name]="material.reference.name"
                [unit]="material.reference.unit"
                [amount]="material.amount || material.count"
                class="padding-top-8">
              </list-material-item>
              <mat-divider></mat-divider>
            </div>
            <div class="align-center padding-top-8 padding-bottom-8" *ngIf="user?.assignedMaterials?.length">
              <button [disabled]="loadingConfirm" mat-raised-button color="primary" (click)="confirm()">Confirmar</button>
            </div>
            <p class="align-center" *ngIf="user?.assignedMaterials?.length">
              <i>Al Confirmar el material pasará a estar en tu posesión.</i>
            </p>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingConfirm"></mat-progress-bar>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Material devuelto</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-image-panel class="margin-top-16" text="No existe material devuelto" *ngIf="!user?.returnedMaterials?.length"></app-image-panel>
            <div *ngFor="let material of user?.returnedMaterials" class="item">
              <list-material-item
                [unitDescription]="material.store"
                [name]="material.reference.name"
                [unit]="material.reference.unit"
                [amount]="material.amount || material.count"
                class="padding-top-8">
              </list-material-item>
              <mat-divider></mat-divider>
            </div>
            <p class="align-center padding-top-8" *ngIf="user?.returnedMaterials?.length">
              <i>Este material está pendiente de devolver por parte del líder.</i>
            </p>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingConfirm"></mat-progress-bar>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    `,
    styleUrls: ['./technician-dashboard-materials.component.css'],
})
export class TechnicianDashboardMaterialsComponent {

   
  errorUpdateUser;
  user;
  loadingConfirm;
  loadingMaintenances;
  maintenances;
  actionsClosed = [];
  state = ['NEW'];
  stats;
  pagination;
  currentPage = 0;
  subscribeSession;
  subscribeMaintenancesState;
  subscribeLuminariesState;
  loading = false;
  availableMaterials;
  pendingSyncMaintenances;
  rawMaintenanceState;
  pendingSyncLuminaries;
  availableBiometry;
  fingerprintEnabled;
  coworker;

  @ViewChild('scrolledContent') scrolledContent;
  

  constructor(
    private zone: NgZone,
    private store: Store<AppState>,
    private router: Router,
    public apiManager: ApiManagerService,
    public syncData: SyncDataService,
    private cacheService: ImageCacheService,
    private dialog: MatDialog,
    private fingerprint: FingerprintService,
    private nativeLayer: NativeLayerService,
    private unsyncExpansionsSerive: UnsyncExpansionsService,
    private unsyncModernizations: UnsyncModernizationsService,
  ) {

  }

  private isPendingSync(pendingSync) {
    return (Object.keys(pendingSync || { })).find((key) => {
      return pendingSync[key];
    });
  }

  get fingerprintAlertText() {
    if (!this.availableBiometry) {
      return 'El dispositivo no es compatible con la huella. No podrás firmar documentos con este dispositivo.';
    } else if (!this.fingerprintEnabled) {
      return 'Aun no has activado la huella. Necesitas activar la huella para completar el cierre de los mantenimientos.'
    }

    return '';
  }

  configureGroup() {
    const dialog = this.dialog.open(SelectUsersDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { selected: [{...this.user}], disabled: [{...this.user}], roleId: 'DRIVER'}
    });

    dialog.afterClosed().subscribe( (data) => {
      if (data.close) {
        return;
      }
      const selectedParticipants =  data.participants.filter(({ id }) => id !== this.user.id );

      this.apiManager.setCoworker(this.user.id, {
        coworkers: selectedParticipants.map(({id}) => id),
      }).subscribe((data: any) => {
       SessionStore.updateUserInfo({
          coworkers: selectedParticipants
        });
      });
    });
  }

  async enrollFingerprint() {
    if (!this.nativeLayer.isNative) {
      return;
    }

    await this.fingerprint.enroll();

    this.fingerprintEnabled = true;
  }

  ngOnInit(): void {

    if (this.nativeLayer.isNative) {
      this.fingerprint.isCompatible().then((result) => {
        this.availableBiometry = result;
  
        this.fingerprint.isEnrolled().then((result) => {
          this.fingerprintEnabled = result;
        });
      });
    }

    this.loadingMaintenances = true;
    let extraData = true;

    this.subscribeLuminariesState = this.store.select('luminaries').subscribe((luminaries) => {
      this.pendingSyncLuminaries = luminaries;
    });

    this.store.dispatch(
      actionsLuminmaries.initState()
    );

    this.subscribeSession = SessionStore.getInstance()
      .get$()
      .subscribe((data) => {
        this.user = data?.user;
        this.coworker = this.user.coworkers?.length;
        if (this.user && this.rawMaintenanceState) {
          this.availableMaterials = getAvailableUsedMaterials(this.user.confirmedMaterials, this.rawMaintenanceState, this.unsyncExpansionsSerive.getItems(), this.unsyncModernizations.getItems());

          return;
        }
        if (!this.user || this.subscribeMaintenancesState ) {
          return;
        }

        this.subscribeMaintenancesState = this.store.select('assignedMaintenances').subscribe((data) => {
          this.zone.run(() => {
            this.loadingMaintenances = false;
            if (!data) {
              return;
            }
            this.rawMaintenanceState = data;
            this.maintenances = data.filter(({ state }) => 
              this.state?.length > 0 ? this.state.includes(state): true
            );
            this.pendingSyncMaintenances = data.filter(({ pendingSync }) => this.isPendingSync(pendingSync));
            this.availableMaterials = getAvailableUsedMaterials(this.user.confirmedMaterials, data, this.unsyncExpansionsSerive.getItems(), this.unsyncModernizations.getItems());
          });
        }, () => {
          this.loadingMaintenances = false;
        });
        if (!extraData) {
          extraData = false;
          return;
        }
        this.fetchMaintenances(0, false);
        this.fetchStats();
    });
  }

  fetchStats() {
    this.apiManager.fetchStats({ technician: this.user.id }).subscribe((stats: any) => {
      this.stats = stats.data;
    });
  }

  ngOnDestroy() {
    this.subscribeSession.unsubscribe();
    this.subscribeMaintenancesState.unsubscribe();
  }

  private fetchMaintenances(pageIndex = 0, cache = true) {
    this.loadingMaintenances = true;
    this.maintenances = null;
    this.store.dispatch(
      actions.loadMaintenances({
        assigned: this.user.id,
        state: undefined,
        options: { cache }
      })
    )
  }

  navigate(maintenance) {
    if (maintenance.state !== 'CLOSED') {
      this.router.navigate(['/app/repair-maintenance'], { queryParams: { id: maintenance.id } });
    } else {
      this.router.navigate(['/app/detail-maintenance'], { queryParams: { id: maintenance.id } });
    }
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  getBase64FromCache(photo) {
    return new Promise(async (resolve) => {
      const response = await this.cacheService.get(photo);
      const buffer = await response.blob();
      const base64 = await this.blobToBase64(buffer)

      resolve(base64);
    })
  }

  performPhotos(photos) {
    return Promise.all(photos.map(async(photo) => {
      if (!photo.includes('/base64/')) {
        return photo;
      }
      const buffer = await this.cacheService.get(photo).then((response)=>response.blob());
      const base64 = await this.blobToBase64(buffer);

      return base64;
    }));
  }

  confirm() {
    this.loadingConfirm = true;
    this.apiManager.confirmMaterial(this.user.id, { assignedMaterials: this.user.assignedMaterials }).subscribe(() => {
      this.updateUser().subscribe(() => this.loadingConfirm = false );
    }, () => {
      this.loadingConfirm = false;
    });
  }

  updateUser() {
    const callUserDetail = this.apiManager.getUserDetail(this.user.id);

    callUserDetail.subscribe((data: any) => {
      SessionStore.updateUserInfo({
        confirmedMaterials: data.confirmedMaterials,
        assignedMaterials: data.assignedMaterials,
        returnedMaterials: data.returnedMaterials
      });
      this.errorUpdateUser = false;
    }, () => {
      this.errorUpdateUser = true;
    });

    return callUserDetail;
  }

  setMaintenanceFilter(event) {
    this.state = event.value ? event.value.split(','): '';
    this.fetchMaintenances();
  }

  fetchUser() {
    this.apiManager.getUserDetail(this.user.id).subscribe((data: any) => {
      SessionStore.updateUserInfo(data, true);
    });
  }

  refresh() {
    this.fetchMaintenances(0, false);
    this.fetchStats();
    this.fetchUser();
  }

  openReturnToStoreDialog() {
    const dialog = this.dialog.open(ChangeMaterialListDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { availableMaterials: this.availableMaterials }
    });

    dialog.afterClosed().subscribe( (data) => {
      if (data.close) {
        return;
      }
      this.apiManager.returnToStore(this.user.id, { store: data.selectedStore, materials: data.selectedMaterials }).subscribe(() => {
        this.loading = true;
        this.updateUser().subscribe(() => this.loading = false, () => this.loading = false );
      });
      console.log(data);
    });
  }

  public openPendingSyncMaintenancesDialog() {
    const dialog = this.dialog.open(ListMaintenancesPendingSyncDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { maintenances: this.pendingSyncMaintenances, luminaries: this.pendingSyncLuminaries }
    });

    dialog.afterClosed().subscribe((data) => {
      if (data.close) {
        return;
      }

      if (data.selectedMaintenance) {
        this.navigate(data.selectedMaintenance);
      } else if (data.selectedLuminary) {
        if (data.selectedLuminary.id) {
          this.router.navigate(['/app/luminary'], { queryParams: { id: data.selectedLuminary.id} });
        } else {
          this.router.navigate(['app/list-luminaries/create'], { state: data.selectedLuminary });
        }
      }
    })
  }

  get numElementsPendingSync() {
    return this.pendingSyncMaintenances?.length + this.pendingSyncLuminaries?.length;
  }

}
