import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { concat } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from '@ildes/services/toast.service';

@Component({
  selector: 'app-edit-asoc-material',
  templateUrl: './edit-asoc-material.component.html',
  styleUrls: ['./edit-asoc-material.component.css']
})
export class EditAsocMaterialComponent implements OnInit {
  private id;
  loading;
  possibleMaterialBk;
;

  possibleMaterial = [

  ];

  selectedMaterial = [

  ];

  technology;
  power;

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      event.previousContainer.data[event.previousIndex].amount = 1;

      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private apiManager: ApiManagerService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.queryParams.subscribe((params) => {
      concat(
        this.setAsociationData(params),
        this.setPossibleMaterial()
      ).subscribe(( ) => { this.loading = false }, () => { this.loading = false });
    });
  }

  private setPossibleMaterial() {
    return this.apiManager.listMaterials().pipe(
      tap((materialData: any) => {
        this.possibleMaterial = materialData.data
          .filter((cMaterial) => !this.selectedMaterial.find(({ id }) => cMaterial.id === id))
          .map(({ unit, id, name }) => ({
            unit,
            id,
            name
          }));
        this.possibleMaterialBk = [...this.possibleMaterial];
      })
    );
  }

  private setAsociationData(params) {
    this.id = params.id;
    return this.apiManager.getAsocMaterial({ id: this.id }).pipe(
      tap((data: any) => {
        this.selectedMaterial = data.materials;
        this.technology = data.technology;
        this.power = data.power;
      })
    );
  }

  save() {
    this.loading = true;
    const data = {
      materials: this.selectedMaterial,
      technology: this.technology,
      power: this.power,
    };

    // TODO: editAsocMaterial
    this.apiManager.changeAsocMaterial(this.id, data).subscribe(() => {
      this.toast.show('Modificación realizada correctamente', 'ok');
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}
