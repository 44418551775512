<div class="container">
  <simple-header class="padding-8 background" title="Datos de usuario" [back]="true" icon="close"></simple-header>
  <app-loading *ngIf="loading"></app-loading>
  <div class="section" *ngIf="this.selectedUser && !this.loading">
    <div class="action-element-container">
      <h3 color="primary" class="action-text-title">Perfil</h3>
      <div matRipple role="button" (click)="gotoEditProfile()" class="actionable action-button">Editar</div>
    </div>
    <div class="padding-top-8 action-element-container">
      <div class="action-text-title center">
        Foto: <i>Pulsa en la foto para cambiarla</i>
      </div>
      <div matRipple role="button" (click)="changePhoto()" class="actionable action-button">
        <profile-avatar size="medium" [image]="this.selectedUser.photo | imagePath" [name]="this.selectedUser.name" [surname]="this.selectedUser.surname"></profile-avatar>
      </div>
    </div>
    <mat-divider class="paddin-8"></mat-divider>
    <p class="padding-top-8">
      <strong>Nombre: </strong><span>{{this.selectedUser.name}} {{this.selectedUser.surname}}</span>
    </p>
    <mat-divider class="paddin-8"></mat-divider>
    <p class="padding-top-8">
      <strong>Rol: </strong><span [ngClass]="{'error': !this.selectedUser.role}"> {{this.selectedUser.role?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Sexo: </strong><span [ngClass]="{'error': !this.selectedUser.gender}">{{this.selectedUser.gender?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Correo electrónico: </strong><span [ngClass]="{'error': !this.selectedUser.email}">{{this.selectedUser.email || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <div class="padding-top-8 padding-bottom-12 action-element-container">
      <span class="action-text-title">
        <strong>Contraseña: </strong><span> *****</span>
      </span>
      <div matRipple role="button" class="actionable action-button" [routerLink]="['/app/user/change-password']" *ngIf="this.user.id === this.selectedUser.id">Cambiar</div>
    </div>
    <mat-divider *ngIf="availableBiometry"></mat-divider>
    <div class="padding-top-8 action-element-container" *ngIf="availableBiometry">
      <span class="action-text-title">
        <strong>Firma con biometría: </strong><span [ngClass]="{'error': !this.fingerprintEnabled}"> {{this.fingerprintEnabled ? 'Activada': 'Desactivada'}}</span>
      </span>
      <div matRipple role="button" class="actionable action-button" *ngIf="this.user.id === this.selectedUser.id && this.fingerprintEnabled !== undefined && !this.fingerprintEnabled" (click)="enrollFingerprint()">Activar</div>
      <div matRipple role="button" class="actionable action-button" *ngIf="this.user.id === this.selectedUser.id && this.fingerprintEnabled !== undefined && this.fingerprintEnabled" (click)="disableFingerprint()">Desactivar</div>
    </div>
  </div>
  <div class="section margin-top-8" *ngIf="this.selectedUser">
    <div class="action-element-container">
      <h3 class="action-text-title">Información de contacto</h3>
      <div matRipple role="button" (click)="gotoEditContactInformation()" class="actionable action-button">Editar</div>
    </div>
    <p class="padding-top-8">
      <strong>Dirección: </strong><span [ngClass]="{'error': !this.selectedUser.address}">{{this.selectedUser.address || 'No informado'}} </span>
    </p>
    <mat-divider class="paddin-8"></mat-divider>
    <p class="padding-top-8">
      <strong>Teléfono: </strong><span [ngClass]="{'error': !this.selectedUser.phone}">{{this.selectedUser.phone || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Celular: </strong><span [ngClass]="{'error': !this.selectedUser.mobilePhone}">{{this.selectedUser.mobilePhone || 'No informado'}} </span>
    </p>
  </div>
  <div class="section margin-top-8" *ngIf="this.selectedUser">
    <div class="action-element-container">
      <h3 class="action-text-title">Documentación e información de RRHH</h3>
      <div matRipple role="button" (click)="gotoEditDocumentation()" class="actionable action-button">Editar</div>
    </div>
    <p class="padding-top-8">
      <strong>Documento de identificación: </strong><span [ngClass]="{'error': !this.selectedUser.document?.number}">{{this.selectedUser.document?.number || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Fecha de nacimiento: </strong><span [ngClass]="{'error': !this.selectedUser.bornDate}">{{ this.selectedUser.bornDate ? (this.selectedUser.bornDate | formatDate): 'No informado'}} </span>
    </p>
    <mat-divider class="paddin-8"></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de contrato: </strong><span [ngClass]="{'error': !this.selectedUser.contractType?.description}">{{this.selectedUser.contractType?.description || 'No informado'}} </span>
    </p>
    <mat-divider></mat-divider>
    <p class="padding-top-8">
      <strong>Tipo de sangre: </strong><span [ngClass]="{'error': !this.selectedUser.bloodType?.description}">{{this.selectedUser.bloodType?.description || 'No informado'}} </span>
    </p>
  </div>  
</div>
