<div class="padding-8 container" *ngIf=selectedUser>
  <simple-header title="Información de contacto" [back]="true" icon="close"></simple-header>
  <form [formGroup]="profile" class="padding-top-8 padding-bottom-8">
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Dirección</mat-label>
        <input matInput formControlName="address">
        <mat-error>
            La dirección es obligatoria
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="phone">
        <mat-error>
            El número de teléfono es obligatorio
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Celular</mat-label>
        <input matInput formControlName="mobilePhone">
        <mat-error>
            El número de celular es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="align-center">
      <div class="padding-bottom-8">
        <button (click)="save()" [disabled]="profile.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
      </div>
      <div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </form>
</div>
