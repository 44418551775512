import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'calcEndDate'
})
export class CalcEndDatePipe implements PipeTransform {

  transform(value: any, days): any {
    return moment(value).add(days || 0, 'days');
  }

}
