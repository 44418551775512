<simple-header title="Cambiar foto" (action)="close()" icon="close"></simple-header>
<div class="container" *ngIf="!photo">
  <mat-icon class="icon">image_search</mat-icon>
  <p>Selecciona una foto</p>
  <input-file (selectedFile)="onSelectedImage($event)" accept="image/*" title="Cambiar foto" icon="camera"></input-file>
</div>
<div class="container" *ngIf="photo">
  <image-cropper
      [imageQuality]="100"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      format="jpeg"
      [imageBase64]="photo"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      (imageCropped)="imageCropped($event)"
      [resizeToWidth]="resizeToWidth"
  ></image-cropper>
  <p *ngIf="loadingCropper">Cargando imagen...</p>
  <p *ngIf="!loadingCropper">Encuadra la imagen</p>
</div>

<button [disabled]="loadingCropper || loadingRequest || converting" mat-flat-button color="primary" (click)="changePhoto()" *ngIf="photo" class="button-size-200 center">
  Cambiar foto
</button>
<mat-progress-bar mode="indeterminate" *ngIf="loadingRequest"></mat-progress-bar>
