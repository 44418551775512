// https://timdeschryver.dev/blog/google-maps-as-an-angular-component
import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps'

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  markers = [];;
  center: google.maps.LatLngLiteral
  @ViewChild('map') map: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  infoContent = 'hello!';

  constructor() { }

  ngOnInit(): void {
    this.center = {
      lat: 40.3931136,
      lng: -3.702784,
    };

    this.markers = [{
      position: {
        lat: this.center.lat,
        lng: this.center.lng,
      },
    }];
  }

  centerChanged(event) {
    console.log(this.map?.getCenter().lat());
  }

  clicked(marker: MapMarker, content) {
    this.infoWindow.open(marker)
  }


}
