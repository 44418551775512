import { Injectable } from '@angular/core';
import { on } from '@ngrx/store';
import * as actions from './daily-questionaries.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, throwError } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { StorageService } from '@ildes/services/storage-service.service';

@Injectable()
export class DailyQuestionaryEffects {

  loadDailyQuestionaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadDailyQuestionaries),
      mergeMap(() => {
        let cachedData = this.storageService.getItem('dailyQuestionaries');
        cachedData?.map((questionary) => {
          questionary.date = new Date(questionary.date);
        });
        return of(actions.initState({questionaries: cachedData || []}));
      })
    )
  });

  // fetchData(action) {
  //   const params = {
  //     assigned: action.projectId,
  //     ...action.state && { state: [action.state] }
  //   };
  //   let cachedData = this.storageService.getItem('assignedMaintenances');

  //   if (!cachedData || !cachedData.length) {
  //       return this.apiManager.listMaintenances(params, { error: false, cache: action.options.cache });
  //   }
    
  //   return this.apiManager.listMaintenances(params, { error: true, cache: action.options.cache })
  //    .pipe(
  //     map((data: any) => {
  //       const updatedLocalData = data.data.map( (maintenance) => cachedData.find(({ id, updatedAt, pendingSync, pendingNormalising }) => {
  //         return id === maintenance.id && (updatedAt > maintenance.updatedAt || pendingSync || pendingNormalising);
  //       })  || maintenance);

  //       return { data: updatedLocalData};
  //     }),
  //     catchError((e) => {
  //       return throwError(e);
  //     })
  //     );
  // }
  constructor(
    private actions$: Actions,
    private apiManager: ApiManagerService,
    private storageService: StorageService,
  ) {}
}
