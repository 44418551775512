<google-map #map (centerChanged)="centerChanged($event)" [center]="center" width="100%" height="100%">
  <map-marker
    #somemarker="mapMarker"
    (mapClick)="clicked(somemarker, 'wowo')"
    *ngFor="let marker of markers"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="marker.options"
  >
  </map-marker>
  <map-info-window style="color:black">{{infoContent}}</map-info-window>
</google-map>
