import { Component, OnInit, Inject } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service'
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const CLOSE = { id:'close', icon:'close' };
const ASCEND_METHODS = [
  {
    id:'TWO_SECTION_STAIRCASE',
    description:'Escalera de dos cuerpos'
  },
  {
    id:'CRANE',
    description:'Carro canasta (grúa)'
  },
  {
    id:'STEPLADDER',
    description:'Escalera de tijera'
  },
  {
    id:'HARNESS',
    description:'Pretales'
  },
]

@Component({
  selector: 'app-luminary-searcher-dialog',
  templateUrl: './luminary-searcher-dialog.component.html',
  styleUrls: ['./luminary-searcher-dialog.component.css']
})
export class LuminarySearcherDialogComponent implements OnInit {

  headerActions = [CLOSE];
  projectId;
  maintenance;
  taskData;

  searchLuminaryAdapter;
  ascendMethodOptions = ASCEND_METHODS;

  constructor(
    public dialogRef: MatDialogRef<LuminarySearcherDialogComponent>,
    private apiManager: ApiManagerService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.maintenance = data.maintenance;
  }

  ngOnInit(): void {
    const projectId = this.maintenance.projectId;

    this.searchLuminaryAdapter = (data) => {
      return this.apiManager.listLuminaries({
        number: data.text,
        ...projectId && { projectId }
      }, { error: true, timeout: true }).pipe(
        map(({ data }: any) => ( {data: this.normalize(data)}))
      )
    };
    this.taskData = new UntypedFormGroup({
      luminary: new UntypedFormControl(this.maintenance.luminary ? { typed: this.maintenance.luminary.number, selected: { name: this.maintenance.luminary.number }} : ''),
      height: new UntypedFormControl(this.maintenance.height, Validators.min(1)),
      ascendMethod: new UntypedFormControl(this.maintenance.ascendMethod?.id)
    });
  }

  normalize(data) {
    return data.map((object) => ({...object, ...{ name: object.number }}));
  }

  close(commit) {
    this.dialogRef.close({
      luminary: this.luminary,
      ascendMethod: this.ascendMethodOption,
      height: this.height.value,
      commit
    });
  }

  headerAction() {
    this.dialogRef.close({ commit: false });
  }

  get luminary() {
    return this.taskData.controls.luminary.value.typed;
  }

  get height() {
    return this.taskData.controls.height;
  }

  get ascendMethod() {
    return this.taskData.controls.ascendMethod;
  }

  get ascendMethodOption() {
    return this.ascendMethodOptions.find((option) => 
      this.ascendMethod.value === option.id
    );
  }

}
