import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-create-luminary-dialog',
    template: `
        <simple-header title="Crear luminaria" (action)="close()" icon="close"></simple-header>
        <app-create-luminaires
            class="scrolling"
            [dialogMode]="true"
            [luminary]="luminary"
            [project]="project"
            (saveLuminary)="saveLuminary($event)">
        </app-create-luminaires>`,
    styleUrls: ['./create-luminary-dialog.component.css']
})
export class CreateLuminaryDialogComponent {
    luminary;
    project;
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CreateLuminaryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.luminary = data.luminary;
        this.project = data.project;
    }
    public close() {
        this.dialogRef.close({ closed: true });
    }
    public saveLuminary(data) {
        this.dialogRef.close({ luminary: data });
    }
}
