import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SessionStore } from '@ildes/stores/session.store';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from './assigned-maintenances.actions';
import * as actionsLuminmaries from '../list-luminaries/list-luminaries.actions';
import getAvailableUsedMaterials from '@ildes/utils/calculate-available-materials';
import { SyncDataService } from '@ildes/services/sync-data.service';
import { ImageCacheService } from '@ildes/services/image-cache.service';
import { SelectUsersDialogComponent } from '../../basic/select-users-dialog/select-users-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangeMaterialListDialogComponent } from '@ildes/views/change-material-list-dialog/change-material-list-dialog.component';
import { ListMaintenancesPendingSyncDialogComponent } from '../list-maintenances-pending-sync-dialog/list-maintenances-pending-sync-dialog.component';
import { FingerprintService } from '../../services/fingerprint.service';
import { NativeLayerService } from '@ildes/services/native-layer.service';

const REPAIR_MAINTENANCE = {
  id: 'fixMaintenance',
  description: 'Reparar',
  icon: 'assignment_ind',
  permission: {
    family: 'maintenances',
    actionName: 'repair'
  },
  conditions: [
    {
      key: 'state',
      value: 'CLOSED',
      equal: false
    }
  ]
};

const NAVIGATE = {
  id: 'navigate',
  description: 'Abrir en mapa',
  icon: 'map'
};

const GO_TO_DETAIL = {
  icon: 'close',
  id: 'detail',
  description: 'Ver mantenimiento',
  conditions: [
    {
      key: 'pendingSync',
      value: true,
      equal: false
    },{
      key: 'state',
      value: 'CLOSED',
      equal: true
    }
  ]
};


const ACTIONS = [ REPAIR_MAINTENANCE, GO_TO_DETAIL, NAVIGATE ];

@Component({
  selector: 'app-technician-dashboard-maintenances',
  templateUrl: './technician-dashboard-maintenances.component.html',
  styleUrls: ['./technician-dashboard-maintenances.component.css']
})
export class TechnicianDashboardMaintenancesComponent implements OnInit, OnDestroy {

  errorUpdateUser;
  user;
  loadingConfirm;
  loadingMaintenances;
  maintenances;
  actions = ACTIONS;
  actionsClosed = [];
  state = ['NEW'];
  stats;
  pagination;
  currentPage = 0;
  subscribeSession;
  subscribeMaintenancesState;
  subscribeLuminariesState;
  loading = false;
  availableMaterials;
  pendingSyncMaintenances;
  rawMaintenanceState;
  pendingSyncLuminaries;
  availableBiometry;
  fingerprintEnabled;
  coworker;

  @ViewChild('scrolledContent') scrolledContent;
  

  constructor(
    private zone: NgZone,
    private store: Store<AppState>,
    private router: Router,
    public apiManager: ApiManagerService,
    public syncData: SyncDataService,
    private cacheService: ImageCacheService,
    private dialog: MatDialog,
    private fingerprint: FingerprintService,
    private nativeLayer: NativeLayerService,
  ) {

  }

  private isPendingSync(pendingSync) {
    return (Object.keys(pendingSync || { })).find((key) => {
      return pendingSync[key];
    });
  }

  get fingerprintAlertText() {
    if (!this.availableBiometry) {
      return 'El dispositivo no es compatible con la huella. No podrás firmar documentos con este dispositivo.';
    } else if (!this.fingerprintEnabled) {
      return 'Aun no has activado la huella. Necesitas activar la huella para completar el cierre de los mantenimientos.'
    }

    return '';
  }

  configureGroup() {
    const dialog = this.dialog.open(SelectUsersDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { selected: [{...this.user}], disabled: [{...this.user}], roleId: 'DRIVER'}
    });

    dialog.afterClosed().subscribe( (data) => {
      if (data.close) {
        return;
      }
      const selectedParticipants =  data.participants.filter(({ id }) => id !== this.user.id );

      this.apiManager.setCoworker(this.user.id, {
        coworkers: selectedParticipants.map(({id}) => id),
      }).subscribe((data: any) => {
       SessionStore.updateUserInfo({
          coworkers: selectedParticipants
        });
      });
    });
  }

  async enrollFingerprint() {
    if (!this.nativeLayer.isNative) {
      return;
    }

    await this.fingerprint.enroll();

    this.fingerprintEnabled = true;
  }

  ngOnInit(): void {

    if (this.nativeLayer.isNative) {
      this.fingerprint.isCompatible().then((result) => {
        this.availableBiometry = result;
  
        this.fingerprint.isEnrolled().then((result) => {
          this.fingerprintEnabled = result;
        });
      });
    }

    this.loadingMaintenances = true;
    let extraData = true;

    this.subscribeLuminariesState = this.store.select('luminaries').subscribe((luminaries) => {
      this.pendingSyncLuminaries = luminaries;
    });

    this.store.dispatch(
      actionsLuminmaries.initState()
    );

    this.subscribeSession = SessionStore.getInstance()
      .get$()
      .subscribe((data) => {
        this.user = data?.user;
        this.coworker = this.user?.coworkers?.length;
        if (this.user && this.rawMaintenanceState) {
          this.availableMaterials = getAvailableUsedMaterials(this.user.confirmedMaterials, this.rawMaintenanceState);

          return;
        }
        if (!this.user || this.subscribeMaintenancesState ) {
          return;
        }

        this.subscribeMaintenancesState = this.store.select('assignedMaintenances').subscribe((data) => {
          this.loadingMaintenances = false;
          this.zone.run(() => {
            if (!data) {
              return;
            }
            this.rawMaintenanceState = data;
            this.maintenances = data.filter(({ state }) => 
              this.state?.length > 0 ? this.state.includes(state): true
            );
            this.pendingSyncMaintenances = data.filter(({ pendingSync }) => this.isPendingSync(pendingSync));
            this.availableMaterials = getAvailableUsedMaterials(this.user.confirmedMaterials, data);
          });
        }, () => {
          this.loadingMaintenances = false;
        });
        if (!extraData) {
          extraData = false;
          return;
        }
        this.fetchMaintenances(0, false);
        this.fetchStats();
    });
  }

  fetchStats() {
    this.apiManager.fetchStats({ technician: this.user.id }).subscribe((stats: any) => {
      this.stats = stats.data;
    });
  }

  ngOnDestroy() {
    this.subscribeSession.unsubscribe();
    this.subscribeMaintenancesState.unsubscribe();
  }

  private fetchMaintenances(pageIndex = 0, cache = true) {
    this.loadingMaintenances = true;
    this.maintenances = null;
    this.store.dispatch(
      actions.loadMaintenances({
        assigned: this.user.id,
        state: undefined,
        options: { cache }
      })
    )
  }

  navigate(maintenance) {
    if (maintenance.state !== 'CLOSED') {
      this.router.navigate(['/app/repair-maintenance'], { queryParams: { id: maintenance.id } });
    } else {
      this.router.navigate(['/app/detail-maintenance'], { queryParams: { id: maintenance.id } });
    }
  }

  doAction(maintenance, action) {
    switch(action.id) {
      case REPAIR_MAINTENANCE.id:
        this.router.navigate(['/app/repair-maintenance'], { queryParams: { id: maintenance.id } });
      break;
      case GO_TO_DETAIL.id: 
        this.router.navigate(['/app/detail-maintenance'], { queryParams: { id: maintenance.id } });
      break;
    }
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  getBase64FromCache(photo) {
    return new Promise(async (resolve) => {
      const response = await this.cacheService.get(photo);
      const buffer = await response.blob();
      const base64 = await this.blobToBase64(buffer)

      resolve(base64);
    })
  }

  performPhotos(photos) {
    return Promise.all(photos.map(async(photo) => {
      if (!photo.includes('/base64/')) {
        return photo;
      }
      const buffer = await this.cacheService.get(photo).then((response)=>response.blob());
      const base64 = await this.blobToBase64(buffer);

      return base64;
    }));
  }

  confirm() {
    this.loadingConfirm = true;
    this.apiManager.confirmMaterial(this.user.id, { assignedMaterials: this.user.assignedMaterials }).subscribe(() => {
      this.updateUser().subscribe(() => this.loadingConfirm = false );
    }, () => {
      this.loadingConfirm = false;
    });
  }

  updateUser() {
    const callUserDetail = this.apiManager.getUserDetail(this.user.id);

    callUserDetail.subscribe((data: any) => {
      SessionStore.updateUserInfo({
        confirmedMaterials: data.confirmedMaterials,
        assignedMaterials: data.assignedMaterials,
        returnedMaterials: data.returnedMaterials
      });
      this.errorUpdateUser = false;
    }, () => {
      this.errorUpdateUser = true;
    });

    return callUserDetail;
  }

  setMaintenanceFilter(event) {
    this.state = event.value ? event.value.split(','): '';
    this.fetchMaintenances();
  }

  paginate(event) {
    this.scrolledContent.nativeElement.scrollTop = 192;
    this.fetchMaintenances(event.pageIndex);
  }

  fetchUser() {
    this.apiManager.getUserDetail(this.user.id).subscribe((data: any) => {
      SessionStore.updateUserInfo(data, true);
    });
  }

  refresh() {
    this.fetchMaintenances(0, false);
    this.fetchStats();
    this.fetchUser();
  }

  openReturnToStoreDialog() {
    const dialog = this.dialog.open(ChangeMaterialListDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { availableMaterials: this.availableMaterials }
    });

    dialog.afterClosed().subscribe( (data) => {
      if (data.close) {
        return;
      }
      this.apiManager.returnToStore(this.user.id, { store: data.selectedStore, materials: data.selectedMaterials }).subscribe(() => {
        this.loading = true;
        this.updateUser().subscribe(() => this.loading = false, () => this.loading = false );
      });
      console.log(data);
    });
  }

  public openPendingSyncMaintenancesDialog() {
    const dialog = this.dialog.open(ListMaintenancesPendingSyncDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { maintenances: this.pendingSyncMaintenances, luminaries: this.pendingSyncLuminaries }
    });

    dialog.afterClosed().subscribe((data) => {
      if (data.close) {
        return;
      }

      if (data.selectedMaintenance) {
        this.router.navigate(['/app/repair-maintenance'], { queryParams: { id: data.selectedMaintenance.id } });
      } else if (data.selectedLuminary) {
        if (data.selectedLuminary.id) {
          this.router.navigate(['/app/luminary'], { queryParams: { id: data.selectedLuminary.id} });
        } else {
          this.router.navigate(['app/list-luminaries/create'], { state: data.selectedLuminary });
        }
      }
    })
  }

  get numElementsPendingSync() {
    return this.pendingSyncMaintenances?.length + this.pendingSyncLuminaries?.length;
  }
}
