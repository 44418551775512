<div class="container">
    <div class="scrollable-content">
      <simple-header [title]="title" [back]="true" icon="close"></simple-header>
      <div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="padding-top-8">
        <p *ngIf="task?.state!=='COMPLETED' && !usersPendingToSign" >
          Se ha generado la siguiente documentación.
        </p>
        <p *ngIf="task?.state!=='COMPLETED' && !usersPendingToSign">
          Lea detenidamente los documentos antes de firmar.
        </p>
        <div class="padding-top-8 padding-bottom-8" *ngIf="usersPendingToSign">
          <app-alert-dialog text="Documentación firmada correctamente." status="ok"></app-alert-dialog>
        </div>
        <div class="padding-top-8 padding-bottom-8" *ngIf="usersPendingToSign">
          <app-alert-dialog text="Para cerrar la tarea, el resto de miembros tienen que cerrarla también. Podrán cerrarla desde su buzón." status="advise"></app-alert-dialog>
        </div>
        <p *ngIf="task?.state!=='COMPLETED' && !usersPendingToSign">
          Al pulsar sobre el botón de Firmar, acepta la conformidad con lo expuesto en dichos documentos y estos quedarán firmados por su certificado digital emitido por ildes.co
        </p>
        <p *ngIf="usersPendingToSign">
          Si lo desea, puede volver a consultar la documentación pulsando sobre el icono del archivo correspondiente.
        </p>
        <div class="attachment-list" *ngIf="!signin && !downloading">
          <div *ngFor="let fileName of attachments"  class="actionale attachment" matRipple (click)="open(fileName)">
            <div>
              <mat-icon>picture_as_pdf</mat-icon>
            </div>
            <div class="attachment-name">{{fileName}}</div>
          </div>
        </div>
        <app-alert-dialog
          (click)="enrollFingerprint()"
          *ngIf="!loading && (!availableBiometry || !fingerprintEnabled)"
          [text]="fingerprintAlertText"
          [linkText]="availableBiometry && !fingerprintEnabled ? 'Activar huella': undefined"
          status="advise"></app-alert-dialog>
          <section *ngIf="fingerprintEnabled">
            <div class="footer" *ngIf="task?.state!=='COMPLETED' && !usersPendingToSign">
              <button *ngIf="!signin && !downloading" mat-raised-button color="primary" class="button-size-200" (click)="sign()">Firmar</button>
              <div *ngIf="signin || downloading">
                <app-loading diameter="30"></app-loading> {{signin ? "Firmando": downloading ? "descargando": ""}}
              </div>
            </div>
        </section>
      </div>
    </div>
  </div>
  