import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../services/api-manager.service';

@Component({
  selector: 'app-create-asoc-material',
  templateUrl: './create-asoc-material.component.html',
  styleUrls: ['./create-asoc-material.component.css']
})
export class CreateAsocMaterialComponent implements OnInit {

  loading;
  pagination;
  searchLuminaryTypesAdapter;
  materialForm = new UntypedFormGroup({
    technologyForm: new UntypedFormControl(),
    power: new UntypedFormControl('', [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))])
  });

  possibleMaterialBk;

  possibleMaterial = [

  ];

  selectedMaterial = [

  ];


  get technology() {
    return this.materialForm.get('technologyForm')?.value?.typed;
  }

  get power() {
    return this.materialForm.get('power')?.value;
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      event.previousContainer.data[event.previousIndex].amount = 1;

      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  constructor(
    public apiManager: ApiManagerService,
  ) { }

  ngOnInit(): void {
    this.searchLuminaryTypesAdapter = (data) => {
      return this.apiManager.getFromCatalog('technologies', data)
    };
    this.loading = true;
    this.apiManager.listMaterials().subscribe((materialData: any) => {
      this.possibleMaterial = materialData.data.map(({ unit, id, name }) => ({
        unit,
        id,
        name
      }));
      this.loading = false;
      this.possibleMaterialBk = [...this.possibleMaterial];
      this.pagination = materialData.pagination;
    }, () => {
      this.loading = false;
    });
  }

  get invalidForm() {
    return this.materialForm.invalid || this.selectedMaterial.length === 0;
  }

  save() {
    this.loading = true;
    const data = {
      materials: this.selectedMaterial,
      technology: this.technology,
      power: this.power,
    };

    this.apiManager.createAsocMaterial(data).subscribe(() => {
      this.materialForm.controls.technologyForm.setValue(null);
      this.materialForm.controls.power.setValue(null);
      this.materialForm.controls.technologyForm.markAsPristine();
      this.materialForm.controls.power.markAsPristine();
      this.materialForm.controls.power.markAsUntouched();
      this.selectedMaterial = [];
      this.possibleMaterial = [...this.possibleMaterialBk];

      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }


}
