import { Component } from '@angular/core';
import { Router } from '@angular/router';
import projectDailyQuestionary from '../repair-maintenance/questions-high-permission';
import { AppState } from '@ildes/app.reducer';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { UnsyncExpansionsService } from '@ildes/services/unsync-expansions.service';
import { Store } from '@ngrx/store';
import { Observable, concat, defer } from 'rxjs';
import {map, tap } from 'rxjs/operators';
import * as actionsQuestionaries from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.actions';
import { SessionStore } from '@ildes/stores/session.store';

const DISCARD_CHANGES = {
    id: 'discard',
    description: 'Descartar',
    icon: 'trash'
  };
function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) =>  Observable<T> {
    return function inner(source: Observable<T>): Observable<T> {
        return defer(() => {
            onSubscribe();
            return source;
        });
    };
}
@Component({
    selector: 'app-technician-dashboard-expansions',
    template: `
        <section *ngIf="pendingSyncDailyQuestionaries?.length && unsyncExpansionList?.length">
            <h3>Existen cuestionarios diarios sin guardar</h3>
            <mat-progress-bar mode="indeterminate" *ngIf="pendingQuestionaryRequest"></mat-progress-bar>
            <app-alert-dialog
                *ngIf="!pendingQuestionaryRequest"
                text="Al guardar todo también se guardarán los cuestionarios diarios."
                status="advise"></app-alert-dialog>
        </section>
        <section *ngIf="unsyncExpansionList?.length">
            <h3>Expansiones pendientes de guardar</h3>
            <div *ngFor="let unsyncExpansion of unsyncExpansionList" class="item">
                <list-luminary-item
                    (onActionClick)="!pendingRequest && onActionClick(unsyncExpansion, $event)"
                    (onLinkClick)="!pendingRequest && selectExpansion(unsyncExpansion)"
                    [actions]="actionsExpansions"
                    [number]="unsyncExpansion.luminary ? unsyncExpansion.luminary.number: 'Pendiente crear luminaria'"
                    [project]="unsyncExpansion.selectedProject?.name || unsyncExpansion.luminary.projectName || 'Sin proyecto'"
                    [address]="unsyncExpansion.luminary?.address"
                    [ubication]="unsyncExpansion.luminary?.geolocation"
                    [neighborhood]="unsyncExpansion.luminary?.neighborhood"
                    [technology]="unsyncExpansion.luminary?.technology"
                    [power]="unsyncExpansion.luminary?.power"
                ></list-luminary-item>
                <mat-progress-bar mode="indeterminate" *ngIf="loading[unsyncExpansion.id]"></mat-progress-bar>
                <div class="padding-bottom-8">
                    <i>
                        <span *ngIf="unsyncExpansion.closeDate" class="ok"> Pendiente de sincronizar </span>
                        <span *ngIf="!unsyncExpansion.closeDate"  class="advise"> Pendiente de completar el proceso </span>
                    </i>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="align-center padding-top-8" *ngIf="hasPendingSync">
                <button [disabled]="pendingRequest" mat-button (click)="sync()" class="button-size-200">
                    <mat-icon class="icon-button">cloud_upload</mat-icon>Guardar todo
                </button>
            </div>
        </section>
        <div *ngIf="!unsyncExpansionList?.length">
            <app-image-panel text="No hay expansiones pendientes de sinconizar" class="margin-top-16" ></app-image-panel>
        </div>
        <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/create-expansion']">+</button>
    `,
    styleUrls: ['./technician-dashboard-expansions.component.css'],
})
export class TechnicianDashboardExpansionsComponent {
    unsyncExpansionList;
    loading = {};
    loadingQuestionary = {};
    actionsExpansions = [
        DISCARD_CHANGES
    ];
    error = false;
    dailyQuestionaries;
    constructor(
        private store: Store<AppState>,
        private apiManager: ApiManagerService,
        private router: Router,
        private unsyncExpansions: UnsyncExpansionsService
    ) {
        this.unsyncExpansionList = this.unsyncExpansions.getItems();
        
        this.store.dispatch(
            actionsQuestionaries.loadDailyQuestionaries()
        );

        this.store.select('dailyQuestionaries').subscribe((data) => {
            this.dailyQuestionaries = data.filter(({ pendingSync }) => pendingSync);
        });
    }

    get pendingQuestionaryRequest() {
        return Object.values(this.loadingQuestionary).some((value) => value );
    }
    get pendingRequest() {
        return Object.values(this.loading).some((value) => value );
    }

    onActionClick(expansion, event) {
        switch(event.id) {
          case DISCARD_CHANGES.id:
            this.unsyncExpansionList = this.unsyncExpansions.removeItem(expansion);
            
            break;
          default:
            break;
        }
      }

    selectExpansion(epxansion) {
        this.router.navigate(['/app/create-expansion'], { queryParams: { id: epxansion.id} })   
    }

    get hasPendingSync() {
        return !!this.unsyncExpansionList?.filter(( {closeDate } ) => closeDate).length;
    }

    get pendingSyncDailyQuestionaries() {
        return this.dailyQuestionaries?.filter(({ pendingSync })=>pendingSync);
    }

    sync() {
        this.error = false;
        const requestsLuminaries = this.unsyncExpansionList.filter(({closeDate}) => closeDate).map((expansion) => {  
            return this.unsyncExpansions.sync(expansion).pipe(
                doOnSubscribe(() => {
                    this.loading[expansion.id] = true;
                }),
                tap(() => ({
                    next: this.loading[expansion.id] = false
                })), 
                map(() => {
                    return expansion;
                })
            )
        });

        const requestQuestionaries = [...this.pendingSyncDailyQuestionaries].map((questionary) => {  
            const key = `${questionary.projectId}_${questionary.date}`;

            return this.syncDailyQuestionary(questionary).pipe(
                doOnSubscribe(() => {
                    this.loadingQuestionary[key] = true;
                }),
                tap(() => ({
                    next: this.loadingQuestionary[key] = false
                })), 
                map(() => {
                    return questionary;
                })
            )
        });
        const userId = SessionStore.getInstance().get().user.id;

        concat(...[...requestQuestionaries, ...requestsLuminaries]).subscribe(() => {
            this.unsyncExpansionList = this.unsyncExpansions.getItems();

            if (this.unsyncExpansionList?.length) {
                return;
            }
            this.apiManager.getUserDetail(userId).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
            });
          }, (error) => {
            this.loading = {};
            this.apiManager.getUserDetail(userId).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
            });
            if (error && error.code <= 0) {
              return;
            }
            this.error = true;
          });

    }

    private syncDailyQuestionary(dailyQuestionary) {
        const projectId = dailyQuestionary.projectId;
        const questionaryDate = dailyQuestionary.date;

        return this.apiManager.updateDailyQuestionary(projectId, {
            questions: projectDailyQuestionary,
            answers: dailyQuestionary.answers,
            date: questionaryDate,
        }).pipe(
            tap(() => {
                this.store.dispatch(
                    actionsQuestionaries.update({
                        changes: { pendingSync: false },
                        date: new Date(questionaryDate),
                        projectId: projectId,
                    })
                );
            })
        )
    }
}
