import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { ApiManagerService } from '../../services/api-manager.service';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

const UNITS = [
  {
    id: 'UNITS',
    description: 'Unidades'
  },
  {
    id: 'METERS',
    description: 'Metros'
  },
];
@Component({
  selector: 'app-create-material',
  templateUrl: './create-material.component.html',
  styleUrls: ['./create-material.component.css']
})
export class CreateMaterialComponent implements OnInit {

  materialForm;
  loading;
  unitTypes = UNITS;
  unit = UNITS[0];
  filteredCategories;
  categoryCtrl = new FormControl('');
  categories = [];
  allCategories = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;

  constructor(
    private apiManager: ApiManagerService
  ) {
    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      map((category: string | null) => (category ? this._filter(category) : this.allCategories.slice())),
    );
  }

  ngOnInit(): void {
    this.resetForm();
  }

  save() {
    this.loading = true;
    this.apiManager.createMaterial({
      unit: this.unit,
      name: this.materialForm.get('name').value,
      categories: this.categories || [],
    }).subscribe(() => {
      this.resetForm();
      this.loading = false;
    }, (data) => {
      this.loading = false;
    })
  }

  private resetForm() {
    this.unit = UNITS[0];

    this.materialForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required])
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our category
    if (value) {
      this.categories.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.categoryCtrl.setValue(null);
  }

  remove(category: string): void {
    const index = this.categories.indexOf(category);

    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.categories.push(event.option.viewValue);
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCategories.filter(category => category.toLowerCase().includes(filterValue));
  }

}
