import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiManagerService } from '../../services/api-manager.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SessionStore } from '../../stores/session.store';
import { PhotosUtils } from '@ildes/utils/photos';

@Component({
  selector: 'app-change-photo-dialog',
  templateUrl: './change-photo-dialog.component.html',
  styleUrls: ['./change-photo-dialog.component.css']
})
export class ChangePhotoDialogComponent implements OnInit {

  reader;
  photo;
  loadingCropper;
  croppedImage;
  loadingRequest;
  aspectRatio = 1;
  resizeToWidth = 200;
  userId;
  converting = false;
  private photosHandler: PhotosUtils;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangePhotoDialogComponent>,
    public apiManager: ApiManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    
  ) {
    this.userId = data.id;
    this.photosHandler = new PhotosUtils();
  }

  ngOnInit(): void {
    this.reader = new FileReader();

    const reader = this.reader;

    reader.onload = () => {
      this.loadingCropper = true;
      this.photo = reader.result
    };
    // reader.onerror = () => this.errorSelectedFile.emit();
  }

  onSelectedImage(file) {
    this.reader.readAsDataURL(file);
  }

  onErrorSelectedImage() {
    console.log('error!');
    /*TODO: controlar error al cargar imagen*/
  }

  changePhoto() {
    this.loadingRequest = true;
    const sessionStore = SessionStore.getInstance();
    const userId = sessionStore.get().user.id;

    this.apiManager.changePhoto(this.userId, { photo: this.croppedImage }).subscribe((data: any) => {
      this.loadingRequest = false;

      if (userId === this.userId) {
        SessionStore.updateUserInfo({
          photo: data.photo
        });
      }

      this.dialogRef.close();
    }, () => {
      this.loadingRequest = false
    });
  }

  async imageCropped(event: ImageCroppedEvent) {
    this.converting = true;
    try {
      this.croppedImage = await this.photosHandler.blobToBase64(event.blob);
    } catch { }
    
    this.converting = false;
  }

  imageLoaded() {

  }

  cropperReady() {
    this.loadingCropper = false
      // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  close() {
    this.dialogRef.close({ closed: true });
  }


}
