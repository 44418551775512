<div class="container">
  <div class="scrollable-content">
    <simple-header title="Materiales" [back]="true" icon="close"></simple-header>
    <!-- <mat-form-field appearance="outline" class="full-width">
      <mat-label>Buscar por nombre</mat-label>
      <input matInput>
      <mat-error>
          El nombre es obligatorio
      </mat-error>
    </mat-form-field> -->
    <mat-progress-bar class="margin-top-16" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div *ngFor="let material of materials" class="item">
      <list-material-item
        [name]="material.name"
        [unitDescription]="material.unit.description"
        class="padding-top-8"
        [actions]="actions"
        (actionEvent)="onClickAction($event, material)"
      >
      </list-material-item>
      <mat-divider></mat-divider>
    </div>
    <button mat-fab color="primary" class="fab-button" [routerLink]="['/app/material/create']" [allowAction]="['materials.create']">+</button>
  </div>
  <mat-paginator
    [length]="pagination?.totalDocs"
    pageSize="20"
    (page)="paginate($event)">
  </mat-paginator>
</div>
