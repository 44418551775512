import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-panel',
  templateUrl: './image-panel.component.html',
  styleUrls: ['./image-panel.component.css']
})
export class ImagePanelComponent implements OnInit {

  @Input() emoji = 'mood_bad';
  @Input() icon = 'mood_bad';
  @Input() text = 'No hay nada para mostrar aquí.';
  constructor() { }

  ngOnInit(): void {
  }

}
