import { Component, OnInit, Inject } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service'
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CreateLuminaryDialogComponent } from '../create-luminary-dialog/create-luminary-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const CLOSE = { id:'close', icon:'close' };
const ASCEND_METHODS = [
  {
    id:'TWO_SECTION_STAIRCASE',
    description:'Escalera de dos cuerpos'
  },
  {
    id:'CRANE',
    description:'Carro canasta (grúa)'
  },
  {
    id:'STEPLADDER',
    description:'Escalera de tijera'
  },
  {
    id:'HARNESS',
    description:'Pretales'
  },
]
const DISCARD_CHANGES = {
  id: 'discard',
  description: 'Descartar',
  icon: 'trash'
};
@Component({
  selector: 'app-luminary-searcher-dialog',
  template: `
  <simple-header (action)="headerAction()" [actions]="headerActions" title="Identificación de la labor" icon="close"></simple-header>
  <mat-vertical-stepper [linear]="true" #stepper>
    <form [formGroup]="taskData" autocomplete="off">
    <mat-step [completed]="!!selectedProject" *ngIf="requiredProject">
      <ng-template matStepLabel>Indique el proyecto</ng-template>
      <div class="inline-flex padding-top-8">
        <predictive-searcher
            formControlName="selectedProject"
            label="Proyecto"
            [service]="searchProjectAdapter"
            class="column margin-right-8"></predictive-searcher>
      </div>
      <button [disabled]="!selectedProject" mat-button matStepperNext color="primary">Siguiente</button>
    </mat-step>
    <mat-step [completed]="!!selectedLuminary?.power" *ngIf="requiredLuminary">
      <ng-template matStepLabel>Indique el número de luminaria</ng-template>
      <section *ngIf="!selectedLuminary?.power || selectedLuminary?.id">
        <div class="inline-flex padding-top-8">
          <predictive-searcher
            class="column"
            formControlName="luminary"
            label="Número de luminaria"
            [service]="searchLuminaryAdapter"></predictive-searcher>
        </div>
        <div class="flex-row">
            <button class="right" [disabled]="selectedLuminary?.power" (click)="openCreateLuminaryDialog()"  mat-button>Crear luminaria</button>
        </div>
      </section>
      <list-luminary-item
          [actions]="actions"
          *ngIf="!!selectedLuminary?.power && !selectedLuminary?.id"
          (onActionClick)="discardLuminary()"
          (onLinkClick)="editLuminary()"
          [number]="selectedLuminary.number"
          [project]="selectedProject?.name || 'Sin proyecto'"
          [address]="selectedLuminary.address"
          [ubication]="selectedLuminary.geolocation"
          [neighborhood]="selectedLuminary.neighborhood"
          [technology]="selectedLuminary.technology"
          [power]="selectedLuminary.power"
      ></list-luminary-item>
      <button [disabled]="!selectedLuminary?.power" mat-button matStepperNext color="primary">Siguiente</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Indique la altura aproximada</ng-template>   
        <mat-form-field appearance="outline" class="full-width padding-top-8">
          <mat-label>Altura de la luminaria</mat-label>
          <input required type="number" matInput formControlName="height">
        </mat-form-field>
        <button [disabled]="selectedHeight.invalid" matStepperNext mat-button matStepperNext color="primary">Siguiente</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Seleccione el sistema de ascenso</ng-template>
      <mat-form-field appearance="outline" class="full-width padding-top-8">
        <mat-label>Sistema de ascenso</mat-label>
        <mat-select required formControlName="ascendMethod">
          <mat-option *ngFor="let option of ascendMethodOptions" [value]="option.id">
            {{option.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button [disabled]="selectedAscendMethod.invalid" matStepperNext mat-button matStepperNext color="primary">Siguiente</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Confirme los datos antes de continuar</ng-template>
      <key-value-item *ngIf="requiredProject" key="Proyecto" [value]="selectedProject?.name"></key-value-item>
      <key-value-item *ngIf="requiredLuminary" key="Luminaria" [value]="selectedLuminary?.name"></key-value-item>
      <key-value-item key="Altura" [value]="selectedHeight.value"></key-value-item>
      <key-value-item key="Sistema de ascenso" [value]="selectedAscendMethodOption?.description"></key-value-item>
      <div class="align-center padding-top-8">
        <button mat-raised-button color="primary" (click)="close(true)" class="button-size-200">Aceptar</button>
      </div>  
    </mat-step>
    </form>
    
  </mat-vertical-stepper>
  `,
  styleUrls: ['./task-identification-dialog.component.css']
})
export class TaskIdentificationDialogComponent implements OnInit {
  actions = [
    DISCARD_CHANGES
  ];
  requiredLuminary;
  requiredProject;
  headerActions = [CLOSE];
  projectId;
  maintenance;
  taskData;
  searchProjectAdapter;
  searchLuminaryAdapter;
  ascendMethodOptions = ASCEND_METHODS;

  luminary;
  luminaryData;
  height;
  ascendMethod;
  project;


  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TaskIdentificationDialogComponent>,
    private apiManager: ApiManagerService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.projectId = data.projectId;
    this.luminary = data.luminary;
    this.luminaryData = data.luminary;
    this.height = data.height;
    this.ascendMethod = data.ascendMethod;
    this.project = data.project;
    this.requiredLuminary = data.requiredLuminary;
    this.requiredProject = data.requiredProject;
  }

  ngOnInit(): void {
    if (this.requiredLuminary) {
      const projectId = this.projectId ;
      this.searchLuminaryAdapter = (data) => {
        return this.apiManager.listLuminaries({
          number: data.text,
          ...projectId && { projectId }
        }, { error: true, timeout: true }).pipe(
          map(({ data }: any) => ( {data: this.normalize(data)}))
        )
      };
    }
    
    if (this.requiredProject) {
      this.searchProjectAdapter = (data) => {
        return this.apiManager.listProjects(data);
      };
    }

    this.taskData = new UntypedFormGroup({
      luminary: new UntypedFormControl(this.luminary ? { typed: this.luminary.number, selected: { number: this.luminary.number }} : ''),
      height: new UntypedFormControl(this.height, Validators.min(1)),
      ascendMethod: new UntypedFormControl(this.ascendMethod?.id),
      selectedProject: new UntypedFormControl(this.project?.id ? { typed: this.project?.name, selected: this.project }: ''),
    });
  }

  get projectTyped() {
    return this.taskData.get('selectedProject')?.value?.typed;
  }

  public get selectedProject() {
      if (this.projectTyped) {
          return this.taskData.get('selectedProject')?.value?.selected || { name: this.projectTyped};
      }

      return undefined;
  }

  get luminaryTyped() {
    return this.taskData.get('luminary')?.value?.typed;
  }


  get selectedLuminary() {
    if (this.luminaryData && this.luminaryData.number === this.luminaryTyped) {
      return this.luminaryData;
    }
    if (this.luminaryTyped) {
      return this.taskData.get('luminary')?.value?.selected || { number: this.luminaryTyped};
    }

    return undefined;
  }

  
  normalize(data) {
    return data.map((object) => ({...object, ...{ name: object.number }}));
  }

  close(commit) {
    this.dialogRef.close({
      luminary:  this.selectedLuminary,
      ascendMethod: this.selectedAscendMethodOption,
      height: this.selectedHeight.value,
      project: this.selectedProject,
      commit
    });
  }

  headerAction() {
    this.dialogRef.close({ commit: false });
  }


  get selectedHeight() {
    return this.taskData.controls.height;
  }

  get selectedAscendMethod() {
    return this.taskData.controls.ascendMethod;
  }

  get selectedAscendMethodOption() {
    return this.ascendMethodOptions.find((option) => 
      this.selectedAscendMethod.value === option.id
    );
  }

  editLuminary() {
    if (this.selectedLuminary.id) {
      return;
    }

    this.openCreateLuminaryDialog();
  }
  openCreateLuminaryDialog() {
    
    const dialog = this.dialog.open(CreateLuminaryDialogComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog',
        data: {
            luminary: this.selectedLuminary,
            project: this.selectedProject,
        }
    });

    dialog.afterClosed().subscribe(async (data) => {
        if (data.closed) {
            return;
        }
        this.luminaryData = data.luminary;
    });
  }
  discardLuminary() {
    this.luminaryData = null;
  }

}
