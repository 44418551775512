<form [formGroup]="formGroup" autocomplete="off">
  <div class="inline-flex full-width">
    <div class="margin-right-8 column">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{label}}</mat-label>
        <input
          formControlName="typed"
          [matAutocomplete]="auto"
          autocomplete="off"
          (blur)="onblur()"
          #inputMap
          (keyup)="_getAutocompletePlaces($event.target.value)"
          [value]="address"
          matInput
          placeholder="{{placeholder}}">
          <mat-error>
            Debes seleccionar una ubicación válida
          </mat-error>
        <button mat-icon-button matSuffix (click)="onIconClick()" >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #select #auto="matAutocomplete" [displayWith]="displayWith">
          <mat-option *ngFor="let place of places" [value]="place">
            {{place.description}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div (click)="setPosition()" class="padding-top-16 actionable">
      <mat-icon>place</mat-icon>
    </div>
  </div>
  <div class="inline-flex full-width" *ngIf="!hideGeopositionDetail">
    <mat-form-field  appearance="outline" class="column margin-right-column-8">
      <mat-label>Latitud</mat-label>
      <input
        formControlName="lat"
        matInput
        placeholder="37.4117554"
        >
    </mat-form-field>
    <mat-form-field  appearance="outline" class="column">
      <mat-label>Longitud</mat-label>
      <input
        formControlName="lng"
        matInput
        placeholder="-1.5827678"
        >
    </mat-form-field>
  </div>
  <mat-progress-bar *ngIf="loadingGeolocation" mode="indeterminate"></mat-progress-bar>
</form>
