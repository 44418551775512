import { Action, createReducer, State, on  } from '@ngrx/store';
import * as actions from './maintenances.actions';

// export function maintenancesReducer(state: Map<any> = new Map(), action: Action) {
//   const newState = new Map(state);
//
//   switch (action.type) {
//     case actions.selectMaintenance.type:
//       newState.set(action.maintenance.id, {...action.maintenance});
//
//       return newState;
//     case actions.unselectMaintenance.type:
//       newState.delete(action.maintenance.id);
//
//       return newState;
//     default:
//       return newState;
//
//   }
// }

const selectMaintenance = (state: Map<string,any>, action: any) => {
  const newState = new Map(state);

  newState.set(action.maintenance.id, {...action.maintenance});

  return newState;
}

const unselectMaintenance = (state: Map<string,any>, action: any) => {
  const newState = new Map(state);

  newState.delete(action.maintenance.id);

  return newState;
}

const resetSelectedMaintenances = (state: Map<string,any>) => {
  return new Map();
}
const initialState: Map<string, any> = new Map()

const maintenancesReducer = createReducer(
  initialState,
  on(actions.selectMaintenance, selectMaintenance),
  on(actions.unselectMaintenance, unselectMaintenance),
  on(actions.resetSelectedMaintenances, resetSelectedMaintenances)
);

export function reducer(state: Map<string,any> | undefined, action: Action) {
  return maintenancesReducer(state, action);
}
