<connection-alert></connection-alert>
<div class="container">
  <div class="scrollable-content">
    <simple-header title="Pendiente de Sincronizar" (click)="closeDialog()" icon="close"></simple-header>
    <app-image-panel icon="cloud_done" text="Todos los datos están sincronizados" class="margin-top-16" *ngIf="!maintenances?.length && !luminaries?.length"></app-image-panel>
    <section *ngIf="luminaries?.length">
      <h3>Luminarias no sincronizadas</h3>
      <div *ngFor="let luminary of luminaries" class="item">
        <list-luminary-item
          (onActionClick)="!pendingRequest && onActionClick(luminary, $event)"
          (onLinkClick)="!pendingRequest && selectLuminary(luminary)"
          [actions]="actionsLuminary"
          [number]="luminary.number"
          [project]="luminary.project?.name || luminary.projectName || 'Sin proyeco'"
          [address]="luminary.address"
          [ubication]="luminary.geolocation"
          [neighborhood]="luminary.neighborhood"
          [technology]="luminary.technology"
          [power]="luminary.power"
        ></list-luminary-item>
        <mat-progress-bar mode="indeterminate" *ngIf="loading[luminary.number]"></mat-progress-bar>
        <mat-divider></mat-divider>
      </div>
    </section>
    <section *ngIf="maintenances?.length">
      <h3>Mantenimientos no sincronizados</h3>
      <div *ngFor="let maintenance of maintenances" class="item">
        <list-maintenance-item
          [parent]="maintenance.parent"
          (onLinkClick)="!pendingRequest && selectMaintenance(maintenance)"
          [state]="maintenance.state"
          [type]="maintenance.type"
          [luminaryNumber]="maintenance.luminary?.number"
          [project]="maintenance.project?.name || 'Sin proyecto'"
          [address]="maintenance.address"
          [neighborhood]="maintenance.neighborhood"
          [referencePoint]="maintenance.locationReferenceDescription"
          [painType]="maintenance.painType"
          [painDescription]="maintenance.painDescription"
          [technician]="maintenance.technician"
          [participants]="maintenance.participants"
          [power]="maintenance.luminary?.power"
          [technology]="maintenance.luminary?.technology"
          [estimatedResolutionDate]="maintenance.estimatedResolutionDate"
          [repairmentDate]="maintenance.repairmentDate"
          [materials]="maintenance.materials"
        ></list-maintenance-item>
        <mat-progress-bar mode="indeterminate" *ngIf="loading[maintenance.id]"></mat-progress-bar>
        <mat-divider></mat-divider>
      </div>
    </section>
    
    <div class="padding-top-8 padding-bottom-8" *ngIf="error">
      <app-alert-dialog
        text="Tienes que hacer ajustes en el primer elemento para poder sincronizar la información. Para ello, pulsa en ese elemento para navegar al detalle y corregir el problema."
        status="advise"></app-alert-dialog>
    </div>
    <div class="align-center padding-top-8" *ngIf="!error && (maintenances?.length || luminaries?.length)">
      <button [disabled]="pendingRequest" mat-button (click)="sync()" class="button-size-200">
        <mat-icon class="icon-button">cloud_upload</mat-icon>Sincronizar todo
      </button>
    </div>
</div>
