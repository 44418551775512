<h1>Ajustes</h1>
<div *ngIf="!showFormChangeProject" class="padding-bottom-8 padding-top-8 action-element-container">
  <span class="action-text-title">
    <strong>Proyecto seleccionado: </strong><span> <i>{{selectedProject?.name || 'Ninguno'}}</i></span>
  </span>
  <div matRipple role="button" class="actionable action-button" (click)="showFormChangeProject = true">Cambiar</div>
  <mat-divider></mat-divider>
</div>
<form *ngIf="showFormChangeProject" [formGroup]="searchFormGroup" (keydown.enter)="$event.preventDefault()">
  <predictive-searcher
    formControlName="selectedProjectForm"
    label="Seleccionar proyecto"
    [service]="searchProjectAdapter"
    (selectedValue)="onSelectedValue($event)"
    class="column margin-right-8"></predictive-searcher>
</form>
