<div class="container">
    <simple-header title="Asignar a técnico" [back]="true" icon="close" class="padding-8 background"></simple-header>
    <div class="scrollable-content">
    <div class="section">
      <h3>Mantenimientos seleccionados ({{selectedMaintenances.length}})</h3>
      <div *ngFor="let maintenance of selectedMaintenances" class="snap-align padding-8">
        <list-maintenance-item
          [type]="maintenance.type"
          [technician]="maintenance.technician"
          [luminaryNumber]="maintenance.luminary?.number"
          [project]="maintenance.project?.name || 'Sin proyecto'"
          [address]="maintenance.address"
          [neighborhood]="maintenance.neighborhood"
          [referencePoint]="maintenance.locationReferenceDescription"
        ></list-maintenance-item>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="section margin-top-8">
      <h3 class="padding-top-16">Pulsa en un técnico para asignar</h3>
      <div class="">
        <div *ngFor="let technical of technicals" class="item">
          <list-user-item
            (onLinkClick)="onSelectTechnical(technical)"
            [name]="technical.name"
            [surname]="technical.surname"
            [role]="technical.role?.description"
            [email]="technical.email"
            [phone]="technical.phone"
            [photo]="technical.photo"
            class="padding-top-8">
          </list-user-item>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</div>
