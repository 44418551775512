import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanizeDescriptionMaterial'
})
export class HumanizeDescriptionMaterialPipe implements PipeTransform {

  private humainceCuantifier(unit) {
    switch (unit?.id?.toLowerCase()) {
      case 'meters':
        return ' m.'
        break;

      default:
        return 'x'
        break;
    }
  }

  transform(value: any, prefixedByAmount: Boolean = false): unknown {
    const humaincedCuantifier = this.humainceCuantifier(value.unit);

    if (humaincedCuantifier === 'x' && prefixedByAmount) {
      return `${value.name} ${humaincedCuantifier}${value.amount}`;
    }

    return `${value.amount}${humaincedCuantifier} ${value.name}`;
  }

}
