<div class="square-button actionable" (click)="doSelectPhoto()" matRipple *ngIf="!readOnly && photos.length < maxPhotos">
  <input autocomplete="off" class="overlay" type="file" #inputfile (change)="selectFile($event)">
  <div>+</div>
  <div>Añadir foto</div>
  <div><i>Máximo {{maxPhotos}} fotos</i></div>
</div>
<div *ngFor="let photo of photos; index as i" class="selected-photo">
  <img [src]="photo | imagePath" (click)="selectedPhoto=photo">
  <div class="close" *ngIf="!readOnly">
    <button mat-icon-button aria-label="eliminar foto" (click)="doRemovePhoto(i)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="opened-photo" (click)="selectedPhoto=null" *ngIf="selectedPhoto">
  <img [src]="selectedPhoto | imagePath">
</div>
