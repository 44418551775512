<div class="container padding-8" *ngIf=selectedUser>
  <simple-header title="Documentación e información RRHH" [back]="true" icon="close"></simple-header>
  <form [formGroup]="profile" class="padding-top-8 padding-bottom-8">
    <div>
      <h3>Documento de identidad</h3>
      <div>
        <mat-form-field appearance="outline" class="document-type margin-right-16">
          <mat-label>Tipo de documento</mat-label>
          <mat-select [(value)]="documentType">
            <mat-option *ngFor="let currentDocumentType of documentTypes" [value]="currentDocumentType">
              {{currentDocumentType.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Número de documento</mat-label>
          <input matInput formControlName="documentNumber" [maxlength]="documentNumberMaxLength">
          <mat-error *ngIf="profile.get('documentNumber').hasError('required')">
              El número de documento es obligatorio
          </mat-error>
          <mat-error *ngIf="profile.get('documentNumber').hasError('maxlength')">
              El límite de caracteres es de {{documentNumberMaxLength}}
          </mat-error>
        </mat-form-field>
      </div>
      <h4>Fecha de expedición</h4>
      <date-selector [date]="this.selectedUser.document?.expeditionDate" (onChangeDate)="setExpeditionDate($event)"></date-selector>
      <div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Lugar de expedición</mat-label>
          <input matInput formControlName="expeditionPlace">
          <mat-error>
              El lugar de expedición es obligatorio
          </mat-error>
        </mat-form-field>
        <h3>Fecha de nacimiento</h3>
        <date-selector [date]="this.selectedUser.bornDate" (onChangeDate)="setBornDate($event)"></date-selector>
      </div>
      <mat-form-field appearance="outline" class="full-width" [allowAction]="['users.updateContractType']">
        <mat-label>Contrato</mat-label>
        <mat-select [(value)]="contractType">
          <mat-option *ngFor="let currentContractType of contractTypes" [value]="currentContractType">
            {{currentContractType.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Tipo de sangre y RH</mat-label>
        <mat-select [(value)]="bloodType">
          <mat-option *ngFor="let currenBloodType of bloodTypes" [value]="currenBloodType">
            {{currenBloodType.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="align-center">
      <div class="padding-bottom-8">
        <button (click)="save()" [disabled]="profile.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
      </div>
      <div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </form>
</div>
