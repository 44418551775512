import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-list-step-state-item',
  templateUrl: './list-step-state-item.component.html',
  styleUrls: ['./list-step-state-item.component.css']
})
export class ListStepStateItemComponent implements OnInit {

  @HostBinding('attr.disabled') get valid() { return !!this.disabled; }
  // @Input() check;
  @Input() state;
  @Input() title;
  @Input() description;
  @Input() disabled;
  @Input() loading = false;
  @Input() disableAction = false;

  constructor() { }

  ngOnInit(): void {
  }

  get check() {
    return this.state === 'COMPLETED';
  }

}
