import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storage = new Map();

  constructor() {
  }

  getItem(key: string, meta = false) {
    const data = localStorage.getItem(key);

    if (data) {
      this.storage.set(key, JSON.parse(data));
      return meta ? JSON.parse(data) : JSON.parse(data).value;
    }
    return null;
  }

  _getItem(key: string, meta = false) {
    let data = this.storage.get(key);

    if (data) {
      return meta ? data : {data: data.value.data?.slice(0,5)};
    }

    data = localStorage.getItem(key);

    if (data) {
      this.storage.set(key, JSON.parse(data));
      return meta ? JSON.parse(data) : {data: JSON.parse(data).value.data?.slice(0,5)};
    }
    return null;
  }

  setItem(key, value) {
    const data = {
      metadata: { timestamp: new Date().getTime() },
      value
    };
    this.storage.set(key, data);

    localStorage.setItem(key, JSON.stringify(data));
  }
}
