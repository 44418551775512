import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.css']
})
export class FilterListComponent implements OnInit {

  @Input() criterias;

  constructor() { }

  ngOnInit(): void {

  }

}
