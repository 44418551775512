import { Component } from '@angular/core';
import { MaintenanceQuestionaryDialogComponent } from '@ildes/basic/maintenance-questionary-dialog/maintenance-questionary-dialog.component';
import taskQuestionary from '../repair-maintenance/questions-maintenance';
import projectDailyQuestionary from '../repair-maintenance/questions-high-permission';
import { MatDialog } from '@angular/material/dialog';
import { GalleryDialogComponent } from '@ildes/basic/gallery-dialog/gallery-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskIdentificationDialogComponent } from '@ildes/views/task-identification-dialog/task-identification-dialog.component';
import { ModernizationDataDialogComponent } from '../modernization-data-dialog/modernization-data-dialog.component';
import getAvailableUsedMaterials from '@ildes/utils/calculate-available-materials';
import { SessionStore } from '@ildes/stores/session.store';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
import * as actionsQuestionaries from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.actions';
import { UnsyncModernizationsService } from '@ildes/services/unsync-modernizations.service';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { Location } from '@angular/common';
import { concat, of, throwError, zip } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { UnsyncExpansionsService } from '@ildes/services/unsync-expansions.service';

@Component({
    selector: 'app-create-modernization',
    template: `
        <connection-alert></connection-alert>
        <mat-progress-bar mode="indeterminate" *ngIf="pendingRequest"></mat-progress-bar>
        <div class="container">
            <div class="scrollable-content">
            <simple-header title="Modernización" [back]="true" icon="close"></simple-header>
            <div class="item">
                <app-list-step-state-item
                    [state]="statusStepSelectProject"
                    [disabled]="false"
                    (click)="openTaskData()"
                    title="Identificación de la labor"
                    [description]="descriptionStepSelectProject">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepQuestionaryDailyPermission"
                    [disabled]="!selectedProject"
                    (click)="openDailyQuestionary()"
                    title="Cuestionario diario para trabajar en alturas"
                    [description]="descriptionStepQuestionaryDailyPermission">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepHighQuestionaryPermission"
                    [disabled]="statusStepQuestionaryDailyPermission!=='COMPLETED'"
                    (click)="openSecurityQuestionary()"
                    title="Confirmación de seguridad"
                    [description]="descriptionStepHighQuestionaryPermission">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepInitialPhotos"
                    [disabled]="statusStepHighQuestionaryPermission!=='COMPLETED'"
                    (click)="openTakePhotos('initial')"
                    title="Tomar fotos del estado inicial"
                    [description]="descriptionStepInitialPhotos">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepModernizationData"
                    [disabled]="statusStepInitialPhotos!=='COMPLETED'"
                    (click)="openDialogModernization()"
                    title="Datos de la modernización"
                    [description]="descriptionStepModernizationData">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
                <app-list-step-state-item
                    [state]="statusStepFinalPhotos"
                    [disabled]="statusStepModernizationData!=='COMPLETED'"
                    (click)="openTakePhotos('final')"
                    title="Fotos del estado final"
                    [description]="descriptionStepFinalPhotos">
                </app-list-step-state-item>
                <mat-divider></mat-divider>
            </div>
            <div class="padding-top-8 padding-bottom-8" *ngIf="invalidSelectedProject">
                <app-alert-dialog
                    text="No se pudo comprobar el proyecto seleccionado. Para finalizar el proceso tienes que seleccionar de nuevo el proyecto."
                    status="advise"></app-alert-dialog>
            </div>
            <div class="padding-top-8 padding-bottom-8" *ngIf="showErrorLuminary">
                <app-alert-dialog
                    text='La luminaria no existe. Seleccione otra luminaria en el paso "Identificación de la labor"'
                    status="advise"></app-alert-dialog>
            </div>    
            <div class="align-center padding-top-8" *ngIf="!invalidSelectedProject && !unsync && !showErrorLuminary">
                <button [disabled]="pendingRequest || !completedSteps" mat-raised-button color="primary" (click)="createModernization()" class="button-size-200">Crear modernización</button>
            </div>
            <div *ngIf="!invalidSelectedProject && unsync && !showErrorLuminary" class="align-center padding-top-8">
                <button [disabled]="pendingRequest" mat-button (click)="createModernization()" class="button-size-200">
                    <mat-icon class="icon-button">cloud_upload</mat-icon>Sincronizar
                </button>
            </div>
        </div>
    </div>`,
    styleUrls: ['./create-modernization.component.css'],
})
export class CreateModernizationComponent {
    public selectedProject;
    public pendingRequest;
    public answersSecurityQuestionary;
    private openDate;
    private luminary;
    private usedMaterials;
    private user;
    private assignedMaintenances;
    private description;
    private ascendMethod;
    private id;
    public unsync;
    private height;
    private dailyQuestionary;
    private closeDate;
    private technology;
    private power;
    public photos = {
        initial: undefined,
        final: undefined
    };
    private extractedMaterials;
    public showErrorLuminary;
    constructor(
        private dialog: MatDialog,
        private store: Store<AppState>,
        private unsyncModernizations: UnsyncModernizationsService,
        private unsyncExpansions: UnsyncExpansionsService,
        private route: ActivatedRoute,
        private location: Location,
        private apiManager: ApiManagerService,
    ) {
        SessionStore.getInstance().get$().subscribe((data) => {
            this.user = data?.user;
            this.store.dispatch(
                actions.loadMaintenances({
                    assigned: this.user.id,
                    state: undefined,
                    options: { cache: true }
                })
            )
        });

        this.store.select('assignedMaintenances').subscribe((data) => {
            this.assignedMaintenances = data;
        });
        this.route.queryParams.subscribe(params => {
            this.store.dispatch(
                actionsQuestionaries.loadDailyQuestionaries()
            );
            const value = this.unsyncModernizations.findItem(params.id);

            if (!value) {
                return;
            }

            this.unsync = value.unsync;
            this.id = value.id;
            this.power = value.power;
            this.technology = value.technology;
            this.selectedProject = value.selectedProject;
            this.usedMaterials = value.usedMaterials;
            this.answersSecurityQuestionary = value.answersSecurityQuestionary;
            this.openDate = value.openDate;
            this.luminary = value.luminary;
            this.photos = value.photos;
            this.description = value.description;
            this.ascendMethod = value.ascendMethod;
            this.height = value.height;
            this.closeDate = value.closeDate;
            this.extractedMaterials = value.extractedMaterials;
            if (this.selectedProject.id) {
                this.findQuestionary();
            } else {
                this.dailyQuestionary = value.dailyQuestionary
            }
        });
    }

    public signTask() {

    }

    get invalidSelectedProject() {
        return this.selectedProject && !this.selectedProject?.id;
    }

    performModernizationData() {
        const project = {
            name: this.selectedProject.name,
            id: this.selectedProject.id,
        }
        return {
            id: this.id,
            ascendMethod: this.ascendMethod,
            height: this.height,
            selectedProject: project,
            luminary: this.luminary,
            project,
            unsync: this.unsync,
            dailyQuestionary: this.dailyQuestionary,
            answersSecurityQuestionary: this.answersSecurityQuestionary,
            openDate: this.openDate,
            usedMaterials: this.usedMaterials,
            description: this.description,
            photos: this.photos,
            closeDate: this.closeDate,
            extractedMaterials: this.extractedMaterials,
            technology: this.technology,
            power: this.power
        };
    }
    public createModernization() {
        this.closeDate = this.closeDate || new Date().toISOString();
        const modernizationData = this.performModernizationData();
        this.unsyncModernizations.addItem(modernizationData);
        this.pendingRequest = true;
        const userId = SessionStore.getInstance().get().user.id;
        concat(
            this.syncDailyQuestionary(),
            this.unsyncModernizations.sync(modernizationData).pipe(tap(() => {
                this.pendingRequest = false;
                this.apiManager.getUserDetail(userId).subscribe((user) => {
                    SessionStore.updateUserInfo(user, true);
                });
                this.location.back();
            })),
        ).subscribe((data) => {
            
        }, (error) => {
            this.pendingRequest = false;
            this.apiManager.getUserDetail(userId).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
            });
            if (error.code === 1) {
                this.showErrorLuminary = true;
            }
        });
    }
    private syncDailyQuestionary() {
        if (!this.dailyQuestionary.pendingSync) {
            return of(null);
        }
        return this.apiManager.updateDailyQuestionary(this.selectedProject.id, {
            questions: projectDailyQuestionary,
            answers: this.dailyQuestionary.answers,
            date: this.dailyQuestionary.date,
        }).pipe(
            tap(() => {
                this.store.dispatch(
                    actionsQuestionaries.update({
                        changes: { pendingSync: false },
                        date: new Date(this.dailyQuestionary.date),
                        projectId: this.selectedProject.id,
                    })
                );
            }),
            catchError((error) => {
                if (error.code === 1) {
                    this.store.dispatch(
                        actionsQuestionaries.update({
                            changes: { answers: this.dailyQuestionary.answers.map(({ id }) => ({ id, value: true })), pendingSync: false },
                            date: new Date(this.dailyQuestionary.date),
                            projectId: this.selectedProject.id,
                        })
                    );
                }
                return throwError(error);
            })
        )
    }
    public get completedSteps() {
        return this.statusStepFinalPhotos === 'COMPLETED';
    }

    public get statusStepSelectProject() {
        if (this.selectedProject?.id) {
            return 'COMPLETED';
        } else {
            return 'PENDING';
        }
    }


    public get descriptionStepSelectProject() {
        if (this.selectedProject) {
            return `Proyecto seleccionado: ${this.selectedProject.name}.<br>
                Luminaria: ${this.luminary?.number}.<br>
                Altura: ${this.height}.<br>
                Método de ascenso: ${this.ascendMethod?.description}.`;
        } else {
            return 'Debes seleccionar un proyecto para poder comenzar.';
        }
    }

    public get statusStepQuestionaryDailyPermission() {
        if (!this.dailyQuestionary?.answers) {
            return 'PENDING';
        }
        const responsedAnswers = this.dailyQuestionary?.answers?.filter(({ value }) => value)?.length;

        if (responsedAnswers === projectDailyQuestionary?.length) {
            return 'COMPLETED';
        } else {
            return 'IN_PROGRESS';
        }
    }

    public get statusStepHighQuestionaryPermission() {
        if (!this.answersSecurityQuestionary) {
            return 'PENDING';
        }
        const responsedAnswers = this.answersSecurityQuestionary?.filter(({ value }) => value)?.length;

        if (responsedAnswers === taskQuestionary?.length) {
            return 'COMPLETED';
        } else {
            return 'IN_PROGRESS';
        }
    }

    public get descriptionStepQuestionaryDailyPermission() {
        if (this.dailyQuestionary?.answers?.length > 0) {
            const responsedAnswers = this.dailyQuestionary?.answers.filter(({ value }) => value).length;

            return `Contestadas ${responsedAnswers} de ${projectDailyQuestionary.length}`;
        }

        return 'Completa el formulario diario de permiso de trabajo';

    }
    public get descriptionStepHighQuestionaryPermission() {
        if (this.answersSecurityQuestionary?.length > 0) {
            const responsedAnswers = this.answersSecurityQuestionary.filter(({ value }) => value).length;

            return `Contestadas ${responsedAnswers} de ${taskQuestionary.length}`;
        }

        return 'Completa el cuestionario de permiso en alturas.';

    }
    public get statusStepInitialPhotos() {
        let photos = this.photos.initial;

        if (this.luminary && !this.luminary.id) {
            photos = this.luminary.photos || [];
        }
        return photos ? 'COMPLETED' : 'PENDING';
    }
    public get descriptionStepInitialPhotos() {
        if (this.photos.initial) {
            return `${this.photos.initial.length} añadidas.`;
        } else {
            return 'Toma las fotos del estado del terrreno.';
        }
    }
    public get descriptionStepModernizationData() {
        return !this.technology ?
            'Introduce los datos de la modernización.' :
            `Material usado: ${this.humaniceMaterials(this.usedMaterials)}.<br>
            Observaciones: ${this.description || '-'}.<br>
            Tecnología: ${this.technology}.<br>
            Potencia: ${this.power}.`;
    }
    public get statusStepFinalPhotos() {

        return this.photos.final ? 'COMPLETED' : 'PENDING';
    }
    public get descriptionStepFinalPhotos() {
        if (this.photos.final) {
            return `${this.photos.final.length} añadidas.`;
        } else {
            return 'Toma las fotos del estado final.';
        }
    }
    public get statusStepModernizationData() {
        return this.technology && this.power ? 'COMPLETED' : 'PENDING';
    }
    private humaniceMaterials(materials) {
        if (!materials?.length) {
            return '-'
        }
        return materials.map((material) => `${material.reference.name} x${material.amount}`).join(',');
    }
    

    openTaskData() {
        const dialog = this.dialog.open(TaskIdentificationDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                project: this.selectedProject,
                luminary: this.luminary,
                ascendMethod: this.ascendMethod,
                height: this.height,
                requiredProject: true,
                requiredLuminary: true,
            }
        });

        dialog.afterClosed().subscribe((data) => {
            if (!data.commit) {
                return;
            }
            this.ascendMethod = data.ascendMethod;
            this.height = data.height;
            this.selectedProject = data.project;
            this.luminary = data.luminary;
            this.showErrorLuminary = false;
            if (this.selectedProject.id) {
                this.findQuestionary();
            }

            this.id = this.unsyncModernizations.addItem(this.performModernizationData());
        });
    }

    private sameDate(day1,day2) {
        return (
          day1.getFullYear() === day2.getFullYear() &&
          day1.getMonth() === day2.getMonth() &&
          day1.getDate() === day2.getDate()
        );
      }

    findQuestionary() {
        this.store.select('dailyQuestionaries').pipe(first()).subscribe((data) => {
            const consignedDate = this.getConsignedDate();
            
            this.dailyQuestionary = data.find(({ projectId, date }) => {
                console.log(consignedDate, date);
                return projectId === this.selectedProject.id && this.sameDate(consignedDate, date)
            });

            if (!this.dailyQuestionary) {
                this.fetchDailyQuestionary();
            }
        });

        return this.store.select('dailyQuestionaries').subscribe((data: any) => {
            const consignedDate = this.getConsignedDate();

            this.dailyQuestionary = data.find(({ projectId, date }) => projectId === this.selectedProject?.id && this.sameDate(consignedDate, date));
        })
    }

    getConsignedDate() {
        return this.closeDate ? new Date(this.closeDate) : new Date();
    }

    fetchDailyQuestionary(technicianId?) {
        const consignedDate = this.getConsignedDate();
    
        this.apiManager.getDailyQuestionary(this.selectedProject?.id, { date: consignedDate.toISOString(), technicianId } , { error: false }).subscribe((data: any) =>{
          if (!data.data) {
            return;
          }
          this.dailyQuestionary = {
            answers: data.data.questionary,
            date: new Date(data.data.date),
            projectId: this.selectedProject?.id
          };
        });
      }

    openDailyQuestionary() {
        // completar cuestionario
        const dialog = this.dialog.open(MaintenanceQuestionaryDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                title: 'Cuestionario diario para trabajar en alturas',
                readOnly: false,
                questions: projectDailyQuestionary,
                answers: this.dailyQuestionary?.answers
            }
        });

        dialog.afterClosed().subscribe((data) => {
            if (!data.commit) {
                return;
            }
            this.openDate = this.openDate || new Date().toISOString();
            const updated = !!this.dailyQuestionary;
            this.dailyQuestionary = {
                questions: projectDailyQuestionary,
                answers: data.answers,
            };
            // TODO: ver qué pasa si no tenemos el proyecto (permitir hacerlo por nombre también)
            if (!this.selectedProject.id) {

                return;
            }
            this.apiManager.updateDailyQuestionary(this.selectedProject.id, {
                questions: projectDailyQuestionary,
                answers: data.answers,
                date:  this.openDate,
              }).subscribe(() => {
                this.performSaveDailyPermission({ data, updated }, false);
              }, (error) => {
                // en este caso ya se completó el cuestionario de forma correcta y no se puede modificar
                // restauramos a todo true
                if (error.code === 1) {
                  data.answers.forEach((answer) => {
                    answer.value = true;
                  });
        
                  this.performSaveDailyPermission({ data, updated }, false);
        
                  return;
                }
                if (!error.code || error.code > 0) {  
                  return;
                }
                this.performSaveDailyPermission({ data, updated }, true);
              });
        });
    }

    public performSaveDailyPermission({ data, updated }, pendingSync = false) {
        if (!updated) {
          this.store.dispatch(
            actionsQuestionaries.newQuestionary({
              answers: data.answers,
              date: new Date(),
              projectId: this.selectedProject.id,
              pendingSync
            })
          );
        } else {
          this.store.dispatch(
            actionsQuestionaries.update({
              changes: { answers: data.answers, pendingSync },
              date: new Date(),
              projectId: this.selectedProject.id,
            })
          );
        }
      }

    openSecurityQuestionary() {
        // completar cuestionario
        const dialog = this.dialog.open(MaintenanceQuestionaryDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                readOnly: false,
                questions: taskQuestionary,
                title: 'Cuestionario de seguridad',
                answers: this.answersSecurityQuestionary,
            }
        });

        dialog.afterClosed().subscribe((data) => {
            if (!data.commit) {
                return;
            }
            this.answersSecurityQuestionary = data.answers;
        });
    }

    openTakePhotos(step) {
        // asociamos fotos a estado inicial o estado final
        let photos = this.photos[step];

        if (step === 'initial' && this.luminary && !this.luminary.id) {
            photos = this.luminary.photos || [];
        }
        const dialog = this.dialog.open(GalleryDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                readOnly: false,
                photos: photos
            }
        });

        dialog.afterClosed().subscribe(async (data) => {
            if (!data?.commit) {
                return;
            }

            this.photos[step] = data.photos;
            this.id = this.unsyncModernizations.addItem(this.performModernizationData());
        });
    }

    openDialogModernization() {
        const dialog = this.dialog.open(ModernizationDataDialogComponent, {
            width: '600px',
            height: '600px',
            panelClass: 'responsive-dialog',
            data: {
                readOnly: false,
                luminary: this.luminary,
                project: this.selectedProject,
                availableMaterials: getAvailableUsedMaterials(this.user.confirmedMaterials, this.assignedMaintenances, this.unsyncExpansions.getItems(), this.unsyncModernizations.getItems()),
                usedMaterials: this.usedMaterials?.map(({ amount, reference }) => ({ amount, reference })),
                extractedMaterials: this.extractedMaterials?.map(({ amount, reference }) => ({ amount, reference })),
                descripiton: this.description,
                technology: this.technology,
                power: this.power,
            }
        });

        dialog.afterClosed().subscribe(async (data) => {
            if (data.closed) {
                return;
            }
            this.description = data.description;
            this.usedMaterials = data.usedMaterials;
            this.extractedMaterials = data.extractedMaterials;
            this.technology = data.technology;
            this.power = data.power;
            this.id = this.unsyncModernizations.addItem(this.performModernizationData());
        });
    }
}
