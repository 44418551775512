import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'list-project-item',
  templateUrl: './list-project-item.component.html',
  styleUrls: ['./list-project-item.component.css']
})
export class ListProjectItemComponent implements OnInit {

  @Output() addParticipantsClicked: EventEmitter<any> = new EventEmitter();
  @Input() name;
  @Input() participants;
  @Input() startDate:any = new Date();
  @Input() endDate:any = new Date();
  @Input() contractType;
  @Input() contractNumber;
  @Input() actions;

  @Output() doAction =  new EventEmitter();
  @Output() onSelect = new EventEmitter();

  view = [75, 75];
  manteinances: any[] = [{name: 'a', value: 5},{name: 'b', value: 15}];

  colorScheme = {
    domain: ['#26c6da', '#b2ebf2']
  };

  get duration() {
    const startDate = moment(this.startDate);
    const endDate = moment(this.endDate);
    const years = endDate.diff(startDate, 'years');
    const months = endDate.diff(startDate, 'months') - years*12;
    const days = endDate.diff(startDate, 'days');
    let results = '';

    if (years) {
      results = `${results}${years} años`;
    }
    if (months > 0) {
      results = `${results && results +', '}${months} meses`;
    }
    if (!results && days > 0){
      results = `${results}${days} días`;
    }
    return results;
  }
  constructor(
  ) { }

  ngOnInit(): void {
    
  }

  linkClicked() {
    this.onSelect.emit();
  }

  onAddParticipants() {
    this.addParticipantsClicked.emit();
  }

  actionEvent(action) {
    this.doAction.emit(action);
  }

}
