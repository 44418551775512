import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { SessionService } from '@ildes/services/session.service';
import { ToastService } from '@ildes/services/toast.service';
import { SessionStore } from '@ildes/stores/session.store';
import { MAIN_ROUTES } from 'src/config';

@Component({
    selector: 'app-remember-password',
    template: `<mat-card class="banner">
    <div class="padding-left-16 padding-right-16">
      <img src="./assets/images/logo.png" class="logo">
      <div *ngIf="!user">
        <p> Introduce tu correo electrónico para enviarte el email de recuperación de cuenta</p>
        <mat-divider></mat-divider>
        <form [formGroup]="credentials" class="padding-top-8 padding-bottom-8">
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Email</mat-label>
              <input matInput required [readonly]="this.requested" placeholder="Ex. pat@example.com" formControlName="email">
              <mat-error>
                  El email es obligatorio y debe ser un email válido
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="this.requested">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Código de recuperación</mat-label>
              <input matInput required placeholder="Introduce el código de 4 digitos que ha recibido" formControlName="code">
              <mat-error>
                  El código es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
          <div class="align-center">
            <div class="padding-bottom-8">
              <button (click)="generateEmail()" [disabled]="this.loading" mat-raised-button color="primary" class="button-size-200" *ngIf="!requested">Enviar enlace</button>
              <button (click)="signin()" [disabled]="this.loading" mat-raised-button color="primary" class="button-size-200" *ngIf="requested">Entrar con código</button>
            </div>
            <p *ngIf="this.requested">
                Código de recuperación enviado a su email. Si no recibe el email, revise su bandeja de spam.
            </p>
            <div>
              <!-- <button mat-button>He olvidado la contraseña</button> -->
              <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="user">
      <mat-divider></mat-divider>
      <div class="padding-bottom-32 padding-top-32">
        <profile-avatar size="big" [image]="user.photo | imagePath" [name]="user.name" [surname]="user.surname"></profile-avatar>
      </div>
      <p>
        Bienvenido, {{user.name}}
      </p>
      <div class="margin-top-32 margin-bottom-8">
        <button (click)="go()" mat-raised-button color="primary" class="button-size-200">Entrar</button>
      </div>
    </div>
    </div>
      
  </mat-card>`,
    styleUrls: ['./remember-password.component.css']
})
export class RememberPasswordComponent implements OnInit{
    public credentials: UntypedFormGroup;
    public loading = false;
    public requested = false;
    public user;
    constructor(
      private apiManager: ApiManagerService,
      private router: Router,
      private sessionService: SessionService,
      private location: Location,
      private toastService: ToastService,
    ) {

    }
    ngOnInit(): void {
      SessionStore.getInstance().get$().subscribe((data) => {
        this.user = data.user;
      });

      this.credentials = new UntypedFormGroup({
          'email': new UntypedFormControl('', [ Validators.required, Validators.email ]),
          'code': new UntypedFormControl('')
      });
    }
    signin() {
      const email = this.credentials.controls.email.value.toLowerCase();
      const token = this.credentials.controls.code.value.toLowerCase();
      this.loading = true;

      this.sessionService.recover(
        email,
        token,
      ).subscribe((data) => {
        this.loading = false;
        this.router.navigate(['/app/user/change-password']);
      }, () => {
        this.loading = false;
      })
    }
    generateEmail() {
        this.loading = true;

        this.apiManager.recoverAccess({
            email: this.credentials.controls.email.value.toLowerCase()
        }).subscribe(() =>{
            this.requested = true;
            this.loading = false;
        }, () =>{
            this.loading = false
        })
    }

    go() {
      this.apiManager.getUserDetail(this.user.id).subscribe((data: any) => {
        SessionStore.updateUserInfo(data, true);
        this.router.navigate([MAIN_ROUTES[data.role.id] || MAIN_ROUTES.NOTHING_ELSE]);
      }, () => {
        this.toastService.show(`Datos actualizados por última vez ${new Date(this.user.lastUpdate).toLocaleString()}`, 'warning');
        this.router.navigate([MAIN_ROUTES[this.user.role.id] || MAIN_ROUTES.NOTHING_ELSE]);
      });
    }

}
