<div class="content">
  <button class="left" (click)="dec()" [disabled]="disableDec" mat-icon-button aria-label="restar 1 item">
    <mat-icon>remove_circle_outline</mat-icon>
  </button>
  <div>
    <span *ngIf="showLeft">{{humanizeUnit}}</span>
    {{value}}
    <span *ngIf="!showLeft">{{humanizeUnit}}</span>
  </div>
  <button class="right" (click)="inc()" [disabled]="disableInc" mat-icon-button aria-label="añadir 1 item">
    <mat-icon>add_circle_outline</mat-icon>
  </button>
  <button *ngIf="showRemoveOption" class="right" (click)="doRemove()" mat-icon-button aria-label="eliminar">
    <mat-icon>highlight_off</mat-icon>
  </button>
</div>