<div class="data">
  <div matRipple role="button">
    <span class="name">{{name}}</span>
  </div>
  <div class="subtitle">
    <span>{{unitDescription}}</span>
  </div>
</div>
<div *ngIf="amount && { unit: unit, amount: amount, name: '' } as material">
  {{material | humanizeDescriptionMaterial:true}}
</div>
<div class="actions" *ngIf="actions">
  <button (click)="onClick(action)" mat-icon-button *ngFor="let action of actions">
    <mat-icon>{{action.icon}}</mat-icon>
  </button>
</div>
