import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'number-increment',
  templateUrl: './number-increment.component.html',
  styleUrls: ['./number-increment.component.css']
})
export class NumberIncrementComponent implements OnInit {

  @Input() value = 1;
  @Input() max;
  @Input() min = 1;
  @Output() valueChange = new EventEmitter<number>();
  @Output() remove = new EventEmitter();
  @Input() unit;
  @Input() showRemoveOption = false;

  constructor() { }

  ngOnInit(): void {
    this.value == this.value ?? this.min;
  }

  inc() {
    this.value++;
    this.valueChange.emit(this.value);
  }

  dec() {
    this.value--;
    this.valueChange.emit(this.value);
  }

  get disableDec() {
    return this.value === this.min ? 'true': undefined;
  }

  get disableInc() {
    if (this.max >= 0) {
      return this.value >= this.max ? 'true': undefined;
    }

    return undefined;
  }

  get humanizeUnit() {
    if (this.unit?.id === 'UNITS' || !this.unit?.description) {
      return 'x';
    }

    return this.unit?.description[0];
  }

  get showLeft() {
    return this.humanizeUnit === 'x';
  }

  doRemove() {
    this.remove.emit();
  }
}
