import { Component } from '@angular/core';

@Component({
    selector: 'app-create-luminary-distributive',
    template: `
    <div class="container">
        <div class="scrollable-content">
        <simple-header title="Creación de luminaria" [back]="true" icon="close"></simple-header>
        <mat-action-list>
            <button mat-list-item [routerLink]="['/app/create-expansion']" [allowAction]="['expansion.create']">
                <mat-icon>wb_incandescent</mat-icon> <span class="padding-left-16">Expansión</span>
            </button>
            <button mat-list-item [routerLink]="['/app/create-modernization']" [allowAction]="['modernization.create']">
                <mat-icon>refresh</mat-icon> <span class="padding-left-16">Modernización</span>
            </button>
            <button mat-list-item [routerLink]="['/app/list-luminaries/create']">
                <mat-icon>add_circle</mat-icon> <span class="padding-left-16">Censar luminaria</span>
            </button>
        </mat-action-list>
        </div>
    </div>`,
    styleUrls: ['./create-luminary-distributive.component.css']
})
export class CreateLuminaryDistributiveComponent { }
