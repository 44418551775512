import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Camera, CameraResultType } from '@capacitor/camera';

@Component({
  selector: 'app-select-photo-dialog',
  templateUrl: './select-photo-dialog.component.html',
  styleUrls: ['./select-photo-dialog.component.css']
})
export class SelectPhotoDialogComponent implements OnInit {

    reader;
    photo;
    loadingCropper;
    croppedImage;
    aspectRatio = 1;
    resizeToWidth = 200;

    constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<SelectPhotoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }

    ngOnInit(): void {
      this.reader = new FileReader();

      const reader = this.reader;

      reader.onload = () => {
        this.loadingCropper = true;
        this.photo = reader.result
      };
      // reader.onerror = () => this.errorSelectedFile.emit();
    }

    onSelectedImage(file) {
      this.reader.readAsDataURL(file);
    }

    async openCamara(file) {
      
      const image = await Camera.getPhoto({
        quality: 50,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        promptLabelPhoto:   'Abrir galería    ',
        promptLabelPicture: 'Tomar una foto'
      });
      if (!image) {
        return;
      }
      this.loadingCropper = true;
      this.photo = `data:image/jpeg;base64,${image.base64String}`;

    }

    onErrorSelectedImage() {
      console.log('error!');
      /*TODO: controlar error al cargar imagen*/
    }

    changePhoto() {
      this.dialogRef.close({ photo: this.croppedImage });
    }

    imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
    }

    imageLoaded() {

    }

    cropperReady() {
      this.loadingCropper = false
        // cropper ready
    }
    loadImageFailed() {
      // show message
    }

    close() {
      this.dialogRef.close({ closed: true });
    }


}
