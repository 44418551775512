import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { Router } from '@angular/router';
import { NativeLayerService } from '@ildes/services/native-layer.service';
import { FingerprintService } from '@ildes/services/fingerprint.service';

@Component({
  selector: 'app-mailbox',
  templateUrl: './mailbox.component.html',
  styleUrls: ['./mailbox.component.css']
})
export class MailboxComponent implements OnInit {
  pendingRequest;
  tasks;
  fingerprintEnabled;
  availableBiometry;
  constructor(
    private apiManager: ApiManagerService,
    private router: Router,
    private nativeLayer: NativeLayerService,
    private fingerprint: FingerprintService,
  ) { }

  ngOnInit(): void {
    this.pendingRequest = true;

    if (this.nativeLayer.isNative) {
      this.fingerprint.isCompatible().then((result) => {
        this.availableBiometry = result;
  
        this.fingerprint.isEnrolled().then((result) => {
          this.fingerprintEnabled = result;
        });
      });
    }

    this.apiManager.getTasks({ state: 'PENDING_PERFORM' }).subscribe(({ data }: any) => {
      this.pendingRequest = false;
      this.tasks = data.map(( task ) => {
        return {
          ...task,
          ...{
            description: this.performDescription(task),
            title: this.performTitle(task)
          }
        };
      })
      
    }, () => {
      this.pendingRequest = false;
    });
  }

  get fingerprintAlertText() {
    if (!this.availableBiometry) {
      return 'El dispositivo no es compatible con la huella. No podrás firmar documentos con este dispositivo.';
    } else if (!this.fingerprintEnabled) {
      return 'Aun no has activado la huella. Necesitas activar la huella para completar el cierre de los mantenimientos.'
    }

    return '';
  }

  async enrollFingerprint() {
    if (!this.nativeLayer.isNative) {
      return;
    }

    await this.fingerprint.enroll();

    this.fingerprintEnabled = true;
  }

  performDescription(task) {
    switch (task.subtype) {
      case 'WORK_AT_HIGH_DAILY_PERMISSION':
        return 'Accede para firmar la documentación';
      case 'WORK_AT_MAINTENANCE_PERMISSION':
        return 'Accede para firmar la documentación';
      default:
        break;
    }
  }

  performTitle(task) {
    switch (task.subtype) {
      case 'WORK_AT_HIGH_DAILY_PERMISSION':
          return 'Solicitud de firma para permiso de trabajo en alturas';
      case 'WORK_AT_MAINTENANCE_PERMISSION':
        return 'Solicitud de firma para confirmación de la correcta revisión del entorno y equipo';
      default:
        break;
    }
  }

  openTask(task) {
    this.router.navigate(['/app/task'], { queryParams: { id: task.id } });
  }

}
