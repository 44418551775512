import { createAction, props } from '@ngrx/store';

export const initState = createAction('[Assigned Maintenances] init', props<{maintenances: any[]}>());
export const saveQuestionary = createAction('[Assigned Maintenances] questionary', props<{maintenanceId: string, answers: boolean[], task?: object, openDate: string}>());
export const identifyLuminary = createAction('[Assigned Maintenances] identifyLuminary', props<{maintenanceId: string, luminaryNumber: string, ascendMethod: Object,height: Number}>());
export const setPhotosInitialState = createAction('[Assigned Maintenances] setPhotosInitialState', props<{maintenanceId: string, photos: any[]}>());
export const setPhotosFinalState = createAction('[Assigned Maintenances] setPhotosFinalState', props<{maintenanceId: string, photos: any[]}>());
export const setRepairmentData = createAction('[Assigned Maintenances] setReparimentData', props<{maintenanceId: string, usedMaterials: any[], extractedMaterials: any[], description: string}>());
export const changeState = createAction('[Assigned Maintenances] changeState', props<{maintenanceId: string, state: string, repairmentDate?: string}>());
export const sync = createAction('[Assigned Maintenances] sync', props<{maintenanceId: string}>());
export const loadMaintenances = createAction('[Assigned Component] load', props<{assigned: string, options: any, state: string[]}>());
export const loadedMaintenances = createAction('[Assigned Component] loaded', props<{maintenances: any[]}>());
export const loadMaintenancesError = createAction('[Assigned Component] error');
export const normalizePhotos = createAction('[Assigned Maintenances] normalizePhotos', props<{maintenance: any[]}>());
export const addParticipants = createAction('[Assigned Maintenances] addParticipants', props<{maintenanceId: string, participants: any[]}>());
export const updateTask = createAction('[Assigned Maintenances] updateTask', props<{maintenanceId: string, task: any}>());
