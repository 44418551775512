
const TECHNOLOGIES = [
  {
    id: 'MERCURY',
    description: 'Mercurio'
  },
  {
    id: 'SODIUM',
    description: 'Sodio'
  },
  {
    id: 'LED',
    description: 'Led'
  },
  {
    id: 'OTHER',
    description: 'Other'
  },
];
const TYPE = [
  {
    id: 'H',
    description: 'H'
  },
  {
    id: 'V',
    description: 'V'
  },
  {
    id: 'A',
    description: 'A'
  },
  {
    id: 'C',
    description: 'C'
  },
  {
    id: 'HA',
    description: 'HA'
  },
  {
    id: 'HC',
    description: 'HC'
  },
  {
    id: 'VA',
    description: 'VA'
  },
  {
    id: 'VC',
    description: 'VC'
  },
];
const SPECIAL = [
  {
    id: 'LUMINARY',
    description: 'Luminaria'
  },
  {
    id: 'FAROL',
    description: 'Farol'
  },
  {
    id: 'REFLECTOR',
    description: 'Reflector'
  },
];

const ANOMALY = [
  {
    id: 'OFF',
    description: 'Apagada'
  },
  {
    id: 'DIRECT',
    description: 'Directa'
  },
];
const SWITCHEDON = [
  {
    id: 'REVEL',
    description: 'Revelador'
  },
  {
    id: 'PHOTO',
    description: 'Fotocelda'
  },
  {
    id: 'MANUAL',
    description: 'Operación manual'
  },
];

const ELECTRIC_NETWORK = [
  {
    id: 'DISTRIBUTION',
    description: 'Distribución'
  },
  {
    id: 'EXCLUSIVE',
    description: 'Exclusiva'
  },
];

const UR = [
  {
    id: 'U',
    description: 'U'
  },
  {
    id: 'R',
    description: 'R'
  },
];

const POST = [
  {
    id: 'PRIMARY',
    description: 'Primario'
  },
  {
    id: 'SECONDARY',
    description: 'Secundario'
  },
  {
    id: 'EXCLUSIVE',
    description: 'Exclusivo'
  },
]

const POST_CLASS = [
  {
    id: 'CONCRETE',
    description: 'Concreto'
  },
  {
    id: 'METALIC',
    description: 'Metálico'
  },
];

const RETILAP = [
  {
    id: 'M1',
    description: 'M1'
  },
  {
    id: 'M2',
    description: 'M2'
  },
  {
    id: 'M3',
    description: 'M3'
  },
  {
    id: 'M4',
    description: 'M4'
  },
  {
    id: 'M5',
    description: 'M5'
  },
  {
    id: 'P1',
    description: 'P1'
  },
  {
    id: 'P2',
    description: 'P2'
  },
  {
    id: 'P3',
    description: 'P3'
  },
  {
    id: 'P4',
    description: 'P4'
  },
  {
    id: 'P5',
    description: 'P5'
  },
  {
    id: 'C1',
    description: 'C1'
  },
  {
    id: 'C2',
    description: 'C2'
  },
  {
    id: 'C3',
    description: 'C3'
  },
  {
    id: 'C4',
    description: 'C4'
  },
  {
    id: 'C5',
    description: 'C5'
  },

];

const TYPES = [
  {
    id: 'CORRECTIVE',
    description: 'Correctivo'
  },
  {
    id: 'PREVENTIVE',
    description: 'Preventivo'
  },
  {
    id: 'OTHER',
    description: 'Actividades adicionales'
  },
];

const PAIN_TYPES = [
  {
    id: 'NO_POWEROFF',
    description: 'Encendida las 24 horas'
  },
  {
    id: 'INTERMITENT',
    description: 'Presenta apagados intermitentes'
  },
  {
    id: 'OFF',
    description: 'Apagada'
  },
  {
    id: 'DISCONNECTED',
    description: 'Desconectada'
  },
  {
    id: 'NO_LUMINARY',
    description: 'No tiene luminaria'
  },
  {
    id: 'OTHER',
    description: 'Otros'
  }
];

const INSTALLATION_ZONES = [
  {
    id: 'URBAN',
    description: 'Urbana'
  },
  {
    id: 'RURAL',
    description: 'Rural'
  }
];

export default {
  TYPES,
  PAIN_TYPES,
  POST_CLASS,
  POST,
  UR,
  ELECTRIC_NETWORK,
  SWITCHEDON,
  ANOMALY,
  SPECIAL,
  TYPE,
  TECHNOLOGIES,
  RETILAP,
  INSTALLATION_ZONES
}
