import {  Component, Input, Output, type OnInit, EventEmitter } from '@angular/core';

@Component({
    selector: 'list-store-item',
    template: `
    <div class="flex-row padding-top-8 padding-bottom-8">
        <div class="space-width actionable" (click)="onLinkClicked()">
            <div class="header padding-bottom-8">
                <div class="column-full-width luminary-number" >
                    <span class="name" >{{storeName}}</span>
                </div>
                <div>
                    <span class="project">{{projectName}}</span>
                </div>
            </div>
            <div class="container">
                <div class="data">
                </div>
                <div class="container-right">
                    
                </div>
            </div>
            <div class="container">
                <div class="data">
                    
                </div>
            </div>
        </div>
        <div class="actions" *ngIf="actions">
            <contextual-action [actions]="actions" [collapseAll]="true" (actionEvent)="onClickActionEvent($event)"></contextual-action>
        </div>
    <div>
    `,
    styleUrls: ['./list-store-item.component.css'],
})
export class ListStoreItemComponent implements OnInit {

    @Input() projectName;
    @Input() storeName;

    @Input() actions;
    @Output() linkClicked = new EventEmitter();
    ngOnInit(): void { }

    onLinkClicked() {
        this.linkClicked.emit();
    }

    onClickActionEvent() {
        
    }

}
