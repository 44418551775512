import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.css']
})
export class SimpleHeaderComponent implements OnInit {

  @Input() title;
  @Input() icon;
  @Input() back;
  @Input() actions;

  @Output() action =  new EventEmitter();

  constructor(
    private location: Location,
  ) { }

  ngOnInit(): void {
  }

  onAction(action: any) {
    if (this.back && !action) {
      this.location.back();

      return;
    }
    this.action.emit(action);
  }

}
