import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { SelectUsersDialogComponent } from '../../basic/select-users-dialog/select-users-dialog.component';
import { ErrorBulkLuminariesDialogComponent } from '../../basic/error-bulk-luminaries-dialog/error-bulk-luminaries-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

const LOAD_FROM_FILE = {
  id: 'assign',
  description: 'Cargar luminarias',
  icon: 'assignment_ind',
  permission: { // TODO
    family: 'maintenances',
    actionName: 'assignToTechnician'
  }
};

const ACTIONS = [ LOAD_FROM_FILE ];

@Component({
  selector: 'app-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.css']
})
export class ListProjectsComponent implements OnInit {

  selectedProject;
  projects;
  loading;
  pagination;
  filter;
  actions = ACTIONS;

  @ViewChild("inputfile") inputfileView: ElementRef;

  searchFormGroup = new UntypedFormGroup({
    projectName: new UntypedFormControl(),
  });

  constructor(
    private dialog: MatDialog,
    private apiManager: ApiManagerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.list();
  }

  addParticipants(project) {
    const dialog = this.dialog.open(SelectUsersDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { selected: project.participants || [], disabled: [project.owner || {}] }
    });

    dialog.afterClosed().subscribe( (data) => {
      if (data.close) {
        return;
      }
      this.apiManager.changeParticipants(project.id, { participants: data.participants }).subscribe((data: any) => {
        const cProject = this.projects.find(({ id }) => project.id === id);

        cProject.participants = data.participants;
      });
    });
  }

  paginate(event) {
    this.loading = true;

    this.list({ page: event.pageIndex + 1 })
  }

  private list(pagination = {}) {
    this.loading = true;
    this.apiManager.listProjects({...pagination, ...this.filter}).subscribe((projects: any) => {
      this.loading = false;
      this.projects = projects.data;

      this.pagination = projects.pagination;
    }, () => {
      this.loading = false;
    });
  }

  removeFilters() {
    this.filter = null;

    this.list();
  }

  public get humanizeFilter() {
    if (!this.filter) {
      return;
    }

    return [{
      key: 'Nombre: ',
      value: this.filter.text,
    }];
  }

  public findByName() {
    this.filter = { text: this.searchFormGroup.get('projectName').value };

    this.list();
  }

  doAction(action, project) {
    switch (action.id) {
      case LOAD_FROM_FILE.id:
          this.selectedProject = project;

          this.inputfileView.nativeElement.click();
        break;
      default:
    }
  }

  selectFile(event) {
    const file = event.target.files[0];

    if (!event.target.files[0]) {
      this.selectedProject = null;

      return;
    }

    this.loading = true;
    this.apiManager.bulkLuminaries(this.selectedProject.id, file).subscribe((data) => {
      this.inputfileView.nativeElement.value = null;
      this.loading = false;
      const dialog = this.dialog.open(ErrorBulkLuminariesDialogComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog',
        data
      });

    }, () => {
      this.inputfileView.nativeElement.value = null;
      this.loading = false;
    });
    this.selectedProject = null;
  }

  gotoDetail(project) {
    this.router.navigate(['/app/list-projects/edit'], { queryParams: { id: project.id } });
  }

}
