import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    const formatDate = new Date(value);

    return `${formatDate.getDate()}/${(formatDate.getMonth() + 1)}/${formatDate.getFullYear()}`;
  }

}
