<div *ngIf="actions">
  <button mat-icon-button *ngFor="let action of firstLevelActions" (click)="onClickActionEvent(action)">
    <mat-icon>{{action.icon}}</mat-icon>
  </button>
  <div *ngIf="secondLevelActions?.length">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Abrir menú" >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let action of secondLevelActions" (click)="onClickActionEvent(action)">
        <span>{{action.description}}</span>
      </button>
    </mat-menu>
  </div>
</div>
