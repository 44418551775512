import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'list-maintenance-item',
  templateUrl: './list-maintenance-item.component.html',
  styleUrls: ['./list-maintenance-item.component.css']
})
export class ListMaintenanceItemComponent implements OnInit {

  @Input() state;
  @Input() type;
  @Input() luminaryNumber;
  @Input() project;
  @Input() address;
  @Input() neighborhood;
  @Input() referencePoint;
  @Input() power;
  @Input() technology;
  @Input() materials;
  @Input() estimatedResolutionDate;
  @Input() painType;
  @Input() painDescription;
  @Input() selector;
  @Input() actions;
  @Input() selected;
  @Input() technician;
  @Input() repairmentDate;
  @Input() pendingSync;
  @Input() omitDefaultEventBehaviour;
  @Input() participants;
  @Input() parent;

  @Output() onClickAction =  new EventEmitter();
  @Output() onChangeSelected =  new EventEmitter();
  @Output() onLinkClick =  new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  linkClicked() {
    // if(!this.omitDefaultEventBehaviour) {
    //   this.onClickAction.emit(this.actions[0]);
    // } else {
    //   this.onClickAction.emit();
    // }

    this.onLinkClick.emit();
  }

  onClickActionEvent(action) {
    this.onClickAction.emit(action);
  }

  onChange(event) {
    this.onChangeSelected.emit(event)
  }

  private isPendingSync(pendingSync) {
    return (Object.keys(pendingSync || { })).find((key) => {
      return pendingSync[key];
    });
  }

  get computePendingSync() {
    return this.isPendingSync(this.pendingSync);
  }

}
