import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import * as actions from '../list-luminaries/list-luminaries.actions';
@Component({
  selector: 'app-luminary',
  templateUrl: './luminary.component.html',
  styleUrls: ['./luminary.component.css']
})
export class LuminaryComponent implements OnInit, OnDestroy {

  id;
  loading;
  luminary;
  queryParamsObserver;
  constructor(
    private route: ActivatedRoute,
    private apiManager: ApiManagerService,
    private location: Location,
    private store: Store<AppState>,
  ) {
    this.store.dispatch(
      actions.initState()
    );
  }

  ngOnDestroy(): void {
    this.queryParamsObserver.unsubscribe();
  }
  ngOnInit(): void {
    this.queryParamsObserver = this.route.queryParams.subscribe(params => {
      this.id = params.id;
      
      this.store.select('luminaries').subscribe((luminaries) => {
        this.luminary = luminaries.find(({id}) => id == this.id);

        if (this.luminary) {
          return;
        }
        this.loading = true;
        this.apiManager.getLuminaryDetail(this.id).subscribe((luminary) => {
          this.luminary = luminary;
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      });
    });
  }

  get routeState() {
    return { luminary: this.luminary };
  }
}
