import { Injectable } from '@angular/core';
const MIME_TYPE = 'image/jpeg';

@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {
  private cacheName: string = 'images';

  constructor() { }

  public async put(name, base64) {
    const cache = await caches.open(this.cacheName);
    const blob = await fetch(base64).then(res => res.blob());
    const resp = new Response(
        blob, {
            status: 200,
            statusText: 'ok',
            headers: {
                'content-type': MIME_TYPE,
                'content-length': `${blob.size}`
            }
        }
    )
    const path = `/images/base64/${name}`;
    await cache.put(path, resp);

    return path;
  }

  public async get(name) {
    const cache = await caches.open(this.cacheName);
    return cache.match(name).then(cachedResponse => {
      return cachedResponse;
    });
  }

  public async getBase64(photo, compress = false) {
    const response = await this.get(photo);
    let buffer = await response.blob();
    if (compress) {
      buffer = await this.compress(buffer);
    }
    const base64 = await this.blobToBase64(buffer)

    return base64;
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();

      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  getImage(blob): Promise<HTMLImageElement> {
    return new Promise((resolve, _) => {
      const blobURL = URL.createObjectURL(blob);
      const img = new Image();
      img.onload = ()  => {
        resolve(img);
      };
      img.src = blobURL;
      
      
    })
  }

  async compress(blob): Promise<Blob> {
    const image = await this.getImage(blob);
    const canvas = document.createElement('canvas');
    const { width, height } = image;
    const ctx = canvas.getContext('2d');

    ctx.canvas.width  = width;
    ctx.canvas.height = height;

    ctx.drawImage(image, 0, 0, width, height, 0, 0, width, height);
    return new Promise((resolve, _) => {
      canvas.toBlob( (blob) => resolve(blob), MIME_TYPE, 0.3);
    });
  }
}
