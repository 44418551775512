import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '../../../services/api-manager.service';
import { SessionStore } from '../../../stores/session.store';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-contact-info',
  templateUrl: './edit-contact-info.component.html',
  styleUrls: ['./edit-contact-info.component.css']
})
export class EditContactInfoComponent implements OnInit {

  profile;
  loading;
  selectedUser;
  loadingData;
  id;
  user;

  constructor(
    private apiManager: ApiManagerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.loadingData = true;
    const sessionStore = SessionStore.getInstance();

    this.user = sessionStore.get().user;

    this.route.queryParams.subscribe(params => {
      this.id = '';

      if (!params?.id) {
        this.selectedUser = sessionStore.get().user;

        this.setFormData();
      } else {
        this.id = params.id;
        this.loadingData = true;

        this.apiManager.getUserDetail(this.id).subscribe((user) => {
          this.selectedUser = user;
          this.setFormData();
          this.loadingData = false;
        });
      }
    });
  }

  private setFormData() {
    this.profile = new UntypedFormGroup({
      address: new UntypedFormControl(this.selectedUser.address || '', [Validators.required]),
      phone: new UntypedFormControl(this.selectedUser.phone || '', [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))]),
      mobilePhone: new UntypedFormControl(this.selectedUser.mobilePhone || '', [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))]),
    })
  }

  save(){
    const { address, phone, mobilePhone } = this.profile.controls;

    this.loading = true;
    this.apiManager.updateUser(this.selectedUser.id, {
      address: address.value, phone: phone.value, mobilePhone: mobilePhone.value
    }).subscribe(() => {
      this.loading = false;

      if (this.selectedUser.id === this.user.id) {
        SessionStore.updateUserInfo({
          address: address.value,
          phone: phone.value,
          mobilePhone: mobilePhone.value
        });
      }

    }, (data) => {
      this.loading = false;
    })
  }

}
