import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'input-material-value',
  templateUrl: './input-material-value.component.html',
  styleUrls: ['./input-material-value.component.css']
})
export class InputMaterialValueComponent implements OnInit {

  @Input() forceText = false;
  @Input() value = 1;
  @Input() max;
  @Input() min = 1;
  @Input() material;
  @Output() valueChange = new EventEmitter<number>();
  @Output() remove = new EventEmitter();
  @Input() showRemoveOption = false;

  valueFormUnit;

  get unit() {
    return this.material?.reference?.unit;
  }

  constructor() { }

  ngOnInit(): void {
    this.valueFormUnit = new UntypedFormGroup({
      value: new UntypedFormControl(this.value || this.min, [Validators.required]),
    });

    this.valueFormUnit.valueChanges.subscribe(async (value) => {
      const numberValue = parseInt(value.value, 10);

      if (!isNaN(numberValue) && numberValue < this.min) {
        this.valueFormUnit.controls['value'].setValue(this.min);
      }
      if (this.max && !isNaN(numberValue) && numberValue > this.max) {
        this.valueFormUnit.controls['value'].setValue(this.max);
      }
      this.valueChange.emit(value.value);
    })
  }

  doRemove() {
    this.remove.emit();
  }

}
