import { Injectable } from '@angular/core';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import { zip, from, of } from 'rxjs';
import { concatMap, tap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as actions from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.actions';
import * as actionsLuminaries from '@ildes/views/list-luminaries/list-luminaries.actions';
import { SessionStore } from '@ildes/stores/session.store';
import { ImageCacheService } from './image-cache.service';
import questionsMaintenance from '@ildes/views/repair-maintenance/questions-maintenance';

@Injectable({
  providedIn: 'root'
})
export class SyncDataService {
  constructor(
    public apiManagerService: ApiManagerService,
    private store: Store,
    private cacheService: ImageCacheService
  ) { }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  getBase64FromCache(photo) {
    return new Promise(async (resolve) => {
      const response = await this.cacheService.get(photo);
      const buffer = await response.blob();
      const base64 = await this.blobToBase64(buffer)

      resolve(base64);
    })
  }

  syncMaintenance(maintenance, force = {}, updateUser = true) {
    let data;

    const pendingSync = {...maintenance?.pendingSync || {}, ...force};
    const isPendingSync = Object.values(pendingSync).find((value) => value === true);

    if (!isPendingSync) {
      return of(null);
    }

    let performInitialPhotos = of(null);
    let performFinalPhotos = of(null);

    if (maintenance.photos?.initial && pendingSync?.photosinitial) {
      performInitialPhotos = from(Promise.all(maintenance.photos.initial.map((photo) => {
        return new Promise((resolve) => {
          if (!photo.includes('/base64/')) {
            resolve(photo);

            return;
          }
          this.getBase64FromCache(photo).then((photo64) => {
            resolve(photo64);
          });
        });
      })));
  
    } 

    if (maintenance.photos?.final && pendingSync?.photosfinal) {
      performFinalPhotos = from(Promise.all(maintenance.photos.final.map((photo) => {
        return new Promise((resolve) => {
          if (!photo.includes('/base64/')) {
            resolve(photo);

            return;
          }
          this.getBase64FromCache(photo).then((photo64) => {
            resolve(photo64);
          });
        });
      })));
    }

    return zip(
      performInitialPhotos,
      performFinalPhotos
    ).pipe((
      concatMap(([initialPhotos, finalPhotos]) => {
        const photos:any = {};

        if (initialPhotos) {
          photos.initial = initialPhotos
        }
        if (finalPhotos) {
          photos.final = finalPhotos
        }

        data = {
          ...maintenance.answers && pendingSync.questionary && {
            questions: questionsMaintenance,
            answers: maintenance.questionary
          },
          ...maintenance.openDate && pendingSync.questionary && { openDate: maintenance.openDate },
          ...maintenance.luminary && pendingSync.luminary && { luminary: maintenance.luminary.number },
          ...Object.values(photos).length && { photos },
          state: maintenance.state,
          ...maintenance.localUsedMaterials && pendingSync.repairmentData && { usedMaterials: maintenance.usedMaterials },
          ...maintenance.extractedMaterials && pendingSync.repairmentData && { extractedMaterials: maintenance.extractedMaterials },
          ...maintenance.description && pendingSync.repairmentData && { description: maintenance.description },
          ...maintenance.height && pendingSync.luminary && { height: maintenance.height },
          ...maintenance.ascendMethod && pendingSync.luminary && { ascendMethod: maintenance.ascendMethod },
          ...maintenance.repairmentDate && pendingSync.state && { repairmentDate: maintenance.repairmentDate }
        };
        return this.apiManagerService
          .updateMaintenance(maintenance.id, data).pipe(
            tap( () => {
              this.store.dispatch(
                actions.sync({
                  maintenanceId: maintenance.id
                })
              )
            }),
            tap(() => {
              if (!updateUser) {
                return;
              }
              this.apiManagerService.getUserDetail(maintenance.technician.id).subscribe((user) => {
                SessionStore.updateUserInfo(user, true);
              })
            })
          );
      })
    ))      
  }

  syncLuminary(luminary){
    const subscriber = luminary.id ?
      this.apiManagerService.updateLuminary(luminary.id, luminary):
      this.apiManagerService.createLuminary(luminary);

    
    return subscriber.pipe(
      tap( () => {
        this.store.dispatch(
          actionsLuminaries.remove({
            _id: luminary._id
          })
        )
      }),
    );
  }
}
