import { Component, OnInit, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'list-user-item',
  templateUrl: './list-user-item.component.html',
  styleUrls: ['./list-user-item.component.css']
})
export class ListUserItemComponent implements OnInit {

  @Input() name;
  @Input() surname;
  @Input() role;
  @Input() email;
  @Input() phone;
  @Input() photo;
  @Output() onLinkClick =  new EventEmitter();
  @ContentChild('actions',{ static: false }) actionsTemplateRef: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

  linkClicked() {
    this.onLinkClick.emit();
  }

}
