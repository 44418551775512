import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.css']
})
export class InputFileComponent implements OnInit {

  @Input() title;
  @Input() icon;
  @Input() accept;

  @ViewChild("inputfile") inputfileView: ElementRef;
  @Output() selectedFile: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  onButtonClick() {
    this.inputfileView.nativeElement.click();
  }

  onSelectImage(event) {
    this.selectedFile.emit(event.target.files[0]);
  }

}
