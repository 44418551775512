import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '../basic/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private matSnackBar: MatSnackBar
  ) { }

  show(description, type = 'error') { // ok, error, warning
    this.matSnackBar.openFromComponent(ToastComponent, {
      data: { description, type },
      duration: 3000,
      panelClass: type
    });
  }
}
