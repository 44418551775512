import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

const SHOW_MATERIAL = {
  icon: 'chevron_right',
  id: 'show_material',
  description: 'Ver material',
  disabled: false
};

const ACTIONS = [ SHOW_MATERIAL ];

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.css']
})
export class TechniciansComponent implements OnInit {

  actions = ACTIONS;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {

  }
  onDoAction({ user }) {
    this.router.navigate(['/app/technicians/detail'], { queryParams: { id: user.id } });
  }


}
