import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NativeLayerService } from '@ildes/services/native-layer.service';
import { environment } from '../../environments/environment';

const WHITE_LIST = ['/public', '/session'];

const HOST = environment.host;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private native: NativeLayerService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
    let headers = req.headers;

    if (this.native.isNative) {
      headers = headers.set('uuid', this.native.uuid);
    }
    if (WHITE_LIST.filter( (signature) => req.url.indexOf(signature) >= 0 ).length) {
      return next.handle(req.clone({ headers }));
    }
    // TODO: bindear con una lista de hosts genérica, como en @223344
    if (req.url.indexOf(HOST) <= 0) {
      return next.handle(req);
    }

    if (!token) {
      return next.handle(req);
    }
    headers = headers.set('jwt', token);
    
    const authReq = req.clone({
      headers
    });

    return next.handle(authReq).pipe(
      tap((res: any) => {
        if (res.type === 0){
            return;
        }
        const jwt = res.headers.get('jwt');

        if (!jwt) {
          return;
        }

        sessionStorage.setItem('token', jwt);
        localStorage.setItem('token', jwt);
      }),
    )
  }
}
