import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router } from "@angular/router";

import * as Sentry from "@sentry/angular-ivy";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ImageCropperModule } from 'ngx-image-cropper';

import { GoogleMapsModule } from '@angular/google-maps'

import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import { SigninComponent } from './views/signin/signin.component';
import { TechnicianDashboardMaterialsComponent } from './views/technician-dashboard-materials/technician-dashboard-materials.component';
import { MainComponent } from './views/main/main.component';
import { httpInterceptorProviders } from './http-interceptors/index';
import { FormsModule } from '@angular/forms';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as maintenancesReducer from './views/list-maintenances/maintenances.reducer';
import * as assignedMaintenancesReducer from './views/technician-dashboard-maintenances/assigned-maintenances.reducer';
import * as dailyQuestionariesReducer from './views/technician-dashboard-maintenances/daily-questionaries.reducer';
import * as listLuminariesReducer from './views/list-luminaries/list-luminaries.reducer';

import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ProfileAvatarComponent } from './basic/profile-avatar/profile-avatar.component';
import { CompositionBasicProfileComponent } from './composition/composition-basic-profile/composition-basic-profile.component';
import { ToastComponent } from './basic/toast/toast.component';
import { ProfileComponent } from './views/profile/profile.component';
import { EditProfileComponent } from './views/profile/edit-profile/edit-profile.component';
import { EditContactInfoComponent } from './views/profile/edit-contact-info/edit-contact-info.component';
import { EditDocumentationAndHhrrInformationComponent } from './views/profile/edit-documentation-and-hhrr-information/edit-documentation-and-hhrr-information.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { DateSelectorComponent } from './basic/date-selector/date-selector.component';
import { ControlPanelComponent } from './views/control-panel/control-panel.component';
import { ListUsersComponent } from './views/list-users/list-users.component';
import { ListUserItemComponent } from './basic/list-user-item/list-user-item.component';
import { CreateUserComponent } from './views/create-user/create-user.component';
import { CustomPaginator } from './config/CustomPaginatorConfiguration';
import { ChangePhotoDialogComponent } from './basic/change-photo-dialog/change-photo-dialog.component';
import { InputFileComponent } from './basic/input-file/input-file.component';
import { ImagePathPipe } from './pipes/image-path.pipe';
import { ChangePasswordComponent } from './views/profile/change-password/change-password.component';
import { MaterialComponent } from './views/material/material.component';
import { CreateMaterialComponent } from './views/create-material/create-material.component';
import { ListMaterialItemComponent } from './basic/list-material-item/list-material-item.component';
import { TestComponent } from './views/test/test.component';
import { ListProjectsComponent } from './views/list-projects/list-projects.component';
import { CreateProjectComponent } from './views/create-project/create-project.component';
import { ListProjectItemComponent } from './basic/list-project-item/list-project-item.component';
import { FormatDiffDatePipe } from './pipes/format-diff-date.pipe';
import { SimpleHeaderComponent } from './basic/simple-header/simple-header.component';
import { CalcEndDatePipe } from './pipes/calc-end-date.pipe';
import { CreateLuminairesComponent } from './views/create-luminaires/create-luminaires.component';
import { DropdownSelectorComponent } from './basic/dropdown-selector/dropdown-selector.component';
import { ListUsersCompositionComponent } from './business-compositions/list-users-composition/list-users-composition.component';
import { SelectUsersDialogComponent } from './basic/select-users-dialog/select-users-dialog.component';
import { PredictiveSearcherComponent } from './basic/predictive-searcher/predictive-searcher.component';
import { KeyValueItemComponent } from './basic/key-value-item/key-value-item.component';
import { ResumeLuminaireComponent } from './composition/resume-luminaire/resume-luminaire.component';
import { InputPlaceComponent } from './basic/input-place/input-place.component';
import { ListLuminariesComponent } from './views/list-luminaries/list-luminaries.component';
import { ListLuminaryItemComponent } from './basic/list-luminary-item/list-luminary-item.component';
import { TagAutomaticColorComponent } from './basic/tag-automatic-color/tag-automatic-color.component';
import { SelectPhotoDialogComponent } from './basic/select-photo-dialog/select-photo-dialog.component';
import { ListAsocMaterialComponent } from './views/list-asoc-material/list-asoc-material.component';
import { NumberIncrementComponent } from './basic/number-increment/number-increment.component';
import { AdvancedSearchLuminaryDialogComponent } from './views/advanced-search-luminary-dialog/advanced-search-luminary-dialog.component';
import { FilterListComponent } from './basic/filter-list/filter-list.component';
import { AllowActionDirective } from './directives/allow-action.directive';
import { LuminaryComponent } from './views/luminary/luminary.component';
import { CreateMaintenanceComponent } from './views/create-maintenance/create-maintenance.component';
import { ListMaintenancesComponent } from './views/list-maintenances/list-maintenances.component';
import { ListMaintenanceItemComponent } from './basic/list-maintenance-item/list-maintenance-item.component';
import { HumanizeDescriptionMaterialPipe } from './pipes/humanize-description-material.pipe';
import { AssignToTechnicalComponent } from './views/list-maintenances/assign-to-technical/assign-to-technical.component';
import { ContextualActionComponent } from './basic/contextual-action/contextual-action.component';
import { ListMaterialsAssignedComponent } from './views/list-materials-assigned/list-materials-assigned.component';
import { TechniciansComponent } from './views/technicians/technicians.component';
import { TechniciansDetailComponent } from './views/technicians/detail/detail.component';
import { UserSettingsDialogComponent } from './basic/user-settings-dialog/user-settings-dialog.component';
import { ErrorBulkLuminariesDialogComponent } from './basic/error-bulk-luminaries-dialog/error-bulk-luminaries-dialog.component';
import { TechnicianDashboardMaintenancesComponent } from './views/technician-dashboard-maintenances/technician-dashboard-maintenances.component';
import { RepairMaintenanceComponent } from './views/repair-maintenance/repair-maintenance.component';
import { ListStepStateItemComponent } from './basic/list-step-state-item/list-step-state-item.component';
import { MaintenanceQuestionaryDialogComponent } from './basic/maintenance-questionary-dialog/maintenance-questionary-dialog.component';
import { environment } from '../environments/environment';
import { LuminarySearcherDialogComponent } from './basic/luminary-searcher-dialog/luminary-searcher-dialog.component';
import { GalleryListComponent } from './basic/gallery-list/gallery-list.component';
import { GalleryDialogComponent } from './basic/gallery-dialog/gallery-dialog.component';
import { UseMaterialDialogComponent } from './basic/use-material-dialog/use-material-dialog.component';
import { HumanizeStateComponent } from './basic/humanize-state/humanize-state.component';
import { FilteredListMaintenancesComponent } from './views/list-maintenances/filtered-list-maintenances/filtered-list-maintenances.component';
import { CreateAsocMaterialComponent } from './views/create-asoc-material/create-asoc-material.component';
import { ListAsocMaterialItemComponent } from './basic/list-asoc-material-item/list-asoc-material-item.component';
import { EditLuminaryIdentificationDataComponent } from './views/luminary/edit-luminary-identification-data/edit-luminary-identification-data.component';
import { EditPostAndLuminaryDataComponent } from './views/luminary/edit-post-and-luminary-data/edit-post-and-luminary-data.component';
import { InputMaterialValueComponent } from './basic/input-material-value/input-material-value.component';
import { EffectsModule } from '@ngrx/effects';
import { MaintenancesEffects } from '@ildes/views/technician-dashboard-maintenances/assigned-maintenances.effects'
import { DailyQuestionaryEffects } from '@ildes/views/technician-dashboard-maintenances/daily-questionaries.effects'
import { storeData } from '@ildes/rxjs/store-data';
import { ConnectionAlertComponent } from './basic/connection-alert/connection-alert.component';
import { FilterActionsMaintenancePipe } from './pipes/filter-actions-maintenance.pipe';
import { PullToRefreshComponent } from './basic/pull-to-refresh/pull-to-refresh.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { EditProjectComponent } from './views/edit-project/edit-project.component';
import { AlertDialogComponent } from './basic/alert-dialog/alert-dialog.component';
import { LeaderDashboardComponent } from './views/leader-dashboard/leader-dashboard.component';
import { EditAsocMaterialComponent } from './views/edit-asoc-material/edit-asoc-material.component';
import { DetailMaintenanceComponent } from './views/detail-maintenance/detail-maintenance.component';
import { MailboxComponent } from './views/mailbox/mailbox.component';
import { ListStoresComponent } from './views/list-stores/list-stores.component';
import { ListStoreItemComponent } from './basic/list-store-item/list-store-item.component';
import { TaskDetailComponent } from './views/task-detail/task-detail.component';
import { LoadingComponent } from './basic/loading/loading.component';
import { ImagePanelComponent } from './basic/image-panel/image-panel.component';
import { CreateStoreComponent } from './views/create-store/create-store.component';
import { ChangeMaterialListDialogComponent } from './views/change-material-list-dialog/change-material-list-dialog.component';
import { ListMaintenancesPendingSyncDialogComponent } from './views/list-maintenances-pending-sync-dialog/list-maintenances-pending-sync-dialog.component';
import { StoreItemsComponent } from './views/store-items/store-items.component';
import { AddStoreItemsComponent } from './views/add-store-items/add-store-items.component';
import { RememberPasswordComponent } from './views/remember-password/remember-password.component';
import { CreateLuminaryDistributiveComponent } from './views/create-luminary-distributive/create-luminary-distributive.component';
import { CreateExpansionComponent } from './views/create-expansion/create-expansion.component';
import { CreateModernizationComponent } from './views/create-modernization/create-modernization.component';
import { CreateLuminaryDialogComponent } from './views/create-luminary-dialog/create-luminary-dialog.component';
import { BottomNavigationComponent } from './basic/bottom-navigation/bottom-navigation.component';
import { TechnicianDashboardComponent } from './views/technician-dashboard/technician-dashboard.component';
import { TechnicianDashboardExpansionsComponent } from './views/technician-dashboard-expansions/technician-dashboard-expansions.component';
import { TechnicianDashboardModernizationsComponent } from './views/technician-dashboard-modernizations/technician-dashboard-modernizations.component';
import { SelectProjectDialogComponent } from './basic/select-project-dialog/select-project-dialog.component';
import { ExpansionDataDialogComponent } from './views/expansion-data-dialog/expansion-data-dialog.component';
import { ModernizationDataDialogComponent } from './views/modernization-data-dialog/modernization-data-dialog.component';
import { TaskIdentificationDialogComponent } from './views/task-identification-dialog/task-identification-dialog.component';
import { MaintenanceListDialogComponent } from './views/maintenance-list-dialog/maintenance-list-dialog.component';
export const metaReducers: MetaReducer<any>[] = [storeData];

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    MainComponent,
    DashboardComponent,
    ProfileAvatarComponent,
    CompositionBasicProfileComponent,
    ToastComponent,
    ProfileComponent,
    EditProfileComponent,
    EditContactInfoComponent,
    EditDocumentationAndHhrrInformationComponent,
    FormatDatePipe,
    DateSelectorComponent,
    ControlPanelComponent,
    ListUsersComponent,
    ListUserItemComponent,
    CreateUserComponent,
    ChangePhotoDialogComponent,
    InputFileComponent,
    CreateStoreComponent,
    ImagePathPipe,
    ChangePasswordComponent,
    MaterialComponent,
    CreateMaterialComponent,
    ListMaterialItemComponent,
    TestComponent,
    ListProjectsComponent,
    CreateProjectComponent,
    ListProjectItemComponent,
    FormatDiffDatePipe,
    SimpleHeaderComponent,
    ListStoresComponent,
    ListStoreItemComponent,
    CalcEndDatePipe,
    CreateLuminairesComponent,
    DropdownSelectorComponent,
    ListUsersCompositionComponent,
    SelectUsersDialogComponent,
    PredictiveSearcherComponent,
    KeyValueItemComponent,
    ResumeLuminaireComponent,
    InputPlaceComponent,
    ListLuminariesComponent,
    ListLuminaryItemComponent,
    TagAutomaticColorComponent,
    SelectPhotoDialogComponent,
    ListAsocMaterialComponent,
    NumberIncrementComponent,
    AdvancedSearchLuminaryDialogComponent,
    FilterListComponent,
    AllowActionDirective,
    LuminaryComponent,
    CreateMaintenanceComponent,
    ListMaintenancesComponent,
    ListMaintenanceItemComponent,
    HumanizeDescriptionMaterialPipe,
    AssignToTechnicalComponent,
    ContextualActionComponent,
    ListMaterialsAssignedComponent,
    TechniciansComponent,
    TechniciansDetailComponent,
    UserSettingsDialogComponent,
    ErrorBulkLuminariesDialogComponent,
    TechnicianDashboardMaintenancesComponent,
    TechnicianDashboardComponent,
    RepairMaintenanceComponent,
    ListStepStateItemComponent,
    MaintenanceQuestionaryDialogComponent,
    LuminarySearcherDialogComponent,
    GalleryListComponent,
    GalleryDialogComponent,
    UseMaterialDialogComponent,
    HumanizeStateComponent,
    FilteredListMaintenancesComponent,
    CreateAsocMaterialComponent,
    ListAsocMaterialItemComponent,
    EditLuminaryIdentificationDataComponent,
    EditPostAndLuminaryDataComponent,
    InputMaterialValueComponent,
    ConnectionAlertComponent,
    FilterActionsMaintenancePipe,
    PullToRefreshComponent,
    PrivacyComponent,
    EditProjectComponent,
    AlertDialogComponent,
    LeaderDashboardComponent,
    EditAsocMaterialComponent,
    DetailMaintenanceComponent,
    MailboxComponent,
    TaskDetailComponent,
    LoadingComponent,
    ImagePanelComponent,
    ChangeMaterialListDialogComponent,
    StoreItemsComponent,
    AddStoreItemsComponent,
    ListMaintenancesPendingSyncDialogComponent,
    RememberPasswordComponent,
    CreateLuminaryDistributiveComponent,
    CreateExpansionComponent,
    CreateLuminaryDialogComponent,
    BottomNavigationComponent,
    TechnicianDashboardMaterialsComponent,
    TechnicianDashboardExpansionsComponent,
    TechnicianDashboardModernizationsComponent,
    SelectProjectDialogComponent,
    ExpansionDataDialogComponent,
    ModernizationDataDialogComponent,
    TaskIdentificationDialogComponent,
    CreateModernizationComponent,
    MaintenanceListDialogComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({
      maintenances: maintenancesReducer.reducer,
      assignedMaintenances: assignedMaintenancesReducer.reducer,
      dailyQuestionaries: dailyQuestionariesReducer.reducer,
      luminaries: listLuminariesReducer.reducer
    }, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    GoogleMapsModule,
    EffectsModule.forRoot([MaintenancesEffects, DailyQuestionaryEffects]),
    ServiceWorkerModule.register('./sw.js', { enabled: true })
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    httpInterceptorProviders,
    { provide: MatPaginatorIntl, useValue: new CustomPaginator() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
