import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@ildes/app.reducer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SyncDataService } from '@ildes/services/sync-data.service';
import { tap, map, toArray } from 'rxjs/operators';
import { Observable, concat, defer } from 'rxjs';
import { ApiManagerService } from '@ildes/services/api-manager.service';
import * as actionsLuminaries from '@ildes/views/list-luminaries/list-luminaries.actions';
import { SessionStore } from '@ildes/stores/session.store';

function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) =>  Observable<T> {
  return function inner(source: Observable<T>): Observable<T> {
      return defer(() => {
        onSubscribe();
        return source;
      });
  };
}

const DISCARD_CHANGES = {
  id: 'discard',
  description: 'Descartar',
  icon: 'trash'
};
@Component({
  selector: 'app-list-maintenances-pending-sync-dialog',
  templateUrl: './list-maintenances-pending-sync-dialog.component.html',
  styleUrls: ['./list-maintenances-pending-sync-dialog.component.css']
})
export class ListMaintenancesPendingSyncDialogComponent implements OnInit {
  maintenances;
  luminaries;
  loading = {};
  error = false;
  actionsLuminary = [
    DISCARD_CHANGES
  ]
  constructor(
    private store: Store<AppState>,
    public syncData: SyncDataService,
    public dialogRef: MatDialogRef<ListMaintenancesPendingSyncDialogComponent>,
    private apiManager: ApiManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.maintenances = data.maintenances;
    this.luminaries = data.luminaries;
  }
  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ close: true });
  }

  selectMaintenance(selectedMaintenance) {
    this.dialogRef.close({ selectedMaintenance });
  }
  selectLuminary(selectedLuminary) {
    this.dialogRef.close({ selectedLuminary });
  }

  sync() {
    this.error = false;
    const requestsLuminaries = [...this.luminaries].map((luminary) => {  
      return this.syncData.syncLuminary(luminary).pipe(
        doOnSubscribe(() => {
          this.loading[luminary.number] = true;
        }),
        tap(() => ({
          next: this.loading[luminary.number] = false
        })), 
        map(() => {
          return luminary;
        })
      )
    });

    const requests = [...this.maintenances].map((maintenance) => {
      return this.syncData.syncMaintenance(maintenance, {}, false).pipe(
        doOnSubscribe(() => {
          this.loading[maintenance.id] = true;
        }),
        tap(() => ({
          next: this.loading[maintenance.id] = false
        })), 
        map(() => {
          return maintenance;
        })
      )
    });
    const userId = SessionStore.getInstance().get().user.id;

    const concatRequestes = concat(...[...requestsLuminaries, ...requests])

    concatRequestes.subscribe((item) => {
      this.luminaries = this.luminaries.filter(({number}) => number !== item.number)
      this.maintenances = this.maintenances.filter(({id}) => id !== item.id)
      if (this.maintenances?.length) {
        return;
      }
      this.apiManager.getUserDetail(userId).subscribe((user) => {
        SessionStore.updateUserInfo(user, true);
      })
    }, (error) => {
      this.loading = {};
      this.apiManager.getUserDetail(userId).subscribe((user) => {
        SessionStore.updateUserInfo(user, true);
      });
      if (error && error.code <= 0) {
        return;
      }
      this.error = true;
    });
  }

  get pendingRequest() {
    return Object.values(this.loading).some((value) => value );
  }

  onActionClick(luminary, event) {
    this.luminaries = this.luminaries.filter(({_id}) => _id !== luminary._id)
    switch(event.id) {
      case DISCARD_CHANGES.id:
        this.store.dispatch(
          actionsLuminaries.remove({
            _id: luminary._id
          })
        );
        break;
      default:
        break;
    }
  }
}
