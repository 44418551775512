import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from '../../services/api-manager.service';
import { UntypedFormControl, NgForm, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchLuminaryDialogComponent } from '../../views/advanced-search-luminary-dialog/advanced-search-luminary-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-luminaries',
  templateUrl: './list-luminaries.component.html',
  styleUrls: ['./list-luminaries.component.css']
})
export class ListLuminariesComponent implements OnInit {
  filter: any;
  labelByFilter = {
    project: 'Proyecto:',
    number: 'Número:',
    withoutNumber: 'Sin número',
    location: 'Ubicación:',
    neighborhood: 'Barrio:',
  };
  luminaries;
  pagination;
  loading;
  currentPage = 0;
  searchProjectAdapter;
  searchFormGroup = new UntypedFormGroup({
    selectedProjectForm: new UntypedFormControl(),
    number: new UntypedFormControl(),
  });
  constructor(
    private apiManager: ApiManagerService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.searchProjectAdapter = (data) => {
      return this.apiManager.listProjects(data);
    };
    this.list();
  }

  paginate(event) {
    this.list(event.pageIndex);
  }

  list(pageIndex = 0) {
    this.luminaries = undefined;
    this.loading = true;
    this.currentPage = pageIndex;
    const page = pageIndex ? { page: pageIndex + 1 } : {};
    const filter: any = this.getFilter();

    this.apiManager.listLuminaries({...page, ...filter}).subscribe((luminaries: any) => {
      this.loading = false;
      this.luminaries = luminaries.data;
      this.pagination = luminaries.pagination;
    }, (data) => {
      this.loading = false;
    });
  }

  onSelectedValue({ option }) {
    this.currentPage = 0;
    this.filter = { project: Object.assign({}, { selected: option.value }, { typed: option.value.name }) };

    this.list();
  }

  findByNumber() {
    this.filter = { number: this.searchFormGroup.get('number').value };

    this.list();
  }

  advancedSearch() {
    const dialog = this.dialog.open(AdvancedSearchLuminaryDialogComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'responsive-dialog',
      data: { filter: this.filter }
    });

    dialog.afterClosed().subscribe((data = { closed: false }) => {

      if (data.closed) {
        return;
      }
      this.filter = data;
      this.list();

    });
  }

  get humanizeFilter(): Array<any> {
    if (!this.filter) {
      return [];
    }

    const keys = Object.keys(this.filter || {});
    return keys.filter( (criteria) => this.filter[criteria] || this.filter[criteria] === false).map((key) => ({
      key: this.labelByFilter[key],
      // value: this.filter[key]
      value: this.humanizeValue(key, this.filter[key])
    }));
  }

  humanizeValue(key, value) {
    switch(key) {
      case 'number':
        return value;
      break;
      case 'withoutNumber':
        return '';
      break;
      case 'location':
        return `(${value.lat}, ${value.lng})`;
      break;
      default:
        return value.typed;
    }
  }

  removeFilters() {
    this.filter = null;

    this.pagination = null;
    this.list();
  }

  private getFilter() {
    const filter: any = {};

    if (this.filter?.project?.selected.id) {
      filter.projectId = this.filter.project.selected.id;
    }

    if (this.filter?.neighborhood) {
      filter.neighborhood = this.filter.neighborhood.typed;
    }

    if (typeof this.filter?.withoutNumber === 'boolean' && this.filter?.withoutNumber) {
      filter.number = null;
    } else if (this.filter?.number) {
      filter.number = this.filter.number;
    }

    if (this.filter?.location) {
      filter.latitude = this.filter.location.lat;
      filter.longitude = this.filter.location.lng;
      filter.maxDistance = 50;
    }

    if (this.filter?.number) {
      filter.number = this.filter.number;
    }

    return filter;
  }

  gotoLuminaryDetail(id) {
    this.router.navigate(['/app/luminary'], { queryParams: { id: id } });
  }

}
