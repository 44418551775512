import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, ViewChild } from '@angular/core';
import { CreateLuminaryDialogComponent } from '../create-luminary-dialog/create-luminary-dialog.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '@ildes/services/api-manager.service';

@Component({
    selector: 'app-modernization-data-dialog',
    template: `
    <simple-header title="Datos de la modernización" (action)="close()" icon="close"></simple-header>
    <mat-vertical-stepper [linear]="true" #stepper>
     <form [formGroup]="materialSeacherForm" class="padding-top-8">
      <mat-step [completed]="false">
        <ng-template matStepLabel>Material usado en la expansión</ng-template>
        <div *ngFor="let material of usedMaterials">
          <div class="material padding-top-8 padding-bottom-8">
            <div class="fill center">
              <div>
              {{material.reference.name}}
              </div>
              <div class="description padding-top-8">
                Máximo {{material.max}}
              </div>
            </div>
            <input-material-value
              [min]="0"
              [max]="material.max"
              class="vertical-center"
              [material]="material"
              [(value)]="material.amount"></input-material-value>
          </div>
          <mat-divider></mat-divider>
        </div>
        <button (click)="continue()" mat-button color="primary" class="margin-top-16">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Material retirado </ng-template>
          <div class="inline-flex">
            <predictive-searcher
              formControlName="materialName"
              label="Añadir material"
              [service]="searchMaterialAdapter"
              (selectedValue)="onSelectedValue($event)"
              class="column margin-right-8"
              ></predictive-searcher>
          </div>
          <div class="flex-row">
            <button class="right" (click)="addUnknownMaterial()" [disabled]="!typedRetiredMaterial" mat-button>Añadir</button>
          </div>
        <div *ngFor="let material of extractedMaterials">
          <div class="material padding-top-8 padding-bottom-8">
            <div class="fill center">
              <div>
              {{material.reference.name}}
              </div>
            </div>
            <input-material-value
              [min]="0"
              class="vertical-center"
              [material]="material"
              [(value)]="material.amount"></input-material-value>
          </div>
          <mat-divider></mat-divider>
        </div>
        <button mat-button (click)="continue()" color="primary">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Datos de la modernización </ng-template>
        <list-luminary-item
            *ngIf="luminary"
            [number]="luminary.number"
            [project]="project?.name || 'Sin proyecto'"
            [address]="luminary.address"
            [ubication]="luminary.geolocation"
            [neighborhood]="luminary.neighborhood"
            [technology]="luminary.technology"
            [power]="luminary.power"
        ></list-luminary-item>
        <h3>Nueva tecnología y potencia</h3>
        <div class="inline-flex">
          <div class="inline-flex column">
            <predictive-searcher
              [required]="true"
              label="Tecnología"
              formControlName="technologyForm"
              [service]="searchLuminaryTypesAdapter"
              class="column margin-right-8"></predictive-searcher>
            <mat-form-field appearance="outline" class="column margin-right-column-8">
              <mat-label>Potencia</mat-label>
              <input formControlName="power" matInput maxlength="20">
              <mat-error>
                  La potencia es obligatoria
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <button [disabled]="!technology || !power" (click)="continue()" mat-button color="primary" class="margin-top-16">Continuar</button>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel> Observaciones </ng-template>
        <form [formGroup]="formDescription" autocomplete="off" class="padding-top-8">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Observaciones de la modernización</mat-label>
            <textarea rows="2" matInput formControlName="description"></textarea>
          </mat-form-field>
          <button [disabled]="formDescription.invalid" (click)="continue()" mat-button matStepperNext color="primary">Siguiente</button>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel> Confirmación </ng-template>
        <h3>Material Usado</h3>
        <div *ngIf="!usedMaterials.length" class="align-center">
          No se ha usado material para la expansión
        </div>
        <div class="padding-left-8 padding-bottom-16">
          <div *ngFor="let material of cleanListUsedMaterial" class="item">
            <list-material-item
              [name]="material.reference.name"
              [unit]="material.reference.unit"
              [amount]="material.amount"
              class="padding-top-8">
            </list-material-item>
            <mat-divider></mat-divider>
          </div>
        </div>
        <h3>Material Retirado</h3>
        <div *ngIf="!extractedMaterials.length" class="align-center">
          Ningún material retirado
        </div>
        <div class="padding-left-8 padding-bottom-16">
          <div *ngFor="let material of cleanListRetiredMaterial" class="item">
            <list-material-item
              [amount]="material.amount"
              [name]="material.reference.name"
              [unit]="material.reference.unit"
              class="padding-top-8">
            </list-material-item>
            <mat-divider></mat-divider>
          </div>
        </div>
        <h3>Datos de luminaria</h3>
        <div class="padding-left-8 padding-bottom-16">
        <list-luminary-item
            *ngIf="luminary"
            [number]="luminary.number"
            [project]="luminary.project?.name || 'Sin proyecto'"
            [address]="luminary.address"
            [ubication]="luminary.geolocation"
            [neighborhood]="luminary.neighborhood"
            [technology]="luminary.technology"
            [power]="luminary.power"
        ></list-luminary-item>
        </div>
        <h3>Nueva tecnología y potencia</h3>
        <div class="padding-left-8 padding-bottom-16">
          <div class="inline-flex">
            <tag-automatic-color icon="memory" [text]="technology" class="actionable" matRipple *ngIf="technology"></tag-automatic-color>
            <tag-automatic-color icon="offline_bolt" [text]="power" class="actionable" matRipple *ngIf="power"></tag-automatic-color>
          </div>
        </div>
        <h3>Observaciones</h3>
        <div class="description padding-left-8 padding-bottom-16">
          {{formDescription.controls?.description?.value}}
        </div>
        <button mat-button (click)="confirm()" color="primary">Guardar</button>
      </mat-step>
      </form>
    </mat-vertical-stepper>
    `,
    styleUrls: ['./modernization-data-dialog.component.css']
})
export class ModernizationDataDialogComponent {
    availableMaterials;
    usedMaterials = [];
    luminary;
    description;
    extractedMaterials;
    searchLuminaryTypesAdapter;
    searchMaterialAdapter;
    materialSeacherForm;
    formDescription;
    @ViewChild('stepper') stepper: any;

    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ModernizationDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private apiManager: ApiManagerService,
    ) {
        this.extractedMaterials = data.extractedMaterials || [];
        this.luminary = data.luminary;
        this.description = data.description;

        this.availableMaterials = (data.availableMaterials || []).map((material) => (
            {...material}
        ));
        this.materialSeacherForm = new UntypedFormGroup({
          materialName: new UntypedFormControl(''),
          technologyForm: new UntypedFormControl(data.technology ? { typed: data.technology, selected: { name: data.technology }} : ''),
          
          power: new UntypedFormControl( data.power, [Validators.required, Validators.pattern(new RegExp('^[0-9]+$'))]),
        });
        this.formDescription = new UntypedFormGroup({
          description: new UntypedFormControl(this.description),
        });
        const currentUsedMaterials = data.usedMaterials;
        this.usedMaterials = this.availableMaterials.map((availableMaterial) => {
            const currentUsedMaterial = (currentUsedMaterials || [])
                .find((usedMaterial) => usedMaterial.reference?.id === availableMaterial.reference.id);
        
            return {
                amount: currentUsedMaterial?.amount || 0,
                reference: availableMaterial.reference,
                max: availableMaterial.amount + (currentUsedMaterial?.amount || 0)
            };
        });
        (currentUsedMaterials || []).forEach((usedMaterial) => {
            const usedInAvailableMaterials = this.usedMaterials
              .find((currentUsedMaterial) => currentUsedMaterial.reference?.id === usedMaterial.reference.id);
      
            if (!usedInAvailableMaterials) {
              this.usedMaterials.push({
                amount: usedMaterial.amount,
                reference: usedMaterial.reference,
                max: usedMaterial.amount
              });
            }
        });
        this.searchMaterialAdapter = () => {
          return this.apiManager.listMaterials();
        };
        this.searchLuminaryTypesAdapter = (data) => {
          return this.apiManager.getFromCatalog('technologies', data)
        };
    }

    get cleanListUsedMaterial() {
        return this.usedMaterials.filter(({ amount }) => amount > 0);
    }

    continue() {
        this.stepper.selected.completed = true;
        this.stepper.next();
     }

    confirm() {
        this.dialogRef.close({
          luminary: this.luminary,
          usedMaterials: this.cleanListUsedMaterial,
          extractedMaterials: this.extractedMaterials,
          technology: this.technology,
          power: this.power,
          description: this.formDescription.controls?.description?.value,
        });
    }

    public close() {
        this.dialogRef.close({ closed: true });
    }

    addUnknownMaterial() {
      const name = this.materialSeacherForm?.controls?.materialName?.value?.typed;
  
      if (this.extractedMaterials.find(({ reference }) => reference.name === name)) {
        return;
      }
      const material = {
        amount: 1,
        reference: {
          name
        }
      };
      this.extractedMaterials = [...this.extractedMaterials, material];
      this.materialSeacherForm.reset();
    }

    onSelectedValue({option}) {
      if (this.extractedMaterials.find(({ reference }) => reference.name === option.value.name)) {
        return;
      }
      const material = {
        amount: 1,
        reference: option.value
      }
      
      this.extractedMaterials = [...this.extractedMaterials, material];
      this.materialSeacherForm.reset();
    }

    get typedRetiredMaterial() {
      return this.materialSeacherForm?.controls?.materialName?.value?.typed
    }
    get cleanListRetiredMaterial() {
      return this.extractedMaterials.filter(({ amount }) => amount > 0);
    }

    get technology() {
      const value = this.materialSeacherForm?.controls?.technologyForm?.value;

      return value?.selected?.name || value?.typed;
    }

    get power() {
      return this.materialSeacherForm?.controls?.power?.value;
    }
}
