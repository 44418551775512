import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectPhotoDialogComponent } from '@ildes/basic/select-photo-dialog/select-photo-dialog.component';
import { Camera, CameraResultType } from '@capacitor/camera';
import { ImageCacheService } from '@ildes/services/image-cache.service';
import { NativeLayerService } from '@ildes/services/native-layer.service';

@Component({
  selector: 'gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.css']
})
export class GalleryListComponent implements OnInit {
  selectedPhoto
  @Input() cropMode;
  @Input() photos;
  @Input() readOnly = false;
  @Input() maxPhotos = 5;
  @Output() photosChange = new EventEmitter();
  @Output() addPhoto = new EventEmitter();
  @Output() removePhoto = new EventEmitter();

  @ViewChild("inputfile") inputfileView: ElementRef;

  constructor(
      private dialog: MatDialog,
      private imageCache: ImageCacheService,
      private native: NativeLayerService,
  ) { }

  ngOnInit(): void {
  }

  async doSelectPhoto() {
    if (this.cropMode) {
      const dialog = this.dialog.open(SelectPhotoDialogComponent, {
        width: '600px',
        height: '600px',
        panelClass: 'responsive-dialog'
      });

      dialog.afterClosed().subscribe((data = { closed: false }) => {
        if (data.closed || !data.photo) {
          return;
        }

        this.addPhotoAndNotify(data.photo);
      });
    } else {
      const image = await Camera.getPhoto({
        quality: 50,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        promptLabelPhoto:   'Abrir galería    ',
        promptLabelPicture: 'Tomar una foto'
      });
      if (!image) {
        return;
      }
      let base64 = `data:image/jpeg;base64,${image.base64String}`;
      // if(!this.native.isNative) {
      //   const compressedBlob = await this.compress(base64);

      //   base64 = await this.blobToBase64(compressedBlob);
      // }
      

      this.addPhotoAndNotify(base64);
      // this.inputfileView.nativeElement.click();
    }
  }

  blobToBase64(blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();

      reader.onloadend = () => resolve(reader.result.toString());
      reader.readAsDataURL(blob);
    });
  }

  getImage(base64): Promise<HTMLImageElement> {
    return new Promise((resolve, _) => {
      const img = new Image();

      img.onload = ()  => {
        resolve(img);
      };
      img.src = base64;
    })
  }

  async compress(base64): Promise<Blob> {
    const image = await this.getImage(base64);
    const canvas = document.createElement('canvas');
    const { width, height } = image;
    const ctx = canvas.getContext('2d');
    ctx.canvas.width  = width;
    ctx.canvas.height = height;
    ctx.drawImage(image, 0, 0, width, height, 0, 0, width, height);
    return new Promise((resolve, _) => {
      canvas.toBlob( (blob) => resolve(blob), 'image/jpeg', 0.5);
    });
  }

  doRemovePhoto(index) {
    this.photos.splice(index, 1);
    this.removePhoto.emit(index);
    this.photosChange.emit(this.photos);
  }

  async selectFile(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const base64 = await this.toBase64(file);

    this.addPhotoAndNotify(base64);
    
  }

  private async addPhotoAndNotify(base64) {
    const name = btoa(`${(new Date).getTime() + Math.random()}`);
    const path = await this.imageCache.put(name, base64);

    this.photos = [...this.photos, path];

    this.photosChange.emit(this.photos);
    this.addPhoto.emit(path);
  }

  private toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }
}
