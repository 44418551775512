import { Component, OnInit } from '@angular/core';
import { SessionStore } from '@ildes/stores/session.store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  user;

  constructor() { }

  ngOnInit(): void {
    SessionStore.getInstance().get$().subscribe((data) => {
      this.user = data?.user;
    });
  }

}
