<connection-alert></connection-alert>
<mat-progress-bar mode="indeterminate" *ngIf="pendingRequest"></mat-progress-bar>
<div class="container">
  <div class="scrollable-content">
    <simple-header title="Reparación de mantenimiento" [back]="true" icon="close"></simple-header>
    <div class="item">
      <app-list-step-state-item
        [state]="completedAnswersDailyQuestionary ? 'COMPLETED': (answersDailyPermission?.length ? 'IN_PROGRESS': 'PENDING')"
        [disabled]="pendingSignMaintenanceTask || pendingRequest || completedAnswersDailyQuestionary"
        (click)="openDailyPermissionQuestionary()"
        title="Cuestionario diario para trabajar en alturas"
        [description]="descriptionStep0">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="(questionaryCompleted || completedAnswers >= 0) ? 'COMPLETED': 'PENDING'"
        (click)="openQuestionary()"
        [disabled]="pendingSignMaintenanceTask || pendingRequest || !completedAnswersDailyQuestionary || repairmentCompleted"
        title="Confirmación de seguridad"
        [description]="descriptionStep1">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="taskIdentificationCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="pendingSignMaintenanceTask || pendingRequest"
        (click)="openTaskIdentificationDialog()"
        title="Identificación de la labor"
        [description]="descriptionStep2">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <!-- <app-list-step-state-item
        [loading]="loadingDocumentationStep"
        (click)="signTask()"
        title="Firmar permiso de trabajo"
        [description]="descriptionStepSign">
      </app-list-step-state-item> -->
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="initialPhotosCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="pendingSignMaintenanceTask || observationsInMaintenanceQuestionary || pendingRequest || !questionaryCompleted || closed"
        (click)="openTakePhotos('initial')"
        title="Tomar fotos del estado inicial"
        [description]="descriptionStep3">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="repairmentCompleted ? 'COMPLETED': 'PENDING'"
        (click)="repair()"
        [disabled]="pendingSignMaintenanceTask || observationsInMaintenanceQuestionary || pendingRequest || !initialPhotosCompleted || closed"
        title="Reparar"
        [description]="descriptionStep4">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="finalPhotosCompleted ? 'COMPLETED': 'PENDING'"
        (click)="openTakePhotos('final')"
        [disabled]="pendingSignMaintenanceTask || observationsInMaintenanceQuestionary || pendingRequest || !repairmentCompleted || closed"
        title="Tomar fotos del estado final"
        [description]="descriptionStep5">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
    </div>
    <div class="padding-top-8 padding-bottom-8" *ngIf="observationsInMaintenanceQuestionary && !maintenanceTask">
      <app-alert-dialog
        (click)="postponeMaintenance()"
        text="Existen disconformidades en la confirmación de seguridad. Puedes posponer la tarea para reanudarla otro día."
        linkText="Generar documentación y posponer tarea"
        status="advise"></app-alert-dialog>
    </div>
    <div class="padding-top-8 padding-bottom-8" *ngIf="pendingIdenfifyLuminary">
      <app-alert-dialog
        (click)="goToLuminary()"
        text="La luminaria {{this.maintenance.luminary.number}} no existe. ¿Quieres registrarla?."
        linkText="Dar de alta luminaria {{this.maintenance.luminary.number}}"
        status="advise"></app-alert-dialog>
    </div>
    <div class="padding-top-8 padding-bottom-8" *ngIf="maintenanceTask && maintenanceTask?.state !== 'COMPLETED'">
      <app-alert-dialog
        (click)="signTask()"
        text="Este mantenimiento tiene un documento pendiente de firmar."
        linkText="Ver documentación"
        status="advise"></app-alert-dialog>
    </div>
    <div *ngIf="!closed && !observationsInMaintenanceQuestionary" class="align-center padding-top-8">
      <button [disabled]="pendingRequest || !completedSteps" mat-raised-button color="primary" (click)="closeMaintenance()" class="button-size-200">Cerrar mantenimiento</button>
    </div>
    <div *ngIf="pendingSync" class="align-center padding-top-8">
      <button [disabled]="pendingRequest" mat-button (click)="syncMaintenance(maintenance)" class="button-size-200">
        <mat-icon class="icon-button">cloud_upload</mat-icon>Sincronizar
      </button>
    </div>
  </div>
</div>
