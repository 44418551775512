<connection-alert></connection-alert>
<mat-progress-bar mode="indeterminate" *ngIf="pendingRequest"></mat-progress-bar>
<div class="container">
  <div class="scrollable-content">
    <simple-header title="Detalle de mantenimiento" [back]="true" icon="close"></simple-header>
    <div class="item">
      <app-list-step-state-item
        [state]="completedAnswersDailyQuestionary ? 'COMPLETED': (answersDailyPermission?.length ? 'IN_PROGRESS': 'PENDING')"
        [disabled]="completedAnswersDailyQuestionary"
        (click)="openDailyPermissionQuestionary(true)"
        title="Cuestionario diario para trabajar en alturas"
        [description]="descriptionStep0">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="(questionaryCompleted || completedAnswers >= 0) ? 'COMPLETED': 'PENDING'"
        (click)="openQuestionary(true)"
        [disabled]="(questionaryCompleted || !(completedAnswers >= 0))"
        title="Confirmación de seguridad"
        [description]="descriptionStep1">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="taskIdentificationCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="true"
        title="Identificación de la labor"
        [description]="descriptionStep2">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="initialPhotosCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="!(maintenance?.photos?.initial?.length > 0)"
        (click)="openTakePhotos('initial', true)"
        title="Fotos del estado inicial"
        [description]="descriptionStep3">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="repairmentCompleted ? 'COMPLETED': 'PENDING'"
        [disabled]="true"
        title="Datos de la reparación"
        [description]="descriptionStep4">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
      <app-list-step-state-item
        [state]="finalPhotosCompleted ? 'COMPLETED': 'PENDING'"
        (click)="openTakePhotos('final', true)"
        [disabled]="!(maintenance?.photos?.final?.length > 0)"
        title="Fotos del estado final"
        [description]="descriptionStep5">
      </app-list-step-state-item>
      <mat-divider></mat-divider>
    </div>
    <div class="padding-top-8 padding-bottom-8" *ngIf="maintenanceTask && maintenanceTask?.state !== 'COMPLETED'">
      <app-alert-dialog
        (click)="signTask()"
        text="Este mantenimiento tiene un documento pendiente de firmar."
        linkText="Ver documentación"
        status="advise"></app-alert-dialog>
    </div>
  </div>
</div>
