import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'list-luminary-item',
  templateUrl: './list-luminary-item.component.html',
  styleUrls: ['./list-luminary-item.component.css']
})
export class ListLuminaryItemComponent implements OnInit {

  @Input() number;
  @Input() project;
  @Input() address;
  @Input() ubication;
  @Input() neighborhood;
  @Input() technology;
  @Input() actions;
  @Input() power;
  @Input() newTechnology;
  @Input() newPower;
  @Output() onLinkClick =  new EventEmitter();
  @Output() onActionClick =  new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  linkClicked() {
    this.onLinkClick.emit()
  }

  onClickActionEvent(event) {
    this.onActionClick.emit(event);
  }
}
