<div class="margin-8">
  <simple-header title="Crear material" [back]="true" icon="close"></simple-header>
  <form [formGroup]="materialForm" class="padding-top-8 padding-bottom-8">
    <div class="content">
      <mat-form-field appearance="outline" class="adjust-width">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" maxlength="50">
        <mat-error>
            El nombre es obligatorio
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="margin-left-16">
        <mat-label>Unidad</mat-label>
        <mat-select [(value)]="unit">
          <mat-option *ngFor="let unit of unitTypes" [value]="unit">
            {{unit.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Categorías</mat-label>
      <mat-chip-grid #chipGrid aria-label="Selecciona la categoría">
        <mat-chip-row *ngFor="let category of categories" (removed)="remove(category)">
          {{category}}
          <button matChipRemove [attr.aria-label]="'elimina ' + category">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input placeholder="Nueva categoría..." #categoryInput [formControl]="categoryCtrl"
        [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"/>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
          {{category}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="align-center">
      <div class="padding-bottom-8">
        <button (click)="save()" [disabled]="materialForm.invalid || loading" mat-raised-button color="primary" class="button-size-200">Guardar</button>
      </div>
      <div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </form>
</div>
