import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiManagerService } from '../../../services/api-manager.service';
import { AppState } from '@ildes/app.reducer';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-to-technical',
  templateUrl: './assign-to-technical.component.html',
  styleUrls: ['./assign-to-technical.component.css']
})
export class AssignToTechnicalComponent implements OnInit {

  selectedMaintenances;
  technicals;
  maintenanceObservable;

  constructor(
    private store: Store<AppState>,
    private apiManager: ApiManagerService,
    private location: Location,
    private router: Router
  ){ }

  ngOnInit(): void {
    this.maintenanceObservable = this.store.select('maintenances').subscribe( state => {
      this.selectedMaintenances = [];
      state.forEach((value, key) => {
        this.selectedMaintenances.push(value);
      });

      this.selectedMaintenances = this.selectedMaintenances || [];

      const projects = [...new Set(this.selectedMaintenances.map(({ project }) =>  project.id))];

      this.apiManager.getProjectParticipants('TECHNICIAN', { projectId: projects }).subscribe((technicals: any) => {
        this.technicals = technicals.data;
      });
    });
  }

  showUserDetailMaintenances() {

  }

  onSelectTechnical({id}) {
    const maintenances = this.selectedMaintenances.map((maintenance) => ({...maintenance, ...{ technician: id } }));

    this.apiManager.assignMaintenancesToTechnical(maintenances).subscribe(() => {
      this.router.navigate(['/app/technicians/detail'], { queryParams: { id } });
    });
  }

  ngOnDestroy() {
    this.maintenanceObservable.unsubscribe();
  }

}
