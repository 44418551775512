export default [{
  id: 0,
  title: 'Declaro estar en buena condición de salud, sin agotamiento físico ni bajo el efecto de medicamentos, así como  haberme alimentado apropiadamente.',
  observations: {
    show: true,
    placeholder: 'Indique brevemente los motivos por los cuales no puede realizar la labor.'
  },
}, {
  id: 1,
  title: 'Declaro que conozco el objetivo de la tarea y cuento con la capacitación para realizarla en forma apropiada.',
  observations: {
    show: true,
    placeholder: 'Indique brevemente los motivos por los cuales no puede realizar la labor.'
  },
}, {
  id: 2,
  title: 'Identifico distancia segura entre la zona de trabajo y elementos eléctricos energizados.',
  observations: {
    show: true,
    placeholder: 'Indique brevemente los motivos por los cuales no puede realizar la labor.'
  },
}, {
  id: 3,
  title: 'Identifico que NO extisten riesgos específicos que impidan el desarrollo de la labor.',
  observations: {
    show: true,
    placeholder: 'Indique brevemente los motivos por los cuales no puede realizar la labor.'
  },
}];
