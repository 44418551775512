import { Action, createReducer, State, on  } from '@ngrx/store';
import * as actions from './list-luminaries.actions';
import { ActionReducer } from '@ngrx/store';

const initState = (state) => {
  const luminaries = localStorage.getItem('luminaries');
  
  if (luminaries) {
    return JSON.parse(luminaries)?.value || [];
  } else {
    return [];
  }
}

const create = (state, action) => {
  return [...state, {...action.luminary, _id: parseInt(`${Math.random()*1000000}`)}];
 }

 const remove = (state, action) => {
  return state.filter(({_id}) => _id !== action._id)
 }

 // TODO: modificar updatedAt
const update = (state, action) => {
  return state.map((luminary) => {
    if (luminary._id === action.id) {
      return {
        ...luminary,
        ...action.changes,
        updatedAt: new Date().toISOString()
      }
    }

    return luminary;
  });
}

const initialState = [];
const luminaries = createReducer(
  initialState,
  on(actions.initState, initState),
  on(actions.create, create),
  on(actions.update, update),
  on(actions.remove, remove),
);

export function reducer(state: any[] | undefined, action: Action) {
  return luminaries(state, action);
}
