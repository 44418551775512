import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'humanize-state',
  templateUrl: './humanize-state.component.html',
  styleUrls: ['./humanize-state.component.css']
})
export class HumanizeStateComponent implements OnInit {

  @Input() state;
  constructor() { }

  ngOnInit(): void {

  }

  get description() {
    switch (this.state) {
      case 'IN_PROGRESS':
        return 'En curso'
        break;
      case 'REJECTED':
        return 'Rechazado'
        break;
      case 'CLOSED':
        return 'Cerrado'
        break;
      default:
        return ''
        break;
    }
  }

}
