import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'resume-luminary',
  templateUrl: './resume-luminaire.component.html',
  styleUrls: ['./resume-luminaire.component.css']
})
export class ResumeLuminaireComponent implements OnInit {

  @Input() projectName;
  @Input() number;
  @Input() technology;
  @Input() power;
  @Input() maker;
  @Input() model;
  @Input() address;
  @Input() latitude;
  @Input() longitude;
  @Input() neighborhood;
  @Input() retilap;
  @Input() type;
  @Input() special;
  @Input() anomaly;
  @Input() switchedon;
  @Input() electricNetwork;
  @Input() postType;
  @Input() postClass;
  @Input() particularUse;

  constructor() { }

  ngOnInit(): void {
  }
}
